import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPrivateApi+"deals";


export const dealService = {
    getAll,
    getById,
    create,
    update,
    delete: _delete,

    getAllBusiness,
    getByIdBusiness,
    createBusiness,
    updateBusiness,
    deleteBusiness: _deleteBusiness,
};

function getAll(query='') {
    return axios.get(`${baseUrl}${query}`);
}

function getById(id) {return axios.get(`${baseUrl}/${id}`);}

function create(params) {return axios.post(baseUrl, params);}

function update(id, params, queryParams='') {
    return axios.put(`${baseUrl}/${id}${queryParams}`, params)
}

function _delete(id) {return axios.delete(`${baseUrl}/${id}`)}




function getAllBusiness(business_id, query='') {
    return axios.get(`${baseUrl}/business/${business_id}${query}`);
}

function getByIdBusiness(business_id, id) {return axios.get(`${baseUrl}/business/${business_id}/${id}`);}

function createBusiness(business_id, params) {return axios.post(baseUrl+'/business/'+business_id, params);}

function updateBusiness(business_id, id, params, queryParams='') {
    return axios.put(`${baseUrl}/business/${business_id}/${id}${queryParams}`, params)
}

function _deleteBusiness(business_id, id) {return axios.delete(`${baseUrl}/business/${business_id}/${id}`)}
