import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import {
	Dashboard, Demo2Dashboard, DispensaryDashboard, Demo1Dashboard, Demo3Dashboard, AddEntityPage, PublisherDashboard, DeliveryDashboard, DoctorDashboard
} from "../../_metronic/_partials";
import BrandDashboard from "../../_metronic/_partials/dashboards/BrandDashboard";
import { dispensaryService } from '../services'

export function DashboardPage(props) {


	const user = useSelector(state => state.auth.user)

	console.log(user)

	const getBody = () => {
		switch (user.type) {
			case "ADMIN":
				return <Dashboard />

			case "PUBLISHER":
				return <PublisherDashboard />

			case "DELIVERY":
				return <DispensaryDashboard business_id={props.match.params.business_id} />

			case "BRAND":
				return <BrandDashboard business_id={props.match.params.business_id} />

			case "DISPENSARY": 
				return <DispensaryDashboard business_id={props.match.params.business_id} />

			case "DOCTOR": 
				return <DoctorDashboard />

			default:
				break;
		}
	}


	return (
		<>
			{ getBody()}
		</>
	)
}
