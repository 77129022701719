/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { AreaOfCoverageUpdateDialog } from './AreaOfCoverageUpdateDialog'
import { dispensaryService } from '../../../../app/services'

export function LocationWidget({ 
  className, 
  iconColor, 
  dispensary, 
  location,
  mapURL,
  coverage,
  address,
  address_line_2,
  addresszip,
  city,
  state,
  type,
  isCurbsidePickupServiceAvailable,
  isPickupServiceAvailable,
  fetchDispensary,
  businessType,
  dispensaryId = 'undefined'
}) {

  const showMapModalInitialState = false
  const [showMapModal, setShowMapModal] = useState(showMapModalInitialState)

  

  const saveItem = (fields) => {
   
      dispensaryService.update(dispensaryId, fields).then(data => {
          fetchDispensary()
      })
      .catch(error => {

      })
  }

  

  return (
    <>
      <div className={`card card-custom ${className}`}>
        { 
          showMapModal && (
            <AreaOfCoverageUpdateDialog  
              show={showMapModal} 
              type={type}
              onHide={()=>{setShowMapModal(false)}} 
              coverage={coverage}
              address={address}
              address_line_2={address_line_2}
              addresszip={addresszip}
              city={city}
              state={state}
              location={location}
              fetchDispensary={fetchDispensary}
              dispensaryId={dispensaryId}
            />
          )
        }
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
                <div className="font-weight-bolder">Business Address</div>
              <div className="font-size-sm text-muted mt-2">The address of your business</div>
            </div>
          </div>
          <div className="card-toolbar">
            <button 
              type='button' 
              className="btn btn-success font-weight-bolder font-size-sm"
              onClick={()=>setShowMapModal(true)}
            >
                  <i className="flaticon-edit"></i>
                  Update location
            </button>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0">
        
          {
            mapURL ? (
              <div style={{paddingLeft:"2.25rem", paddingRight:"2.25rem"}}>
                <img src={mapURL} style={{width:"100%", height:"100%"}} />
                <div className='row'>
                  <div style={{borderRight:'1px solid #cccccc'}} className='col col-6 '>
                    <p class="font-weight-bold text-dark-75 font-size-lg mb-1 pt-4">
                      Address:
                    </p>
                    <p className='font-weight-bold text-muted'>
                      <span>{address}</span><br></br>
                      {
                        address_line_2 && <><span>{address_line_2}</span><br></br></>
                      }
                      <span>{city}</span><br></br>
                      <span>{state} ({addresszip})</span>
                    </p>
                  </div>
                  <div className='col col-6'>
                    <p class="font-weight-bold text-dark-75 font-size-lg mb-1 pt-4">
                      Exact location:
                    </p>
                    <p className='font-weight-bold text-muted'>
                      Longitude: {location.coordinates[0]}
                      <br />
                      Latitude: {location.coordinates[1]}
                    </p>
                  </div>
                </div>
                
                {
                  businessType === 'DISPENSARY' && <>
                  <hr></hr>
                <h4>Pickup Online Ordering Options</h4>
                <div class="form-group row">
                    <div class="col-3">
									  	  <span class="switch switch-icon">
									  	  	  <label>
                                <input 
                                  type="checkbox" 
                                  checked={isPickupServiceAvailable} 
                                  name="select" 
                                  id="isPickupServiceAvailable" 
                                  onClick={
                                    ()=>saveItem({isPickupServiceAvailable:!isPickupServiceAvailable})
                                  }
                                />
									  	  	  	  <span></span>
									  	  	  </label>
									  	  </span>
									  </div>
									  <label htmlFor='isPickupServiceAvailable' class="col-9 col-form-label">Online Ordering for pickup</label>
								</div>
                <div class="form-group row">
                    <div class="col-3">
									  	  <span class="switch switch-icon">
									  	  	  <label>
                                <input 
                                  type="checkbox" 
                                  checked={isCurbsidePickupServiceAvailable} 
                                  name="select" 
                                  id="isCurbsidePickupServiceAvailable" 
                                  onClick={
                                    ()=>saveItem({isCurbsidePickupServiceAvailable:!isCurbsidePickupServiceAvailable})
                                  }
                                />
									  	  	  	  <span></span>
									  	  	  </label>
									  	  </span>
									  </div>
									  <label htmlFor='isCurbsidePickupServiceAvailable' class="col-9 col-form-label">Curbside Pickup</label>
								</div>
                  </>
                }
                
                
              </div>
            ) : (
              <div style={{paddingLeft:"2.25rem", paddingRight:"2.25rem"}} className='d-flex align-items-center flex-column justify-content-center'>
                <h4>Location not found</h4>
                {/* <br></br> */}
                <p className='font-weight-bold text-muted'><center>Please set the location of your business in order to be listed in weedzly</center></p>
              </div>
            )
          }
          
        </div>
      </div>
    </>
  );
}