/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { disable } from "mapbox-gl-draw-circle/lib/utils/drag_pan";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler2 } from "./";
import Swal from 'sweetalert2'

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger mr-2'
    },
    buttonsStyling: false
})
  

export function DropdownDailyDeal(props) {

    const moveDeal = (thisDay, newDay, newDeal) => {
        let payload = {
            days: newDeal.days.filter(x => x!=thisDay).concat(newDay)
        }
        props.updateDeal(newDeal._id, payload)
    }

    const copyDeal = (thisDay, newDay, newDeal) => {
        let payload = {
            days: newDeal.days.concat(newDay)
        }
        props.updateDeal(newDeal._id, payload)
    }

    const removeDay = (thisDay, newDeal) => {
        let payload = {
            days: newDeal.days.filter(x => x!=thisDay)
        }
        if(payload.days.length > 0 ){
            props.updateDeal(newDeal._id, payload)
        } else {
            // swalWithBootstrapButtons.queue([{
            //     title: 'Warning!',
            //     text: `Since this deal is only available the ${props.day}, if you delete from this day it will be completely deleted, if you would want to use this again, use disable option instead`,
            //     icon: 'warning',
            //     showCancelButton: true,
            //     confirmButtonText: 'Yes, delete it!',
            //     cancelButtonText: 'No, cancel!',
            //     showLoaderOnConfirm: true,
            //     reverseButtons: true,
            //     preConfirm: () => { 
                    
                    
            //       return  
                  
                  props.removeDeal(newDeal._id).then(data => {
                    Swal.fire(
                      'Done!',
                      'The item has been deleted!',
                      'success'
                    )
                  })
                  .catch(error => {
                      Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.data.message || error.response.data.errors[0]['message'],
                      })
                  })
            //     } 
            //   }])
           
        }
    }

    const disableDeal = (id, status) => {
        let payload = {
            isEnabled: status
        }
        swalWithBootstrapButtons.queue([{
            title: 'Warning!',
            text: `By ${status ? 'enabling' : 'disabling' } this deal, it’ll be automatically ${!status ? 'enabled' : 'disabled' }  from all other days`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Yes, ${!status ? 'disable' : 'enable' } it!`,
            cancelButtonText: 'No, cancel!',
            showLoaderOnConfirm: true,
            reverseButtons: true,
            preConfirm: () => { 
                
                
              return props.updateDeal(id, payload).then(data => {
                Swal.fire(
                  'Done!',
                  'The item has been deleted!',
                  'success'
                )
              })
              .catch(error => {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data.message || error.response.data.errors[0]['message'],
                  })
              })
            } 
          }])
    }
    
    return <>
        {/*begin::Navigation*/}
        <ul className="navi ">
            <li className="navi-header pb-1">
                <span className="text-primary text-uppercase font-weight-bold font-size-sm">Options</span>
            </li>
           
            {
                props.deal.days.length != 7 && <>
                    <li className="navi-item dropdown-item">
                        <Dropdown className="" alignRight drop={'right'}>
                          <Dropdown.Toggle
                            id="dropdown-toggle-top"
                            as={DropdownCustomToggler2}
                          >
                            <>
                                <span className="navi-icon"><i className="flaticon2-send-1"></i></span>
                                <span className="navi-text">Move to</span>
                                <span className="navi-icon"><i className="flaticon2-right-arrow"></i></span>
                            </>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                            <ul className="navi ">
                                <li className="navi-header pb-1">
                                    <span className="text-primary text-uppercase font-weight-bold font-size-sm">Move</span>
                                </li>
                                {
                                    props.days.filter(x => !props.deal.days.includes(x)).map(x => <Dropdown.Item className="navi-item" onClick={()=>moveDeal(props.day, x, props.deal)}>
                                        <a href="#" className="navi-link">
                                            <span className="navi-icon"><i className="flaticon2-calendar-8"></i></span>
                                            <span className="navi-text">{x}</span>
                                        </a>
                                    </Dropdown.Item>)
                                }

                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                            
                    </li>
                    <li className=" navi-item dropdown-item">
                        <Dropdown className="" alignRight drop={'right'}>
                          <Dropdown.Toggle
                            id="dropdown-toggle-top"
                            as={DropdownCustomToggler2}
                          >
                            <>
                                <span className="navi-icon"><i className="flaticon2-copy"></i></span>
                                <span className="navi-text">Share to</span>
                                <span className="navi-icon" style={{fontSize:'12px'}}><i className="flaticon2-right-arrow"></i></span>
                            </>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                            <ul className="navi ">
                                <li className="navi-header pb-1">
                                    <span className="text-primary text-uppercase font-weight-bold font-size-sm">Clone</span>
                                </li>
                                {
                                    props.days.filter(x => !props.deal.days.includes(x)).map(x => <li className="navi-item" onClick={()=>copyDeal(props.day, x, props.deal)}>
                                        <a href="#" className="navi-link">
                                            <span className="navi-icon"><i className="flaticon2-calendar-8"></i></span>
                                            <span className="navi-text">{x}</span>
                                        </a>
                                    </li>)
                                }

                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                    </li>
                </>
            }
            <Dropdown.Item className="navi-item">
                <a href="#" className="navi-link" onClick={()=>props.setShowModalWithId(props.deal._id)}>
                    <span className="navi-icon"><i className="flaticon2-edit"></i></span>
                    <span className="navi-text">Edit</span>
                </a>
            </Dropdown.Item>
            {/* <Dropdown.Item className="navi-item">
                {
                    props.deal.isEnabled ? (
                        <a href="#" className="navi-link" onClick={()=>disableDeal(props.deal._id, false)}>
                            <span className="navi-icon"><i className="flaticon2-box"></i></span>
                            <span className="navi-text">Disable</span>
                        </a>
                    ) : (
                        <a href="#" className="navi-link" onClick={()=>disableDeal(props.deal._id, true)}>
                            <span className="navi-icon"><i className="flaticon2-open-box"></i></span>
                            <span className="navi-text">Enable</span>
                        </a>
                    )
                }
            </Dropdown.Item>  */}
            <Dropdown.Item className="navi-item">
                <a href="#" className="navi-link" onClick={()=>{removeDay(props.day, props.deal)}}>
                    <span className="navi-icon"><i className="flaticon2-delete"></i></span>
                    <span className="navi-text">Delete</span>
                </a>
            </Dropdown.Item>
        </ul>
        {/*end::Navigation*/}
    </>
}
