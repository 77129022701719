import React, { useMemo, useState, useEffect } from "react";
import { AdminAsideMenuList } from "./AdminAsideMenuList";
import { BottomAsideMenu } from "./BottomAsideMenu";
import { DispensaryAsideMenuList } from "./DispensaryAsideMenuList";
import { PublisherAsideMenuList } from "./PublisherAsideMenuList";
import { DoctorAsideMenuList } from  './DoctorAsideMenuList'
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { useSelector } from 'react-redux'
import { DeliveryAsideMenuList } from "./DeliveryAsideMenuList";
import { BrandAsideMenuList } from "./BrandAsideMenuList";
import { QuickActions } from "../../subheader/components/QuickActions";
import { accountService } from '../../../../../app/services'
import { useHistory } from "react-router";

export function AsideMenu({ isActive }) {

  const user = useSelector(state => state.auth.user)

  const [ selectedBusiness, setSelectedBusiness ] = useState(null)

  const [ businesses, setBusinesses ] = useState([])

  const history = useHistory()

  const getBusinesses = async () => {

    try {
      
      const _businesses = await accountService.getBusinesses()
      setBusinesses(_businesses.data.businesses)
      if(user.type === 'BRAND'){
        setSelectedBusiness(_businesses.data.businesses[0])
        if(!window.location.pathname.includes('/brands/'+_businesses.data.businesses[0]._id)){
          history.push('/brands/'+_businesses.data.businesses[0]._id)
        }
        
      }

    } catch (er) {

      console.log(er)
    }

  }

  useEffect(() => {
    if(user.type != 'PUBLISHER'){
      getBusinesses()
    }
  
  }, [])
  
  
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true),
    };
  }, [uiService]);

  return (
    <div className={`h-100 tab-pane fade ${isActive && "show active"}`}>
      <div className="h-100 ">
        {/* begin::Menu Container */}
        
        <div
          id="kt_aside_menu"
          data-menu-vertical="1"
          className={`aside-menu d-flex flex-column justify-content-between h-100 ${layoutProps.asideClassesFromConfig}`}
          {...layoutProps.asideMenuAttr}
        >
          <div>
         
            {
              (user.type === 'DISPENSARY' || user.type === 'DELIVERY') && <div className='d-flex' style={{padding: '9px 25px'}}>
              <QuickActions businesses={businesses} setBusinesses={setBusinesses} selectedBusiness={selectedBusiness} setSelectedBusiness={setSelectedBusiness} user={user}/> 
            </div>
            }
            
            { 
              user.type==="ADMIN" 
                ? <AdminAsideMenuList layoutProps={layoutProps} /> 
                : user.type==="PUBLISHER" ? <PublisherAsideMenuList selectedBusiness={selectedBusiness} layoutProps={layoutProps} /> 
                : (
                  selectedBusiness ? (
                    user.type==="DISPENSARY" ? <DispensaryAsideMenuList selectedBusiness={selectedBusiness} layoutProps={layoutProps} /> 
                    : user.type==="DELIVERY"  ? <DispensaryAsideMenuList selectedBusiness={selectedBusiness} layoutProps={layoutProps} />
                    : user.type==="BRAND" ? <BrandAsideMenuList selectedBusiness={selectedBusiness} layoutProps={layoutProps} /> 
                    : user.type==="DOCTOR" ? <DoctorAsideMenuList selectedBusiness={selectedBusiness} layoutProps={layoutProps} />
                    : ""
                  ) : ''
                  
                )
            }
          </div>
            {console.log(selectedBusiness)}
            {console.log(businesses)}
          {
            selectedBusiness && (user.type === 'DISPENSARY' || user.type === 'DELIVERY' || user.type === 'BRAND') && <BottomAsideMenu selectedBusiness={selectedBusiness} layoutProps={layoutProps} />
          }
          
        </div>
        {/* end::Menu Container */}
      </div>
    </div>
  );
}
