import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPrivateApi+"subscriptions";


export const subscriptionService = {
    getAll,
    getById,
    getPortalUrl,
    create,
    update,
    getByUserId,
    delete: _delete,
};




function getAll(business_id, query = '') {

    return axios.get(baseUrl+'/'+business_id+query);
}

function getById(id, query='') {
    return axios.get(`${baseUrl}/${id}${query}`);
}

function getPortalUrl(business_id) {
    return axios.get(`${baseUrl}/${business_id}/getPortalUrl`);
}

function getByUserId(id) {
   
    return axios.get(`${baseUrl}/user/${id}`)  .then(category => {
        return category;
})}

function create(params) {
 
    return axios.post(baseUrl, params);
}


function update(id, params) {

    return axios.put(`${baseUrl}/${id}`, params)
        .then(user => {
        });
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(id) {
    return axios.delete(`${baseUrl}/${id}`)
        .then(x => {
        });
}
