/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Figure } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import LandingUpdateDialog from './LandingUpdateDialog'

export function FeatureLanding({ 
  fetchData,
  entityId,
  widgetTitle,
  text,
  image,
  title,
  className,
  editSchema,
  initialValues,
  fieldText,
  fieldTitle,
  fieldPicture,
}){

  const [ showModal, setShowModal ] = useState(false)

  return (
    <>
        {
          showModal && (
            <LandingUpdateDialog  
              fetchData={fetchData}
              entityId={entityId}
              widgetTitle={widgetTitle+' Update'}
              show={showModal} 
              onHide={()=>{setShowModal(false)}} 
            //   title={title}
              editSchema = {editSchema}
              initialValues = {initialValues}
              fieldText = {fieldText}
              fieldTitle = {fieldTitle}
              fieldPicture = {fieldPicture}
              image={image}
            />
          )
        } 
      <div className={`card card-custom ${className}`} style={{maxHeight:'500px'}}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
                <div className="font-weight-bolder">{widgetTitle}</div>
              {/* <div className="font-size-sm text-muted mt-2">Area covered by your delivery service</div> */}
            </div>
          </div>
          <div className="card-toolbar">
            <button 
              type='button' 
              className="btn btn-success font-weight-bolder font-size-sm"
              onClick={()=>setShowModal(true)}
            >
                  <i className="flaticon-edit"></i>
                  Update
            </button>
          </div>
        </div>
        <div className="card-body d-flex flex-column card-body pt-5 pb-5">
            <div className='row'>
                <div className='col-md-12 col-12 d-flex justify-content-center'>
                    <Figure>
                        <Figure.Image
                            width={72}
                            height={72}
                            src={image}
                            style={{borderRadius:'100%'}}
                        />
                    </Figure>
                </div>
                <div className='col-md-12 col-12 align-items-center'>
                    <h4><center>{title && title.length > 0 ? title : 'Update title'}</center></h4>
                    {
                      text && text.length > 0 ? <p style={{whiteSpace:'pre-line', overflowY:'auto'}}><center>{text}</center></p> : <center><h4>Update text</h4></center>
                    }
                </div>
            </div>
        </div>
      </div>
    </>
  );
}