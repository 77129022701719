/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Figure } from 'react-bootstrap'
import LandingUpdateDialog from './LandingUpdateDialog'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import * as Yup from "yup";

export function MainSectionLanding({ 
    fetchData,
    entityId,
    widgetTitle,
    text,
    title,
    setText,
    image,
    className,
    editSchema,
    initialValues,
    fieldText,
}){

    const [ showModal, setShowModal ] = useState(false)


  return (
    <>
        {
          showModal && (
            <LandingUpdateDialog  
              fetchData={fetchData}
              entityId={entityId}
              widgetTitle={widgetTitle+' Update'}
              show={showModal} 
              onHide={()=>{setShowModal(false)}} 
              editSchema={editSchema}
              initialValues={initialValues}
              fieldText={fieldText}
            />
          )
        } 
      <div className={`card card-custom ${className}`} style={{maxHeight:'500px'}}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">{widgetTitle}</div>
              {/* <div className="font-size-sm text-muted mt-2">Area covered by your delivery service</div> */}
            </div>
          </div>
          <div className="card-toolbar">
            <button 
              type='button' 
              className="btn btn-success font-weight-bolder font-size-sm"
                onClick={()=>setShowModal(true)}
            >
                  <i className="flaticon-edit"></i>
                  Update
            </button>
          </div>
        </div>
        <div className="card-body d-flex flex-column card-body pt-5 pb-5">
            <div className='row'>
                <div className='col-md-4 col-12'>
                    <Figure>
                        <Figure.Image
                            src={image}
                        />
                    </Figure>
                </div>
                <div className='col-md-8 col-12'>
                        <h4>{title && title.length > 0 ? title : 'Update title'}</h4>
                    {
                      text && text.length > 0 ? <p style={{whiteSpace:'pre-line', overflowY:'auto'}}>{text}</p> : <center><h4>Update text</h4></center>
                    }
                </div>
            </div>
        </div>
      </div>
    </>
  );
}