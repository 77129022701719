import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";
const baseUrl = getPublicApi;
const accountUrl = getPrivateApi+"users";

export const accountService = {
    // login,
    // logout,
    // register,
    // verifyEmail,
    // forgotPassword,
    // validateResetToken,
    // resetPassword,
    // resendToken,
    // update,
    getAll,
    getById,
    updateOwn,
    update,
    getBusinesses,
    create,
    createAdmin,
    fixpassword,
    delete: _delete,
};

// function login(email, password) {
//     return fetchWrapper.post(`${baseUrl}auth/login`, { email, password })
//     .then(user => {
//         // store user details and jwt token in local storage to keep user logged in between page refreshes
//         localStorage.setItem('user', JSON.stringify(user));
        
//         // publish user to subscribers
//         userSubject.next(user);
        
//         return user;
//     });
// }

// function logout() {
//     // remove user from local storage and publish null to user subject
//     localStorage.removeItem('user');
//     userSubject.next(null);
//     history.push('/account/login');
// }

// function resendToken(params) {
//     return fetchWrapper.post(`${baseUrl}auth/resend`, params)
// }

// function register(params) {
//     return fetchWrapper.post(`${baseUrl}auth/signup`, params);
// }

// function verifyEmail(token) {
//     return fetchWrapper.post(`${baseUrl}auth/verify/${token}`);
// }

// function forgotPassword(email) {
//     return fetchWrapper.post(`${baseUrl}password/forgot-password`, { email });
// }

// function validateResetToken(token) {
//     return fetchWrapper.post(`${baseUrl}password/validate-token`, { token });
// }

// function resetPassword(token, password) {
//     return fetchWrapper.post(`${baseUrl}password/reset`, { token, password });
// }

function getAll(query) {
    var fetchParam=""
    if(!query){fetchParam=`${accountUrl}`}
    else{fetchParam=`${accountUrl}${query}`}
    return axios.get(fetchParam);
}


function fixpassword(dispensary) {
    return axios.put(`${accountUrl}/fixpassword/${dispensary}`);
}

function getBusinesses(){
    return axios.get(`${accountUrl}/businesses`);
}

function getById(id) {
    return axios.get(`${accountUrl}/${id}`);
}

function create(params) {
    return axios.post(accountUrl, params);
}

function createAdmin(params) {
    return axios.post(accountUrl+'/admin', params);
}

function updateOwn(params) {
    return axios.put(`${accountUrl}/me`, params)
}

function update(id, params) {
    return axios.put(`${accountUrl}/${id}`, params)
}



// prefixed with underscored because delete is a reserved word in javascript
function _delete(id) {
    return axios.delete(`${accountUrl}/${id}`)
}
