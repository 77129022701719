import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPrivateApi+"orders";


export const orderService = {
    getAll,
    getById,
    create,
    update,
    getFulfilledCount,
    getChartSalesData,
    getAllThisMonth
};




function getAll(business_id, query='') {
    console.log('asdasdasdasdasd')
    return axios.get(`${baseUrl}/${business_id}${query}`);
}

function getAllThisMonth(business_id, query='') {
    return axios.get(`${baseUrl}/${business_id}/month${query}`);
}

function getById(business_id, id) {
    
    return axios.get(`${baseUrl}/${business_id}/${id}`);
}

function create(params) {
    return axios.post(baseUrl, params);
}

function update(business_id, id, params) {
    return axios.put(`${baseUrl}/${business_id}/${id}`, params)
}

function getFulfilledCount () {
    return axios.get(`${baseUrl}/getfulfilledcount`)
}

function getChartSalesData (query) {
    console.log(query)
    return axios.get(`${baseUrl}/chart/days${query}`)
}
