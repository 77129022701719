/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { orderService } from '../../../../app/services'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import Spinner from '../../spinner/Spinner'
import { Link } from 'react-router-dom'

export function BestSaleWidget({ className }) {
  const tabs = {
    tab1: "Week",
    tab2: "Month",
    tab3: "Year",
  };

  const [activeTab, setActiveTab] = useState(tabs.tab1);

  const [ items, setItems ] = useState([])

  const [ loading, setLoading ] = useState(false)

  const queries = (selectedTab) => {
    let timeframe = 0
    switch (selectedTab) {
      case 'Week':
        timeframe = 60 * 60 * 24 * 7
        break;

      case 'Month':
        timeframe = 60 * 60 * 24 * 7 * 4
        break;

      case 'Year':
        timeframe = 60 * 60 * 24 * 365
        break;
    
      default:
        break;
    }
    return '/sales/topProducts?timeframe='+timeframe.toString()
  }

  const fetch = async (query) => {
    try {

      let { data } = await orderService.getAll(query)
      setItems(data)
      setLoading(false)

    } catch(err) {

      console.log(err)

    }
  }

  useEffect(() => {
    setLoading(true)
    fetch(queries(activeTab))
  }, [activeTab])

  return (
    <>
      {/* begin::Base Table Widget 6 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Most sold products
            </span>
            {/* <span className="text-muted mt-3 font-weight-bold font-size-sm">
              The best of your menu
            </span> */}
          </h3>
          <div className="card-toolbar">
            <ul className="nav nav-pills nav-pills-sm nav-dark-75">
              <li className="nav-item">
                <a
                  className={`nav-link py-2 px-4 ${activeTab === tabs.tab1 &&
                    "active"}`}
                  data-toggle="tab"
                  href={`#${tabs.tab1}`}
                  onClick={() => setActiveTab(tabs.tab1)}
                >
                  Week
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link py-2 px-4 ${activeTab === tabs.tab2 &&
                    "active"}`}
                  data-toggle="tab"
                  href={`#${tabs.tab2}`}
                  onClick={() => setActiveTab(tabs.tab2)}
                >
                  Month
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link py-2 px-4 ${activeTab === tabs.tab3 &&
                    "active"}`}
                  data-toggle="tab"
                  href={`#${tabs.tab3}`}
                  onClick={() => setActiveTab(tabs.tab3)}
                >
                  Year
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-2 pb-0">
          {/* begin::Table */}
          {
            loading ? (
              <Spinner />
            ) : (
              items.length < 1 ? (
                <div className='d-flex h-100 align-items-center justify-content-center pt-8 pb-8'>
                  <center><h4>You haven't had any orders this month</h4></center>
                </div>
              ) : (
                <div className="table-responsive">
                  <table className="table table-borderless table-vertical-center">
                    <thead>
                      <tr>
                        <th className="p-0" style={{ width: "50px" }} />
                        <th className="p-0" style={{ minWidth: "150px" }} />
                        <th className="p-0" style={{ minWidth: "120px" }} />
                      </tr>
                    </thead>
                    <tbody>
                    {
                        items && items.map(item => 
                          <tr>
                            <td className="pl-0">
                              <div className="symbol symbol-50 symbol-light mr-2 mt-2">
                                <span className="symbol-label">
                                  {
                                    (item.picture && item.picture.length > 1) ? (
                                      <img className='symbol-label'  src={item.picture[0]} />
                                    ) : (
                                      <SVG
                                        className="h-100 align-self-end"
                                        src={toAbsoluteUrl("/media/svg/icons/Shopping/Box1.svg")}
                                      ></SVG>
                                    )
                                  }
                                </span>
                              </div>
                            </td>
                            <td className="pl-0">
                              <Link to={`/businesses/:business_id/menu/${item._id}/details`} className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                { 
                                  item.name
                                }
                              </Link>
                              <span className="text-muted font-weight-bold d-block">
                                {
                                  item.category
                                }
                              </span>
                            </td>
                            <td></td>
                            <td className="text-right">
                              <span className="font-weight-bolder text-primary">
                                {
                                  item.count
                                } Sold
                              </span>
                            </td>
                          </tr>
                        )
                      }
                     

                    </tbody>
                  </table>
                </div>
              )
            )
          }
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Base Table Widget 6 */}
    </>
  );
}
