import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";
const baseUrl = getPrivateApi+"dispensaries";


export const saService = {
    getOneMonthAgo
};



async function getOneMonthAgo(slug){
    try {
       
        var axios = require('axios');

        var config = {
          method: 'get',
          url: 'https://simpleanalytics.com/weedzly.com.json?version=5&fields=pages',
          headers: { 
            // 'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
            // 'Content-Type': 'application/json',
            'Api-Key': 'sa_api_key_6nNMZH4F5D3OYacl7ZB3zvDmSYgWbAoPwr1a'
            // 'User-Id': 'sa_user_id_fb77b3dd-fc27-4af7-8ae1-ab4bd02c0116',
            // 'Access-Control-Allow-Headers': '*'
          }
        };
        
        axios(config).then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
        

        // let data = await window.gapi.client.request({
        //     path: "/v4/reports:batchGet",
        //     root: "https://analyticsreporting.googleapis.com/",
        //     method: "POST",
        //     body: {
        //         "viewId": "252669873",
        //         "dimensions": [
        //          {
        //           "name": "ga:pagePath"
        //          }
        //         ],
        //         "metrics": [
        //          {
        //           "expression": "ga:pageviews"
        //          }
        //         ],
        //         "dimensionFilterClauses": [
        //          {
        //           "filters": [
        //            {
        //             "operator": "EXACT",
        //             "dimensionName": "ga:pagePath",
        //             "expressions": [
        //              "/dispensaries/cpr"
        //             ]
        //            }
        //           ]
        //          }
        //         ],
        //         "dateRanges": [
        //          {
        //           "startDate": "1monthAgo",
        //           "endDate": "today"
        //          }
        //         ]
        //        },
        // })

    } catch (er) {
        console.log(er)
    }

   
    
}
