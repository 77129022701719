import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../_metronic/_partials/controls";

export function EditDialogHeader({ saveItem, actionsLoading, item }) {

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{(!item.day)?"EDIT ALL":`EDIT ${item.day}`}</Modal.Title>
      </Modal.Header>
    </>
  );

}
