import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPublicApi+"sponsor_areas";
const basePrivateUrl = getPrivateApi+"sponsor_areas";

export const sponsor_areaService = {
    getAll,
    update,
    find,
    create,
    getByDispensaryLocation
};


function getAll(query='') {
    return axios.get(`${basePrivateUrl}${query}`);
}

function update(id, params, queryParams='') {
    return axios.put(`${basePrivateUrl}/${id}${queryParams}`, params)
}

function find(id, params, queryParams='') {
    return axios.put(`${basePrivateUrl}/${id}`)
}

function getByDispensaryLocation(id) {
    return axios.get(`${basePrivateUrl}/business/${id}`)
}

function create(params) {return axios.post(basePrivateUrl, params);}