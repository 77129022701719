import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";
const baseUrl = getPrivateApi+"dispensaries";


export const dispensaryService = {
    getAll,
    getById,
    create,
    update,
    createByAdmin,
    getVariantsByProductId,
    createVariant,
    getVariantById,
	delete: _delete,
    updateIsFeature,
    removeVariant,
    remove,
    updateVariant,
    linkBrandProduct,
    getPendingById,
    approveRequest,
    updateSchedule,
    updateLicenses,
    getWithin,
    findSiblings,
    addDispensariesUrls
};


function getWithin(stateId, query=''){
    return axios.get(`${baseUrl}/within/${stateId}${query}`);
}

function getAll(query) {
    var fetchParam=""
    if(!query){fetchParam=`${baseUrl}`}
    else{fetchParam=`${baseUrl}${query}`}
    console.log(fetchParam)
    return axios.get(fetchParam);
}

function getVariantsByProductId(business_id, product, query='') {
    return axios.get(`${baseUrl}/variants/${business_id}/${product}${query}`);
}


function getById(id) {
    return axios.get(`${baseUrl}/${id}`);
}

function create(params) {
    return axios.post(baseUrl+'/createByBusiness', params);
}


function createVariant(business_id, params) {
    return axios.post(baseUrl+'/variants/'+business_id, params);
}

function getVariantById(business_id, product, id) {
    return axios.get(baseUrl+'/getvariant/'+business_id+'/'+product+'/'+id);
}

function updateVariant(business_id, id, params) {
    return axios.put(`${baseUrl}/variant/${business_id}/${id}`, params)
}

function linkBrandProduct(business_id, id, params) {
    console.log(business_id, id, params)
    return axios.put(`${baseUrl}/push_existing_product/${business_id}/${id}`, params);
}

function createByAdmin(params) {
    delete params.opens_ata
    delete params.closes_ata
    delete params.opens_ata_type
    delete params.closes_ata_type
    return axios.post(`${baseUrl}/createbyadmin`, params);
}

function update(business_id, params) {
    return axios.put(`${baseUrl}/${business_id}`, params)
}

function updateIsFeature(id, params) {return axios.put(`${baseUrl}/${id}/featured`, params)}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(id) {
    return axios.delete(`${baseUrl}/${id}`)
}

function removeVariant(business_id, id, params) {
    return axios.put(`${baseUrl}/removeVariant/${business_id}/${id}`, params)
}

function remove(id) {
    return axios.delete(`${baseUrl}/${id}`)
}

function addDispensariesUrls(params) {
    return axios.post(`${baseUrl}/create_many_url`, params)
}

function getPendingById(id) {
    return axios.get(baseUrl+'/pending/'+id);
}

function approveRequest(id, params) {
    return axios.put(`${baseUrl}/approveRequest/${id}`, params)
}

function updateSchedule(params) {
    return axios.put(`${baseUrl}/schedule`, params)
}

function updateLicenses(params, id) {
    console.log({params, id})
    return axios.put(`${baseUrl}/licenses/${id}`, params)
}

function findSiblings(value, query){
    console.log(query)
    return axios.get(`${baseUrl}/siblings/${value}${query}`,)
}