import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPrivateApi+"brands";


export const brandService = {
    getAll,
    getById,
    findOne,
    create,
    update,
	delete: _delete,
    updateIsFeature,
    approveRequest
};

function getAll(query) {
    var fetchParam=""
    if(!query){fetchParam=`${baseUrl}`}
    else{fetchParam=`${baseUrl}${query}`}
    return axios.get(fetchParam);
}

function getById(id) {return axios.get(`${baseUrl}/${id}`);}

function findOne(value, params='key=_id') {return axios.get(`${baseUrl}/${value}?${params}`);}

function create(params) {return axios.post(baseUrl, params);}

function update(id, params, queryParams='') {
    return axios.put(`${baseUrl}/${id}${queryParams}`, params)
}


function updateIsFeature(id, params) {return axios.put(`${baseUrl}/${id}/featured`, params)}

function _delete(id) {return axios.delete(`${baseUrl}/${id}`)}

function approveRequest(id, params) {return axios.put(`${baseUrl}/approveRequest/${id}`, params)}

