import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPrivateApi+"security";


export const securityService = {
    getAllActivity,
    getAllSessions,
    getAddedRecords
};

function getAllActivity(query) {
    var fetchParam=""
    if(!query){fetchParam=`${baseUrl}/activity`}
    else{fetchParam=`${baseUrl}/activity${query}`}
    return axios.get(fetchParam);
}

function getAllSessions(query) {
    var fetchParam=""
    if(!query){fetchParam=`${baseUrl}/sessions`}
    else{fetchParam=`${baseUrl}/sessions${query}`}
    return axios.get(fetchParam);
}

function getAddedRecords(query) {
    var fetchParam=""
    if(!query){fetchParam=`${baseUrl}/activity/addedPerDay`}
    else{fetchParam=`${baseUrl}/activity/addedPerDay${query}`}
    return axios.get(fetchParam);
}

