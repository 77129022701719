
import React from 'react'

export default function Spinner() {
    return(
        <div style={{zIndex:"5"}} className="overlay-layer bg-transparent" style={{width: "100%", zIndex: "5", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
          <div className="spinner spinner-lg spinner-success" />
        </div>
    )
}
