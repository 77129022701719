/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { forwardRef, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownMenu1 } from "../../../../_partials/dropdowns";



export function QuickActions({businesses, selectedBusiness, setSelectedBusiness, user}) {

  const history = useHistory()

  useEffect(() => {
      let selectedB = businesses.find(x => history.location.pathname.includes('businesses') && history.location.pathname.includes(x._id))
      setSelectedBusiness(selectedB)
  }, [businesses, window.location.pathname])

  return (
    <>
      {/* <OverlayTrigger
        placement="left"
        overlay={<Tooltip id="quick-actions-tooltip">Quick actions</Tooltip>}
      > */}
        <Dropdown className="dropdown-inline w-100" drop="down" alignRight>
          <Dropdown.Toggle
            className='w-100 d-flex justify-content-between align-items-center'
            id="dropdown-toggle-quick-actions-subheader"
          >
            {
              selectedBusiness ? (<>{
                selectedBusiness.type === 'DELIVERY' ? <i className="fa fa-truck text-white icon-md"></i> : <i className="fa fa-store text-white icon-md"></i>
            }<span style={{
              fontSize:'11px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'block',
              textOverflow: 'ellipsis',}} class="buttontext">{selectedBusiness.address}</span></>) : 'Businesses List'
              
            }
            
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
            <DropdownMenu1 businesses={businesses} selectedBusiness={selectedBusiness} user={user} />
          </Dropdown.Menu>
        </Dropdown>
      {/* </OverlayTrigger> */}
    </>
  );
}
