/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";

export function DispensaryAsideMenuList({ layoutProps, selectedBusiness }) {

  const location = useLocation();
  
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li
              className={`menu-item ${getMenuItemActive("/businesses/"+selectedBusiness._id+'/dashboard', false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to={"/businesses/"+selectedBusiness._id+'/dashboard'}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/businesses/"+selectedBusiness._id+"/menu", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to={"/businesses/"+selectedBusiness._id+"/menu"}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart1.svg")}/>
            </span>
              <span className="menu-text">Menu</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/businesses/"+selectedBusiness._id+"/products", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to={"/businesses/"+selectedBusiness._id+"/products"}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}/>
            </span>
              <span className="menu-text">Add Products</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/businesses/"+selectedBusiness._id+"/deals", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to={"/businesses/"+selectedBusiness._id+"/deals"}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sale2.svg")}/>
            </span>
              <span className="menu-text">Deals</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/businesses/"+selectedBusiness._id+"/dailydeals", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to={"/businesses/"+selectedBusiness._id+"/dailydeals"}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sale2.svg")}/>
            </span>
              <span className="menu-text">Daily Deals</span>
            </NavLink>
          </li>

          {/* <li
              className={`menu-item ${getMenuItemActive("/businesses/:business_id/calendardeals", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/businesses/:business_id/calendardeals">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sale2.svg")}/>
            </span>
              <span className="menu-text">Deals Calendar</span>
            </NavLink>
          </li> */}

          <li
              className={`menu-item ${getMenuItemActive("/businesses/"+selectedBusiness._id+"/orders", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to={"/businesses/"+selectedBusiness._id+"/orders"}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart2.svg")}/>
            </span>
              <span className="menu-text">Orders</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/businesses/"+selectedBusiness._id+"/reviews", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to={"/businesses/"+selectedBusiness._id+"/reviews"}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Chat1.svg")}/>
            </span>
              <span className="menu-text">Reviews</span>
            </NavLink>
          </li>
      
          {/* <li
              className={`menu-item ${getMenuItemActive("/businesses/:business_id/subscription", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/businesses/:business_id/subscription">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Credit-card.svg")}/>
            </span>
              <span className="menu-text">Subscription</span>
            </NavLink>
          </li> */}
          <li
              className={`menu-item ${getMenuItemActive("/businesses/"+selectedBusiness._id+"/loyalty", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to={"/businesses/"+selectedBusiness._id+"/loyalty"}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Gift.svg")}/>
            </span>
              <span className="menu-text">Loyalty</span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/businesses/"+selectedBusiness._id+"/users", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to={"/businesses/"+selectedBusiness._id+"/users"}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Contact1.svg")}/>
            </span>
              <span className="menu-text">Loyalty Users</span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/businesses/"+selectedBusiness._id+"/ads", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to={"/businesses/"+selectedBusiness._id+"/ads"}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")}/>
            </span>
              <span className="menu-text">Ads</span>
            </NavLink>
          </li>
        </ul>
      </>
  );
}
