/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import { useSelector } from 'react-redux'
import Swal from "sweetalert2";

export function BottomAsideMenu({ layoutProps, selectedBusiness }) {

  const { type, brand } = useSelector(state => state.auth.user)

  const showContact = () => {
		Swal.fire({
			title: 'Contact Info',
			html:
			  '<h4>Email: Michael@weedzly.com</h4>' +
			  '<h4>Phone #: 323-990-0555</h4>'
		  })
	}

  const location = useLocation();
  
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };
  // if(type==='BRAND' && !brand) return ''
  return (
      <>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/* {
            type==='BRAND' && brand  && <li
              className={`menu-item ${getMenuItemActive(`/${type==='DISPENSARY' || type==='DELIVERY' ? 'dispensary' : type==='BRAND' ? 'brand' : type==='DOCTOR' ? 'doctor' : ''}/landing`, false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to={`/${type==='DISPENSARY' || type==='DELIVERY' ? 'dispensary' : type==='BRAND' ? 'brand' : type==='DOCTOR' ? 'doctor' : ''}/landing`}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}/>
              </span>
                <span className="menu-text">Landing Builder</span>
              </NavLink>
            </li>
          } */}
         
          {
            type!='ADMIN' && type!='BRAND' && <li
              className={`menu-item ${getMenuItemActive("/businesses/"+selectedBusiness._id+'/settings', false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to={"/businesses/"+selectedBusiness._id+'/settings'}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")}/>
              </span>
                <span className="menu-text">Settings</span>
              </NavLink>
            </li>
          }
          <li
          onClick={()=>showContact()}
              className={`menu-item`}
              aria-haspopup="true"
            >
              <div className="menu-link" >
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Call.svg")}/>
              </span>
                <span className="menu-text">Contact Us</span>
              </div>
            </li>
        </ul>
      </>
  );
}
