import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";
const baseUrl = getPrivateApi+"analytics";

export const analyticsService = {
    reviews,
    getProfileViews
};

function reviews(business_id, queryParams='') {
    return axios.get(`${baseUrl}/${business_id}/reviews${queryParams}`)
}

function getProfileViews(business_id, queryParams='') {
    return axios.get(`${baseUrl}/${business_id}/profileViews${queryParams}`)
}