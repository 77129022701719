/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler, DropdownMenu4 } from "../../dropdowns";
import { Link } from 'react-router-dom'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import _ from 'lodash'

export function ImagesWidget({ disableSliderImage, bannerSize, banner, logo, storefront_picture, className, showWidget, picture, enableStorefront, image_preset, removePicture}) {

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
                <div className="font-weight-bolder">Bussiness Images</div>
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0">
          <div style={{paddingLeft:"2.25rem", paddingRight:"2.25rem"}}>
              <div className='row'>
              <div className='col-xl-3'>
                  <div className='d-flex flex-column'>
                    <div class="d-flex pb-4 flex-column flex-grow-1 mr-2">
                      <span class="font-weight-bold text-dark-75 font-size-lg mb-1">Logo</span>
                      <img 
                        src={ logo ? logo : picture 
                            ? picture 
                            : toAbsoluteUrl("/media/default-logo.jpg")
                        } 
                        style={{height:"100%"}} 
                        className='pb-4'
                      />
                      {
                        logo || picture
                        ? <div className='d-flex justify-content-end'>
                            <button type='button' className='btn btn-success mr-1' onClick={()=>showWidget(1, true, image_preset, 'logo')}>Update</button>
                            <button type='button' className='btn btn-danger' onClick={()=>removePicture('logo')}>Remove</button>
                          </div> 
                        : <div className='d-flex justify-content-end'>
                            <button type='button' className='btn btn-success' onClick={()=>showWidget(1, true, image_preset, 'logo')}>Upload</button>
                          </div>
                      }
                    </div>
                  </div>
                </div>
                <div className={ 'col-xl-6' }>
                  <div className='d-flex flex-column'>
                    
                    
                    <div class="d-flex pb-4 flex-column flex-grow-1 mr-2">
                      <span class="font-weight-bold text-dark-75 font-size-lg mb-1">Banner</span>
                      <img 
                        src={banner 
                            ? banner 
                            : toAbsoluteUrl("/media/default-banner.jpg")
                        } 
                        style={{height:"100%"}} 
                        className='pb-4'
                      />
                      {
                        banner 
                        ? <div className='d-flex justify-content-end'>
                            <button type='button' className='btn btn-success mr-1' onClick={()=>showWidget(bannerSize || 3.2, true, image_preset, 'banner')}>Update</button>
                            <button type='button' className='btn btn-danger' onClick={()=>removePicture('banner')}>Remove</button>
                          </div> 
                        : <div className='d-flex justify-content-end'>
                            <button type='button' className='btn btn-success' onClick={()=>showWidget(bannerSize || 3.2, true, image_preset, 'banner')}>Upload</button>
                          </div>
                      }
                    </div>
                  </div>
                </div>
                { !disableSliderImage && 
                   <div className='col-xl-3'>
                      <div className='d-flex flex-column'>
                        <div class="d-flex pb-4 flex-column flex-grow-1 mr-2">
                          <span class="font-weight-bold text-dark-75 font-size-lg mb-1">Slider Image</span>
                          <img 
                            src={storefront_picture 
                                ? storefront_picture 
                                : toAbsoluteUrl("/media/default-storefront_picture.jpg")
                            } 
                            style={{height:"100%"}} 
                            className='pb-4'
                          />
                          {
                            storefront_picture 
                            ? <div className='d-flex justify-content-end'>
                                <button type='button' className='btn btn-success mr-1' onClick={()=>showWidget(1.77, true, image_preset, 'storefront_picture')}>Update</button>
                                <button type='button' className='btn btn-danger' onClick={()=>removePicture('storefront_picture')}>Remove</button>
                              </div> 
                            : <div className='d-flex justify-content-end'>
                                <button type='button' className='btn btn-success' onClick={()=>showWidget(1.77, true, image_preset, 'storefront_picture')}>Upload</button>
                              </div>
                          }
                        </div>
                      </div>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      
       
    </>
  );
}

