import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPrivateApi+"claims";


export const claimService = {
    getById,
};

function getById(id) {return axios.get(`${baseUrl}/${id}`);}