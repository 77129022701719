import React, { useState, useEffect } from "react";
import {LicensesWidget, ListsWidget10,UserNotesWidget, ListsWidget11, MixedWidget6, MixedWidget10, MixedWidget11, MixedWidget12, TilesWidget1, TilesWidget3, TilesWidget10, TilesWidget11, TilesWidget12, TilesWidget13, TilesWidget14, MyLocationWidget, DispensaryProductsCountTile, DispensaryFulfilledOrdersCountTile } from "../widgets";
import { HoursOfOperationTable } from '../widgets/advance-tables/HoursOfOperationTable'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { dispensaryService } from '../../../app/services'
import { Link } from "react-router-dom";

const MAPBOX_ACCESS_TOKEN = process.env.MAPBOX_ACCESS_TOKEN

export function DoctorDashboard({getDashboardInfo}) {

    const user = useSelector(state => state.auth.user)

    const dispensaryInitialState = {}
    const [ dispensary, setDispensary ] = useState({dispensaryInitialState})

    const fetchedInitialState = false
    const [ fetched, setFetched ] = useState(fetchedInitialState)

    

    
    
    const fetchDispensary = () => {
        dispensaryService.getById(user._id)
            .then((res) => {
                console.log(res.data)
                setDispensary(res.data);
                setFetched(true)
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data.message || error.response.data.errors[0].message,
                })
            })
    }

    useEffect(() => {
        fetchDispensary();
    }, [])


    //LOADING SPINNER
    if(!fetched) return <div style={{zIndex:"5"}} className="overlay-layer bg-transparent" style={{width: "100%", zIndex: "5", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}><div className="spinner spinner-lg spinner-success" /></div>
    
    return <>
        {/* <div className="row">
            <div className="col-xl-3">
                <DispensaryProductsCountTile className="gutter-b" baseColor="primary" widgetHeight="150px" />
            </div>
            <div className="col-xl-3">
                <DispensaryFulfilledOrdersCountTile className="gutter-b" iconColor="success" widgetHeight="150px" />
            </div>
            <div className="col-xl-3">
                <TilesWidget11 className="gutter-b" baseColor="primary" widgetHeight="150px" />
            </div>
            <div className="col-xl-3">
                <TilesWidget12 className="gutter-b" iconColor="success" widgetHeight="150px" />
            </div>
        </div> */}

        {/* begin::Row */}
        {
            dispensary.status === 'UNPUBLISHED' &&
            <div className='row'>
                <div className="col-12">
                    <div class="alert d-flex align-items-center justify-content-between alert-danger" role="alert">
                        { 
                            dispensary.status === 'UNPUBLISHED' && <>
                            Your dispensary has unpublished status, please fill all the requested information to change the status. 
                            <Link to={"/businesses/"+dispensary._id+"/settings"} className='btn btn-light'> Go to settings</Link>
                        </>
                        }
                    </div>
                </div>

            </div>
        }
        <div className="row">
            <div className="col-xl-4">
                <div className="row">
                    <div className="col-xl-12">
                        <TilesWidget1 className="gutter-b card-stretch" chartColor="danger" />
                    </div>
                    <div className="col-xl-12">
                        {/* <TilesWidget1 className="gutter-b card-stretch" chartColor="danger" /> */}
                    </div>
                </div>
                
            </div>
            {
                console.log(dispensary)
            }
            <div className="col-xl-8">
                {
                    (fetched &&  !dispensary.location) ? (
                        <div className={`card card-custom card-stretch gutter-b`}>
                            {/* begin::Header */}
                            <div className="card-header border-0 pt-5">
                              <div className="card-title">
                                <div className="card-label">
                                    <div className="font-weight-bolder"></div>
                                  <div className="font-size-sm text-muted mt-2"></div>
                                </div>
                              </div>
                            </div>
                            <div className="card-body d-flex flex-column px-0">
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    height: '100%',
                                    flexDirection: 'column',
                                }}>
                                  <h4 className='font-size-h4'>Please fill the business information</h4>
                                  <Link to="/entity/update" className='btn btn-info'>Update business</Link>
                                </div> 
                            </div>
                        </div>
                    ) : (
                        <MyLocationWidget 
                            className="gutter-b" 
                            iconColor="success" 
                            dispensaryName={dispensary.name} 
                            mapURL = {`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s-commercial+285A98(${dispensary.location.coordinates[0]},${dispensary.location.coordinates[1]})/${dispensary.location.coordinates[0]},${dispensary.location.coordinates[1]},16,0/400x250@2x?access_token=pk.eyJ1IjoiYW50aG9ueTk1MiIsImEiOiJjazl2enJuMWswNHJhM21vNHBpZGF3eXp0In0.zIyPl0plESkg395zI-WVsg`}
                        />
                    )
                    
                }
                
            </div>
        </div>
        {/* end::Row */}
                
        {/* begin::Row */}
        <div className="row">
            <div className="col-lg-6 col-xxl-4">
                <LicensesWidget licenses={dispensary.licenses} fetchDispensary={fetchDispensary} fetched={fetched} className="card-stretch gutter-b" />
            </div>

            <div className="col-lg-6 col-xxl-8">
                <HoursOfOperationTable className="card-stretch gutter-b" schedule={dispensary.schedule} fetchDispensary={fetchDispensary} fetched={fetched} />
            </div>
        </div>
        <div className="row">
            <div className='col-xl-6'>
                <UserNotesWidget className="card-stretch gutter-b" />
            </div>
        </div>
        {/* <div className="row">
            <div className="col-lg-6">
                <ListsWidget10 className="card-stretch gutter-b" />
            </div>
            <div className="col-lg-6">
                <MixedWidget6 className="gutter-b card-stretch" chartColor="danger" />
            </div>
        </div> */}
        {/* end::Row */}

        {/* end::Dashboard */}
    </>;
}
