import React, { useState, useRef, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useSelector } from 'react-redux'
import * as Yup from 'yup';
import "mapbox-gl/dist/mapbox-gl.css";
import MaskedInput from 'react-text-mask'
import { dispensaryService } from '../../../../app/services';
import {Input} from '../../controls'
import Swal from 'sweetalert2'
import { useDispatch, connect } from 'react-redux'
import * as auth from "../../../../app/modules/Auth/_redux/authRedux";
import { Link, useHistory } from 'react-router-dom'
import Spinner from '../../spinner/Spinner'
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { Modal } from 'react-bootstrap';
import { businesses_image_preset } from '../../../../app/helpers/imageRepositories';
import { Editor } from '@tinymce/tinymce-react';
import { openUploadBox } from "../../../../_metronic/_partials/widgets/cloudinary/cloudinary";

function EditForm(props) {

    const history =  useHistory()

    const user = useSelector(state => state.auth.user)
    const dispatch = useDispatch()


    const styles = {
        width: "100%",
        position: "relative",
        height:"500px"
    };

    const dispensaryInitialState={}
    const [ dispensary, setDispensary ] = useState(dispensaryInitialState)


    const initialValues = {

        isAccessible: dispensary.amenities ? dispensary.amenities.isAccessible : false,
        isSecurityAvailable: dispensary.amenities ? dispensary.amenities.isSecurityAvailable : false,
        isAtmAvailable: dispensary.amenities ? dispensary.amenities.isAtmAvailable : false,
        isCreditCardAccepted: dispensary.amenities ? dispensary.amenities.isCreditCardAccepted : false,

        phone: dispensary.phone,
        website: dispensary.website,
        email: dispensary.email,
        twitter: dispensary.twitter,
        instagram: dispensary.instagram,
        facebook: dispensary.facebook,
        city: dispensary.city ? dispensary.city.name : '',
        state: dispensary.city ? dispensary.city.state.name : '',
  
        about: dispensary.about,
        content: dispensary.content,
        disclaimer: dispensary.disclaimer,

    };

    const fetch = () => {
        props.setActionsLoading(true)
        dispensaryService.getById(props.dispensaryId)
            .then((res) => {
                setDispensary(res.data)
            })
            .catch((error) => {

            })
            .finally(e => {
                props.setActionsLoading(false)
            })
    }


    useEffect(() => {
        fetch()
    }, [])




    const validationSchema = Yup.object().shape({
       
      
        isAccessible: Yup.bool(),
        isSecurityAvailable: Yup.bool(),
        isAtmAvailable: Yup.bool(),
        isCreditCardAccepted: Yup.bool(),
        email: Yup.string()
            .max(50)
            .email()
            .required(),
        phone: Yup.string()
            .required('Phone number is required')
            .max(20),
        website: Yup.string()
            .max(200),
        twitter: Yup.string()
            .max(100),
        instagram: Yup.string()
            .max(100),
        facebook: Yup.string()
            .max(100),
        about: Yup.string().max(5000, 'Maximum 5000 symbols'),
        content: Yup.string().max(100000, 'Maximum 100000 symbols'),
        disclaimer: Yup.string()
            .max(5000),
    });
  

    function ImageUrlFormatter(src) {
		return src.substr(0, src.indexOf("/upload/")+8).concat(`fl_progressive:steep,f_auto,c_fill,g_auto`).concat( src.substr(src.indexOf("/upload/")+8, src.length))
	}

  

    function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
        const {  
            phone,
            email,

        
            isCreditCardAccepted,
            isAtmAvailable,
            isAccessible,
            isSecurityAvailable,

            website,
            instagram,
            facebook,
            twitter,

            content,
            about,
        } = fields

        let payload = {
            content,
            phone,
            email,
            amenities:{
                isSecurityAvailable,
              
                isCreditCardAccepted,
                isAtmAvailable,
                isAccessible,
            },
            website,
            instagram,
            facebook,
            twitter,
            about
        }

        setStatus();

        props.setActionsLoading(true)
        dispensaryService.update(dispensary._id, payload)
         .then(() => {
             dispatch(props.requestUser);
             Swal.fire(
                'Done!',
                'The item has been saved!',
                'success'
              )
              props.onHide()
         })
         .catch(error => {
             console.log(error.response.data)
             setSubmitting(false);
             Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message || error.response.data.errors[0].message,
            })
         })
         .finally(e => {
            props.setActionsLoading(false)
         })
        
        
    }

    return (
        <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting, values, handleChange, setFieldValue, setFieldTouched }) => (
                <Form className="form form-label-right dispensary-form">
                    <Modal.Body>
                    {props.actionsLoading && <Spinner></Spinner>}
                    <div className="form-group row">
                        <div className="col-lg-4">
                            
                            <h3>Business Basic Info</h3>
                            <label>Phone number *</label>
                            <Field  name="phone"
                                
							    render={({ field, form, setFieldTouched }) => (
								    <MaskedInput
                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')} 
                                        {...field}
                                        guide={true}
                                        placeholder="(123) 456-7890"
                                        onBlur={setFieldTouched}
                                        onChange={(e) => {
                                            handleChange(e)
                                            const value = e.target.value || '';
                                            setFieldValue('phone', value);
                                        }}
                                    />
								)}
								type="text"  
							/>
                            <Field
                                name="email"
                                component={Input}
                                type="email"
                                placeholder="Business Email"
                                label="Business Email *"
                            />

                        </div>
                        <div className="col-lg-4">
                            {
                                (dispensary.type==='DISPENSARY' || dispensary.type==='DOCTOR') && <>
                                    <h3>Amenities</h3>
                                    <label className="checkbox checkbox-single" for="isCreditCardAccepted">
                                        <Field type="checkbox" name="isCreditCardAccepted" id="isCreditCardAccepted" className="form-selectgroup-input" />
                                        <span></span>
                                        &nbsp;PAY WITH CREDIT CARD
                                    </label>

                                    <label className="checkbox checkbox-single" for="isAtmAvailable">
                                        <Field type="checkbox"  name="isAtmAvailable" id="isAtmAvailable" className="form-selectgroup-input" />
                                        <span></span>
                                        &nbsp;ATM AVAILABLE
                                    </label>

                                    <label className="checkbox checkbox-single" for="isSecurityAvailable">
                                        <Field type="checkbox" name="isSecurityAvailable" id="isSecurityAvailable" className="form-selectgroup-input" />
                                        <span></span>
                                        &nbsp;SECURITY
                                    </label>

                                    <label className="checkbox checkbox-single" for="isAccessible">
                                        <Field type="checkbox" name="isAccessible" id="isAccessible" className="form-selectgroup-input" />
                                        <span></span>
                                        &nbsp;ACCESIBILITY
                                    </label>
                                  
                                   

                                    
                                </>
                            }                
                  
                        </div>
                        <div className="col-lg-4">
                            <h3>Links and social networks</h3>
                            <Field
                                name="website"
                                component={Input}
                                placeholder="https://example.com/"
                                label="Website"
                                type="text"
                            />

                            <Field
                                name="facebook"
                                component={Input}
                                placeholder="https://www.facebook.com/example"
                                label="Facebook"
                                type="text"
                            />

                            <Field
                                name="twitter"
                                component={Input}
                                placeholder="https://twitter.com/example"
                                label="Twitter"
                                type="text"
                            />

                            <Field
                                name="instagram"
                                component={Input}
                                placeholder="https://www.instagram.com/example"
                                label="Instagram"
                                type="text"
                            />          
                        </div>
                    </div>
                    
                    <div className="form-group row">
                        
                        <div className={`${dispensary.type === 'DOCTOR' ? 'col-6' : 'col-12'}`}>
                            <div className="mb-3">
                                <label>About us</label>
                                <Field name="about" as="textarea" className={'form-control' + (errors.about && touched.about ? ' is-invalid' : '')} data-toggle="autosize" placeholder="Enter about us" style={{overflow: "hidden", overflowWrap: "break-word", height: "100.9792px"}}></Field>
                                <ErrorMessage name="about" component="div" className="invalid-feedback" />
                            </div>
                        </div>

                        

                       
                        {
                            dispensary.type === 'DOCTOR' && <div className="col-6">
                                <div className="mb-3">
                                    <label>Disclaimer</label>
                                    <Field name="disclaimer" as="textarea" className={'form-control' + (errors.disclaimer && touched.disclaimer ? ' is-invalid' : '')} data-toggle="autosize" placeholder="Enter disclaimer" style={{overflow: "hidden", overflowWrap: "break-word", height: "100.9792px"}}></Field>
                                    <ErrorMessage name="disclaimer" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                        }
                    </div>
                    <div className="form-group row">
                        <div className='col col-12'>
								<label>Description content</label>
								<Field
									name="content"
									as="textarea"
									className={'form-control' + (errors.content && touched.content ? ' is-invalid' : '')}
									render={({ field, form }) => (
										<div className={(errors.content && touched.content ? ' is-invalid' : '')}>
											<Editor 
												apiKey={"vuvy0ql1aae4dc7r3t548hpg7c1htcs6hgylirx9kyyh2iwu"}
												// initialValue={values.content}
												value={values.content}
												init={{
													height: 500,
													menubar: 'tools', browser_spellcheck : true, 
													image_title: true,
													automatic_uploads: true,
													file_picker_types: 'image',
													plugins: [
														'advlist autolink link lists  image charmap print preview anchor',
														'searchreplace visualblocks code fullscreen',
														'insertdatetime media image table paste code help wordcount'
													],
													block_formats: 'Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6; Paragraph=p;',
													toolbar:
														`undo redo | formatselect | bold italic backcolor | 
												alignleft | 
												bullist numlist outdent indent | link | removeformat | media image`,
													file_picker_callback: function (cb, value, meta) {
														/* var input = document.createElement('input');
														input.setAttribute('type', 'file');
														input.setAttribute('accept', 'image/*'); */

														/*
														  Note: In modern browsers input[type="file"] is functional without
														  even adding it to the DOM, but that might not be the case in some older
														  or quirky browsers like IE, so you might want to add it to the DOM
														  just in case, and visually hide it. And do not forget do remove it
														  once you do not need it anymore.
														*/
														openUploadBox(url => {
															cb(ImageUrlFormatter(url), { title: url });
														}, 1, false, businesses_image_preset)
													
														/* input.onchange = function () {

															
															var file = this.files[0];

															var reader = new FileReader();
															reader.onload = function () {
																
																var id = 'blobid' + (new Date()).getTime();
																
																var base64 = reader.result;
																
																cb(base64, { title: file.name });
															};
															reader.readAsDataURL(file);
														};

														input.click(); */
													},

												}}
												onEditorChange={(content) => {
													setFieldValue('content', content);
												}}
											/>
										</div>
										)}
								/>

								<ErrorMessage name="content" component="div" className="invalid-feedback" />
							</div>
                            </div>
                    </Modal.Body>
                    <Modal.Footer>
                    
                        <div className="d-flex" style={{justifyContent:"space-between"}}>
                        
                           
                            <button type="button" onClick={()=>props.onHide()} disabled={props.actionsLoading} className="btn btn-danger mr-2">
                            {
                                props.actionsLoading && <span className="spinner-border spinner-border-sm mr-1"></span>
                            }
                                Cancel
                            </button>
                            <button type="submit" disabled={props.actionsLoading} className="btn btn-primary ml-aut">
                            {
                                props.actionsLoading && <span className="spinner-border spinner-border-sm mr-1"></span>
                            }
                                Save
                            </button>
                      
                    </div>
                    </Modal.Footer>
                </Form>
            )}
        </Formik>
    )
}
export default connect(null, auth.actions)(EditForm);
