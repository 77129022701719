import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPrivateApi+"purchases";


export const purchaseService = {
    getAll,
    getById,
    create,
    update,
    getAllPending,
    getByUserId,
    delete: _delete,
};


function getAll(query) {
    var fetchParam=""
    if(!query){fetchParam=`${baseUrl}`}
    else{fetchParam=`${baseUrl}${query}`}
    return axios.get(fetchParam);
}

function getById(id) {
    return axios.get(`${baseUrl}/${id}`);
}
function getAllPending(query) {
    var fetchParam=""
    if(!query){fetchParam=`${baseUrl}/pending`}
    else{fetchParam=`${baseUrl}/pending${query}`}
    return axios.get(fetchParam);
}
function getByUserId(id) {
   
    return axios.get(`${baseUrl}/user/${id}`)  .then(category => {
        return category;
})}

function create(params) {

    return axios.post(baseUrl, params);
}

function update(id, params) {
    return axios.put(`${baseUrl}/${id}`, params)
}

function _delete(id) {
    return axios.delete(`${baseUrl}/${id}`)
}

