import React, { useEffect, useState, useRef } from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";
import queryString from "query-string"
import { checkVerifyMailToken } from "../_redux/authCrud";
import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timerProgressBar: false,
  onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

const VerifyMail = () => {
	const [ redirect, setRedirect ] = useState(false)
	const [ checkingToken, setCheckingToken ] = useState(true)
	useEffect(() => {
		
		let { token } = queryString.parse(window.location.search);
		if(token){
			checkToken(token)
		} else {
			setCheckingToken(false)
			setRedirect(true)
		}
		
	}, [])

	const checkToken = async (token) => {
		
		try{
			await checkVerifyMailToken(token);
			
			Toast.fire({
				icon: 'success',
				title: 'Your email address was verified.'
			})
			setRedirect(true)
		}catch(ex){
			Toast.fire({
				icon: 'error',
				title: 'Link expired or invalid.'
			})
		}
		setCheckingToken(false)
	}

  return( 
    <>
	{
		checkingToken ? <LayoutSplashScreen /> : redirect?<Redirect to="/auth/login" />:<div className='text-center'>
			<h1>Email address verification</h1><br></br><br></br><h4 className='text-danger text-center'>Link expired or invalid</h4>
		</div>
	}
	</>
  )
}


export default VerifyMail
