/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import {Tab, Nav} from "react-bootstrap";
import {AdminNotificationList} from './AdminNotificationList'
import { useSelector } from 'react-redux'


export function QuickPanel() {


  const user = useSelector(state => state.auth.user)

  const [selectedTab, setSelectedTab] = useState("Notifications");

  const setTab = _tabName => {
    setSelectedTab(_tabName);
  };

  return (
      <div id="kt_quick_panel" className="offcanvas offcanvas-left pt-5 pb-10">
        <Tab.Container
            defaultActiveKey={selectedTab}
        >
          {/*begin::Header*/}
          <div
              className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
            <Nav
                onSelect={setTab}
                as="ul"
                role="tablist"
                className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
            >
             
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                    eventKey="Notifications"
                    className={`nav-link ${
                        selectedTab === "Notifications" ? "active" : ""
                    }`}
                >
                  Notifications
                </Nav.Link>
              </Nav.Item>
           
            </Nav>

            <div className="offcanvas-close mt-n1 pr-5" style={{position: "absolute", top: "15px", right: "10px"}}>
              <a
                  href="#"
                  className="btn btn-xs btn-icon btn-light btn-hover-primary"
                  id="kt_quick_panel_close"
              >
                <i className="ki ki-close icon-xs text-muted"></i>
              </a>
            </div>
          </div>
          {/*end::Header*/}

          {/*begin::Content*/}
          <div className="offcanvas-content px-10">
            <div className="tab-content">
             
              <div
                  id="kt_quick_panel_notifications"
                  role="tabpanel"
                  className={`tab-pane pt-2 pr-5 mr-n5 ${selectedTab === "Notifications" ? "active show" : ""}`}
              > 
              <AdminNotificationList />
              
                 {/* {
                user.type==="ADMIN" && <AdminNotificationList />
              } */}
				
             	</div> 
              </div>
              
           
          </div>
          {/*end::Content*/}
        </Tab.Container>
      </div>
  );
}
