import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MapDialogHeader } from './MapDialogHeader'
import { dispensaryService } from '../../../../app/services'
import { AreaOfCoverageUpdateMap } from './AreaOfCoverageUpdateMap'
import Swal from 'sweetalert2'
import { AddPinpointToMap } from './AddPinpointToMap'
import { PlacesList } from "./PlacesList";
import { CitiesList } from "./CitiesList";
import { NewBusinessForm } from "./NewBusinessForm";

export function NewBusinessModal({show, onHide}) {

    const actionsLoadingInitialState = false
    const [ actionsLoading, setActionsLoading ] = useState(actionsLoadingInitialState)

    return (
        <Modal
          size="lg"
          show={show}
          onHide={onHide}
          aria-labelledby="example-modal-sizes-title-lg"
        >
    
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">New Business</Modal.Title>
        </Modal.Header>
        <Modal.Body>
             <NewBusinessForm actionsLoading={actionsLoading} onHide={onHide} setActionsLoading={setActionsLoading}  />
        </Modal.Body> 
        </Modal>
    )
    
}




