const profiles_image_preset = 'spj28hqq'
const strains_image_preset = 'ydhn8trr'
const brands_image_preset = 'pzmxiahe'
const products_image_preset = 'ymhijlld'
const businesses_image_preset = 'lm03jaqt'
const articles_image_preset = 'ejo2tytd'
const states_image_preset = 'whaiegsz'
const general_images_preset = 'ggd3evx8'
export { 
    profiles_image_preset,
    strains_image_preset, 
    brands_image_preset,
    products_image_preset,
    businesses_image_preset,
    articles_image_preset,
    states_image_preset,
    general_images_preset
};

