/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import {Tab, Nav} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../../_helpers";
import socketIOClient from "socket.io-client";
import { useSelector } from 'react-redux'
import { useHistory, Link, useRouteMatch } from 'react-router-dom'
import { getPublicApi } from '../../../../../app/helpers/config'
import { notificationService } from '../../../../../app/services'
import {DesktopNotification} from '../../../../../app/helpers/desktopNotifications'
import InfiniteScroll from "react-infinite-scroll-component";
import { createStore } from 'redux';

const moment = require('moment');

const socket = socketIOClient(getPublicApi.substr(0,getPublicApi.indexOf("api")), {
	withCredentials: false,
  });



function hasNotification(state = 0, action) {
	switch (action.type) {
		case 'SET':
			return action.count;
		default:
			return state;
	}
}

let storeNotification = createStore(hasNotification);

export {storeNotification};


export function AdminNotificationList(props) {

  const user = useSelector(state => state.auth.user)
  const history = useHistory()
	const routerMatch = useRouteMatch()


  const itemsInitialState = [];
	const [items, setItems] = useState(itemsInitialState)
	const newItemInitialState = [];
	const [newItem, setNewItem] = useState(newItemInitialState)
	const isFetchedInitialState = false;
	const [isFetched, setIsFetched] = useState(isFetchedInitialState)
	const [existUnreaded, setExistUnreaded] = useState(false)
	const [existLayoutUnreaded, setExistLayoutUnreaded] = useState(false)
	const [hasMore, setHasMore] = useState(false)
	const [page, setPage] = useState(1)
	const length = 10;

	const markAllAsReaded = () => {
		notificationService.allReaded(user._id).then(res => {
			const updatedItems = items.map(item=>{
				item.readed = true;
				return item
			})
			setItems(updatedItems)
			setExistLayoutUnreaded(false)
			setExistUnreaded(false)
		});
	}

	const fetchData = () => {
		notificationService.getAll(`?size=${length}&page=${page}&sortField=createdAt&sortOrder=desc`).then(res => {
			console.log(res.data.totalData)
			if(res.data.totalData.length > 0) {
				setItems(res.data.totalData);
            	if(res.data.totalCount[0].count===items.length+res.data.totalData.length) {
					setHasMore(false)
				}
			}
        	if(res.data.alert) {
				setExistUnreaded(true)
			}
			setIsFetched(true);
			setPage(page+1)
		})
	}

	//console.log("existunreaded",existUnreaded);
	storeNotification.dispatch({type:"SET",count:items.filter(e=>e).filter(e=>!e.readed).length});

	const fetchLastest = () => {
		
		notificationService.getAll("?size=1&page=0&sortField=createdAt&sortOrder=desc").then(res => {
			setNewItem(res.data.totalData)
		})
	}

	const fetchMoreData = () => {
		notificationService.getAll(`?size=${length}&page=${page}&sortField=createdAt&sortOrder=desc`).then(res => {
			setPage(page+1)
            setItems(items.concat(res.data.totalData))
            console.log(items.length+res.data.totalData.length);
			if(res.data.totalCount[0].count===items.length+res.data.totalData.length){
				setHasMore(false)
			}
		})
	}

	const markAsReadedAndRedirect = async (item) => {
		
		if(!item.readed)
		{
			const newItem = { readed:true }
			item.readed=true;
			notificationService.update(item._id, newItem).then(res => {
				const updatedItems = items.map(x => {
					if(x._id===item._id){
						return item
					}else{
						return x
					}
				});
				
				setItems(updatedItems)
				checkLayoutReaded();
				// history.push(`${user.type==="ADMIN"?"/admin/":"/"}${item.path}`)
			})
		}
		else{
		
			// history.push(`${user.type==="ADMIN"?"/admin/":"/"}${item.path}`)
		}
	}

	const checkLayoutReaded = () => {
		var unreadedCount = 0;
		items.map(x => {
			if(x.readed==false){
				unreadedCount++
			}
		})
		if(unreadedCount<1){
			setExistLayoutUnreaded(false)
		}
	}

	const adminSocketsCotroller = () => {
		socket.on('newPlanPurchase', function (item) {
			DesktopNotification(item.description, item.picture, item.title)
			setExistLayoutUnreaded(true)
			fetchLastest()
		});
	}

	const dispensarySocketsController = () => {
		
		socket.on('updatedPurchaseStatus', function (item) {

			DesktopNotification(item.description, item.picture, item.title)
			setExistLayoutUnreaded(true)
			fetchLastest()
		});

	}
	
	
    useEffect(() => {
		if(user.type != 'PUBLISHER'){

			socket.on("connect", () => {
				
				Notification.requestPermission();
			});
		
			socket.on('pushNotification', function (item) {
				
				DesktopNotification(item.description, item.picture, item.title)
				setExistLayoutUnreaded(true)
				fetchLastest()
			});
		
			socket.emit("setUserSocket", user._id);
		
			
			if(user.type === "ADMIN"){
				adminSocketsCotroller()
			}
			if(user.type === "DISPENSARY" || user.type === "DELIVERY"){
				dispensarySocketsController()
			}
		
			fetchData()
		}
	}, [])

	const addNewNotification = (item) => {
		if(item && item.length > 0){
			const newItemsArray = [item[0], ...items]
			setItems(newItemsArray);
		}
	}

	useEffect(() => {
		addNewNotification(newItem)
    }, [newItem])






    const adminMapItems = () => {
		return (
            // <div
            //     id="kt_quick_panel_notifications"
            //     role="tabpanel"
            //     // className={`tab-pane fade pt-2 pr-5 mr-n5 scroll ps ${selectedTab === "Notifications" ? "active show" : ""}`}
            //     className={`tab-pane fade pt-2 pr-5 mr-n5 scroll ps active show`}
            // >
                <div className="navi navi-icon-circle navi-spacer-x-0" >
			      {console.log(items)}
                    {
                        items.length>0 && items.map((item, index) =>  
                            <Link  key={index} to="/admin/finance/subscriptions" style={{marginBottom:"1em"}} onClick={()=>{markAsReadedAndRedirect(item)}} className={`navi-item ${(item.readed)?"":"bg-light-info rounded"}`}>
                               <div className="navi-link rounded">
                                 <div className="symbol symbol-50 mr-3">
                                   <div className="symbol-label no-back">
                                     <i className={`flaticon-users-1 icon-lg`}></i>
                                   </div>
                                 </div>
                                 <div className="navi-text">
                                     <small>{moment(item.createdAt).fromNow(true)} ago</small>
                                   <div className="font-weight-bold  font-size-lg">
                                     {`${item.transmitter.name} subscribed: `}  
                                   </div>
                                   <div className="text-muted">
                                     {`${item.description.substr(0, item.description.indexOf(" "))} Plan`}
                                   </div>
                                 </div>
                               </div>
                            </Link>
                    )}
                    
			      { hasMore && <button style={{width:"100%"}} onClick={()=>fetchMoreData()} className="btn btn-success">LOAD MORE</button>}
                </div>
            // </div>
		
		)
	}



	const dispensaryMapItems = () => {
		return (
            // <div
            //     id="kt_quick_panel_notifications"
            //     role="tabpanel"
            //     // className={`tab-pane fade pt-2 pr-5 mr-n5 scroll ps ${selectedTab === "Notifications" ? "active show" : ""}`}
            //     className={`tab-pane fade pt-2 pr-5 mr-n5 scroll ps active show`}
            // >
                <div className="navi navi-icon-circle navi-spacer-x-0" >
			      {console.log(items)}
                    {
                        items.length>0 && items.map((item, index) =>  
                            <Link  key={index} to={`/businesses/${item.order.dispensary}/${item.path}/details`} style={{marginBottom:"1em"}} onClick={()=>{markAsReadedAndRedirect(item)}} className={`navi-item ${(item.readed)?"":"bg-light-info rounded"}`}>
                            
							   <div className="navi-link rounded">
                                 <div className="symbol symbol-50 mr-3">
                                   <div className="symbol-label no-back">
                                     <i className={`flaticon-users-1 icon-lg`}></i>
                                   </div>
                                 </div>
                                 <div className="navi-text">
                                     <small>{moment(item.createdAt).fromNow(true)} ago</small>
                                   <div className="font-weight-bold  font-size-lg">
                                     {`${item.transmitter ? '@'+item.transmitter.nickname : 'Guest user'} ${item.title}`}  
                                   </div>
                                   <div className="text-muted">
                                     {item.description}
                                   </div>
                                 </div>
                               </div>
                            </Link>
                    )}
                    
			      { hasMore && <button style={{width:"100%"}} onClick={()=>fetchMoreData()} className="btn btn-success">LOAD MORE</button>}
                </div>
            // </div>
		
		)
	}





    if(!isFetched) return <p>Loading...</p>
  	return (
  	  <>
		{
			user.type === 'ADMIN' 
				? adminMapItems()
				: user.type === 'DISPENSARY' 
				? dispensaryMapItems()
				: user.type === 'DELIVERY'
				? dispensaryMapItems()
				: user.type === 'RETAILER'
				? dispensaryMapItems()
				: ''
		} 
  	  </>
  	);
}
