import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPublicApi+"sponsor_subareas";
const basePrivateUrl = getPrivateApi+"sponsor_subareas";

export const sponsor_subareaService = {
    getAll,
    update,
    getById
    // create
};

function getById(_id) {
    return axios.get(`${basePrivateUrl}/${_id}`);
}

function getAll(query='') {
    return axios.get(`${basePrivateUrl}${query}`);
}

function update(id, params, query='') {
    return axios.put(`${basePrivateUrl}/${id}${query}`, params)
}

// function create(params) {return axios.post(basePrivateUrl, params);}