/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import UserNotesUpdateDialog from './UserNotesUpdateDialog'
import { accountService } from '../../../../app/services'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'

export function UserNotesWidget({ 
  className
}){

  const { notes } = useSelector(state => state.auth.user)

  const showMapModalInitialState = false
  const [showMapModal, setShowMapModal] = useState(showMapModalInitialState)

  const editableWidgedInitialState = false
  const [editableWidged, setEditableWidged] = useState(editableWidgedInitialState)

  return (
    <>
      <div className={`card card-rounded   card-custom ${className}`} style={{maxHeight:'500px'}}>
        {
          showMapModal && (
            <UserNotesUpdateDialog  
              show={showMapModal} 
              onHide={()=>{setShowMapModal(false)}} 
              notes={notes}
            />
          )
        } 
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
                <div className="font-weight-bolder text-success">My notes</div>
              {/* <div className="font-size-sm text-muted mt-2">Area covered by your delivery service</div> */}
            </div>
          </div>
          <div className="card-toolbar">
            <button 
              type='button' 
              className="btn btn-success font-weight-bolder font-size-sm"
              onClick={()=>setShowMapModal(true)}
            >
                  <i className="flaticon-edit"></i>
                  Update notes
            </button>
          </div>
        </div>
        <div className="card-body d-flex flex-column card-body mt-2 mb-2 justify-content-center" style={{minHeight:'150px', backgroundColor:'white'}}>
        {
          notes && notes.length > 0 ? <p style={{whiteSpace:'pre-line', overflowY:'auto'}}>{notes}</p> : <center><h4>No notes found</h4></center>
            
        }
        </div>
      </div>
    </>
  );
}