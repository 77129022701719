import React, { useState, useRef, useEffect } from 'react';
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { mapboxService, sponsor_subareaService } from '../../../../app/services';
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import _, { debounce } from 'lodash'
import * as turf from "@turf/turf";
import { array } from 'prop-types';
import { useSelector } from 'react-redux';



export const AreaOfSponsorUpdateMap = ({
    _selectedLocation,
    actionsLoading,
    dispensaryId,
    saveItem,
    area,
    onHide,
    _setSelectedLocation,
    location,
    hideButtons,
    city, 
    data,
    selectedArea,
setSelectedArea,
    delete_id,
    dispensaryType,
    setDelete_id,
deleteZone
}) => {

    const [ selectedPlace, setSelectedPlace ] = useState({})
    const mapContainer = useRef(null);
    const user = useSelector(state => state.auth.user);
    //Initial states
    const mapInitialState=null
    const longitudeInitialValue=0; 
    const latitudeInitialValue=0;

    //States
    const [map, setMap] = useState(mapInitialState);
    const [latitude, setLatitude] = useState(location ? location.coordinates[1] : latitudeInitialValue)
	const [longitude, setLongitude] = useState(location ? location.coordinates[0] : longitudeInitialValue)
	const [geometry, setGeometry] = useState(null);
    const [markerAddress, setMarkerAddress] = useState(null);
    const [markerZip, setMarkerZip] = useState(null);
    const [markerCity, setMarkerCity] = useState(null);
    const [markerState, setMarkerState] = useState(null);
    const [enableSearch, setEnableSearch] = useState(false);
    const [instMode, setInstMode] = useState(false);
    

    const [ zoom, setZoom ] = useState(10)

    const getAddress = async () => {
        let response = await mapboxService.reverseGeocoding(longitude, latitude, '&types=address&')
        if(response.data.features.length > 0 ){
            setMarkerAddress(response.data.features[0].address ? response.data.features[0].address+' '+response.data.features[0].text : response.data.features[0].text)
            setMarkerZip(response.data.features[0].context.find(x => x.id.includes('postcode')))
            setMarkerCity(response.data.features[0].context.find(x => x.id.includes('place')))
            setMarkerState(response.data.features[0].context.find(x => x.id.includes('region')))
        }
    }

    useEffect(() => {
        if(longitude !=0 && latitude !=0) {
            getAddress()
        }
    }, [longitude, latitude])

    const styles = {
        width: "100%",
        position: "relative",
        height:"500px"
    };
    const [ bounds, setBounds ] = useState({})

    const [ addedFree, setAddedFree ] = useState(false)
    const [ addedTaken, setAddedTaken ] = useState(false)
    const [ addedOwned, setAddedOwned ] = useState(false)

    const [ gettingSquares, setGettingSquares ] = useState(false)


    const findFree = (x) => {
        if(!x[dispensaryType.toLowerCase()]){
            return true
        } else if(!Array.isArray(x[dispensaryType.toLowerCase()])){
            if(x[dispensaryType.toLowerCase()] === dispensaryId){
                return false
            } else if(x[dispensaryType.toLowerCase()].length >5){
                return false
            }
        } else if(Array.isArray(x[dispensaryType.toLowerCase()])){
            if(x[dispensaryType.toLowerCase()].length < 7){
                if(x[dispensaryType.toLowerCase()].includes(dispensaryId)){
                 
                    return false
                } else {
                    
                    return true
                }
            } else {
                console.log('3')
                return false
            }
        }
    }

    const findTaken = (x) => {
        if(!x[dispensaryType.toLowerCase()]){
            return false
        } else if(!Array.isArray(x[dispensaryType.toLowerCase()])){
            if(x[dispensaryType.toLowerCase()].length >5){
                if(x[dispensaryType.toLowerCase()] === dispensaryId){
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        } else if(Array.isArray(x[dispensaryType.toLowerCase()])){
            if(x[dispensaryType.toLowerCase()].length < 7){
                if(x[dispensaryType.toLowerCase()].includes(dispensaryId)){
                    return false
                } else {
                    return false
                }
            } else {
                return true
            }
        }
     }

    const findOwned = (x) => {
        
        if(!x[dispensaryType.toLowerCase()]){
            return false
        } else if(!Array.isArray(x[dispensaryType.toLowerCase()])){
            if(x[dispensaryType.toLowerCase()].length >5){
                if(x[dispensaryType.toLowerCase()] === dispensaryId){
                   
                    return true
                } else {
                    
                    return false
                }
            } else {
                return false
            }
        } else if(Array.isArray(x[dispensaryType.toLowerCase()])){
            if(x[dispensaryType.toLowerCase()].length < 7){
                if(x[dispensaryType.toLowerCase()].includes(dispensaryId)){
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        }
    }
    
    const getSquares = async () => {
            if(parseFloat(map.getZoom())<9){
                alert('You must zoom in more to be able to scan')
            } else {

            
            try {
                setGettingSquares(true)
                let _bounds = map.getBounds()
                let data = await sponsor_subareaService.getAll(`?bbox[]=${_bounds._sw.lng}&bbox[]=${_bounds._sw.lat}&bbox[]=${_bounds._ne.lng}&bbox[]=${_bounds._ne.lat}`)
                if(addedFree){
                       
                    map.removeLayer('free_layer');
                    map.removeSource('free');
                }

                if(addedTaken){
                 
                    map.removeLayer('taken_layer');
                    map.removeSource('taken');
                }

                if(addedOwned){
                  
                    map.removeLayer('owned_layer');
                    map.removeSource('owned');
                }
                if(data && data.data && data.data.totalData && data.data.totalData.length > 0){

                    
                    

                    if(data.data.totalData.filter(x => findFree(x)).length > 0){

                        map.addSource('free', {
                            type: 'geojson',
                            data: {
                                "type": "FeatureCollection",
                                "features": data.data.totalData.filter(x => findFree(x)).map((x)=>{
                                    return ({
                                        "type": "Feature",
                                        "properties": {id:x._id},
                                        "geometry": {...x.area},
                                        "id": x._id
                                    })
                                })
                            }
                        });
        
                        map.addLayer({
                            'id': 'free_layer',
                            'type': 'fill',
                            'source': 'free',
                            'paint': {
                                'fill-color': 'rgba(0, 255, 127, 0.4)',
                                'fill-outline-color': 'rgba(0, 100, 0, 1)'
                            }
                        });



                        
                         
                            setAddedFree(true)
                        } else {
                            setAddedFree(false)
                        }
                        if(data.data.totalData.filter(x => findTaken(x)).length>0){
                        map.addSource('taken', {
                            type: 'geojson',
                            data: {
                                "type": "FeatureCollection",
                                "features": data.data.totalData.filter(x => findTaken(x)).map((x)=>{
                                    return ({
                                        "type": "Feature",
                                        "properties": {id:x._id},
                                        "geometry": {...x.area},
                                        "id": x._id
                                    })
                                })
                            }
                        });
        
                        map.addLayer({
                            'id': 'taken_layer',
                            'type': 'fill',
                            'source': 'taken',
                            'paint': {
                                'fill-color': 'rgba(240, 128, 128, 0.4)',
                                'fill-outline-color': 'rgba(240, 128, 128, 1)'
                            }
                        });

                       
                            setAddedTaken(true)
                        } else {
                            setAddedTaken(false)
                        }

                    
                        if(data.data.totalData.filter(x => findOwned(x))){
                    map.addSource('owned', {
                        type: 'geojson',
                        data: {
                            "type": "FeatureCollection",
                            "features": data.data.totalData.filter(x => findOwned(x)).map((x)=>{
                                return ({
                                    "type": "Feature",
                                    "properties": {id:x._id},
                                    "geometry": {...x.area},
                                    "id": x._id
                                })
                            })
                        }
                    });
   
                    map.addLayer({
                        'id': 'owned_layer',
                        'type': 'fill',
                        'source': 'owned',
                        'paint': {
                            'fill-color': 'rgba(135, 206, 250, 0.4)',
                            'fill-outline-color': 'rgba(30, 144, 255, 1)'
                        }
                    });

                  
                        setAddedOwned(true)
                    } else {
                        setAddedOwned(false)
                    }

                } else {
                    setAddedOwned(false)
                    setAddedTaken(false)
                    setAddedFree(false)
                }


               
                setGettingSquares(false)
            } catch (er) {
                console.log(er)
            }
      
        }
    }

    

    const showInteractiveMap = (data) => {
      
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
        const initializeMap = ({ setMap, mapContainer }) => {
            var map = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
                center: _selectedLocation.center,
                zoom: zoom,
            });

            new mapboxgl.Marker()
            .setLngLat(_selectedLocation.center)
            .addTo(map);

            map.on("load", () => {
                setMap(map);
                map.resize();
            });

            map.on('click', function(e) {
                var features = map.queryRenderedFeatures(e.point, { layers: ['free_layer'] });
                if (!features.length) {
                    return;
                }
                if (typeof map.getLayer('selectedRoad') !== "undefined" ){         
                    map.removeLayer('selectedRoad')
                    map.removeSource('selectedRoad');   
                }
                var feature = features[0];
                //I think you could add the vector tile feature to the map, but I'm more familiar with JSON
            
                map.addSource('selectedRoad', {
                    "type":"geojson",
                    "data": feature.toJSON()
                });
                map.addLayer({
                    "id": "selectedRoad",
                    "type": "line",
                    "source": "selectedRoad",
                    "layout": {
                        "line-join": "round",
                        "line-cap": "round"
                    },
                    "paint": {
                        "line-color": "yellow",
                        "line-width": 8
                    }
                });
                if(feature && feature.properties && feature.properties.id){
                    setSelectedArea(feature.properties.id)
                    setDelete_id(null)
                }

            });

            map.on('click', function(e) {
                var features = map.queryRenderedFeatures(e.point, { layers: ['owned_layer'] });
                if (!features.length) {
                    return;
                }
                if (typeof map.getLayer('selectedRoad') !== "undefined" ){         
                    map.removeLayer('selectedRoad')
                    map.removeSource('selectedRoad');   
                }
                var feature = features[0];
                //I think you could add the vector tile feature to the map, but I'm more familiar with JSON
            
                map.addSource('selectedRoad', {
                    "type":"geojson",
                    "data": feature.toJSON()
                });
                map.addLayer({
                    "id": "selectedRoad",
                    "type": "line",
                    "source": "selectedRoad",
                    "layout": {
                        "line-join": "round",
                        "line-cap": "round"
                    },
                    "paint": {
                        "line-color": "yellow",
                        "line-width": 8
                    }
                });
                if(feature && feature.properties && feature.properties.id){
                 
                    setDelete_id(feature.properties.id)
                    setSelectedArea(null)
                }

            });


        };
        initializeMap({ setMap, mapContainer });
    }

    useEffect(() => {
        console.log('wwwwwwwwww')
        if(!_.isEmpty(_selectedLocation)){
            //getSquares()
            console.log(_selectedLocation)
            showInteractiveMap()
        }
    }, [_selectedLocation])

    const isFirstRun = useRef(true);


    useEffect(() => {
        if (!map) return; // initialize map only once
        if (isFirstRun.current) {
            console.log(area)
            isFirstRun.current = false;
            getSquares()
            return;
        }
    });

    

    // useEffect(() => {
 
    //     showInteractiveMap(longitude, latitude)
        
    // }, [longitude, latitude])

    // useEffect(() => {
    //     if(!_.isEmpty(location)){
    //         setLongitude(location.coordinates[0])
    //         setLatitude(location.coordinates[1])
    //         showInteractiveMap(location.coordinates[0], location.coordinates[1])
    //     }
    // }, [location])

    // useEffect(() => {
    //     if(data && data.data && data.data.area && !_.isEmpty(data.data.area)){
    //         var geometry = data.data.area;
    //         setGeometry(geometry)
    //     } else {
    //         setGeometry(turf.bboxPolygon([-171.791110603, 18.91619, -66.96466, 71.3577635769]))
    //         setZoom(3)
    //     }
    // }, [data])

    // useEffect(() => {
       
    //             showInteractiveMap()
      
    // }, [_selectedLocation])


   

    // useEffect(() => {
    //     if(!instMode){

    //             showInteractiveMap()
            
        
    //     }
    // }, [instMode])

    return (
        <>
            <div
                className='overlay overlay-block cursor-default modal-body'
            >
               
            
            <div ref={el => (mapContainer.current = el)} style={{...styles}} className={`${instMode ? 'd-none' : ''}`} id='map-container' /> 
                               
                {
                    zoom < 11 && <button 
                    className='btn btn-dark'
                    style={{
                        position: 'absolute',
                        top: '2rem',
                        right: '50%',
                        transform: 'translateX(50%)',
                    }}
                    disabled={gettingSquares}
                    onClick={()=>getSquares()}
                >
                    { gettingSquares ? 'Loading...' : 'Scan area'}
                  
                </button>
                }
                    <br />
                    {selectedArea && <h4><strong>Selected area:</strong>&nbsp;{selectedArea ? selectedArea : ''}</h4>}
            
                {
                    user && user.type === 'ADMIN' && !hideButtons && <div className='d-flex justify-content-end'>
                    {
                        (delete_id) && <button type="button" disabled={!delete_id || actionsLoading===true} onClick={()=>{
                            
                                let long = map.getCenter().lng.toFixed(4)
                                let lat = map.getCenter().lat.toFixed(4)
                                let zoom = map.getZoom().toFixed(2)
                           
                            deleteZone({
                                id: delete_id,
                                long,
                                lat,
                                zoom
                            })

                        }} class="btn btn-danger btn-elevate">REMOVE ZONE</button>
                    }
                    {
                        (selectedArea) && <button type="submit" disabled={!selectedArea || actionsLoading===true} onClick={()=>{
                            let long = map.getCenter().lng.toFixed(4)
                                let lat = map.getCenter().lat.toFixed(4)
                                let zoom = map.getZoom().toFixed(2)
                           
                            saveItem({
                                id: selectedArea,
                                long,
                                lat,
                                zoom
                            })
                            
                        }} class="btn btn-primary btn-elevate">ADD ZONE</button>
                    }
                    
                </div>
                }
                
           
            </div>
         
        </>
    )
}



