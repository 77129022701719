const getPrivateApi = process.env.REACT_APP_API_URL
const getPublicApi = process.env.REACT_APP_PUBLIC_API_URL

const dealTypes = [
    { value: 'STANDARD', label: 'Standard' },
	{ value: '$OFF', label: '$ Off' },
	{ value: '%OFF', label: '% Off' },

    
    { value: 'BXGX', label: 'Buy & Get Extra' },
    { value: 'BUNDLE', label: 'Bundle Deals' }
]

export { getPrivateApi, getPublicApi, dealTypes };
