let widget = null;

function init(croppingAspectRatio, mandatoryCropping, image_preset) {
	if (widget) return;

	widget = window.cloudinary.createUploadWidget({
		cloudName: 'timj111',
		cropping: true,
		multiple: false,
		showSkipCropButton: !mandatoryCropping,
		croppingAspectRatio: !croppingAspectRatio,
		uploadPreset: image_preset
	},
		(error, result) => {
			if (!error && result && result.event === "success") {
				if (typeof widget.d == "function") {
					widget.d(result.info.secure_url);
				}
			} else {
				if (typeof widget.reject == "function") {
					widget.reject(error);
				}
			}
		});
}

export function openUploadBox(done, croppingAspectRatio = 1, mandatoryCropping=false, image_preset, sources) {
	console.log(croppingAspectRatio)
	init(croppingAspectRatio, mandatoryCropping, image_preset);
	widget.d = done;
	let showSkipCropButton = mandatoryCropping === false ? true : false
	widget.update({
		showSkipCropButton,
		croppingAspectRatio: croppingAspectRatio,
		uploadPreset: image_preset,
		sources: sources ? sources : ['local', 'url', 'camera', 'facebook', 'instagram']
	});
	widget.open();
}