/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";

export function DoctorAsideMenuList({ layoutProps }) {

  const location = useLocation();
  
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/businesses/:business_id/reviews", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/businesses/:business_id/reviews">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Chat1.svg")}/>
            </span>
              <span className="menu-text">Reviews</span>
            </NavLink>
          </li>
      
          <li
              className={`menu-item ${getMenuItemActive("/businesses/:business_id/subscription", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/businesses/:business_id/subscription">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Credit-card.svg")}/>
            </span>
              <span className="menu-text">Subscription</span>
            </NavLink>
          </li>
        </ul>
      </>
  );
}
