/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { AreaOfCoverageUpdateDialog } from './AreaOfCoverageUpdateDialog'
import { dispensaryService } from '../../../../app/services'
import { AddPinpointToMapModal } from './AddPinpointToMapModal'
import Swal from 'sweetalert2'
import SVG from "react-inlinesvg";
import { Dropdown, Tab, Tabs, Card } from "react-bootstrap";
import { DropdownCustomToggler, DropdownMenu4 } from "../../dropdowns";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHistory } from "react-router";
import MapGL, {
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl
} from 'react-map-gl';
import * as turf from "@turf/turf";
import mapboxgl from "mapbox-gl";
import _ from 'lodash'
// import ControlPanel from './control-panel';
import Pins from './pins';

export function AreaOfCoverageWidget({ 
  className, 
  iconColor, 
  dispensary, 
  location,
  mapURL,
  coverage,
  address,
  address_line_2,
  addresszip,
  city,
  locations,
  state,
  type,
  isDeliveryServiceAvailable,
  fetchDispensary,
  deliveryMinPurchase,
  deliveryTax,
  businessType,
  dispensaryId = 'undefined'
}){

  const showMapModalInitialState = false
  const [showMapModal, setShowMapModal] = useState(showMapModalInitialState)
  const [showAddDeliveryPoint, setShowAddDeliveryPoint] = useState(showMapModalInitialState)
  const  [ editingPoint, setEditingPoint ] = useState(false)

  const [ actionsLoading, setActionsLoading ] = useState(false)

  const [ tab, setTab ] = useState('coverage')

  const editableWidgedInitialState = false
  const [editableWidged, setEditableWidged] = useState(editableWidgedInitialState)

  const [ deliveryValues, setDeliveryValues ] = useState({
    deliveryTax:null,
    deliveryMinPurchase:null
  })

  useEffect(() => {
    setDeliveryValues({
      deliveryTax,
      deliveryMinPurchase
    })
  }, [deliveryTax, deliveryMinPurchase])

  let handleState = (e) => {
    let { name, value } = e.target
    setDeliveryValues({...deliveryValues, [name]:value})
  }

  const deletePoint = async (fields, n) => {
    Swal.fire({
      title: 'Do you want to delete this marker?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return dispensaryService.update(dispensaryId, fields).then(data => {
          fetchDispensary()
          setActionsLoading(false)
          setShowAddDeliveryPoint(false)
      })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Deleted'
        })
      }
    })
 
  }

  const history = useHistory()

  const saveItem = (fields) => {
    console.log(fields)
    setActionsLoading(true)
    dispensaryService.update(dispensaryId, fields).then(data => {
        fetchDispensary()
        setActionsLoading(false)
    })
    .catch(error => {
      setActionsLoading(false)
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: error.response.data.message || error.response.data.errors[0]['message'].replace(`\"deliveryTax\"`, 'delivery fee'),
			})
      setDeliveryValues({
        deliveryTax,
        deliveryMinPurchase
      })
    })
}

  return (
    <>
      
      <Card>

      
        
                            <Card.Header>
                            <div className="card-title mb-3 pb-0">
            <div className="card-label">
                <h4 className="font-weight-bolder">Delivery Settings</h4>
              
            </div>
            {
              coverage && coverage.coordinates && coverage.coordinates.length > 0 && <button className="btn brn-text btn-link mr-0 pr-0 ml-0 pl-0" onClick={()=>setShowMapModal(true)}>Update area of coverage</button>
            }
            
          </div>
                                <div className='d-flex justify-content-between' style={{position:'relative'}}>
                                    <Tabs 

                                        defaultActiveKey="rewards" 
                                        id="uncontrolled-tab-example"
                                        activeKey={tab}
                                        onSelect={key => {
                                            setTab(key)
                                            // history.push('#'+key)
                                        }}
                                    >
                                        <Tab eventKey="coverage" title="Coverage" href="coverage"></Tab>
                                        <Tab eventKey="pins" title="Pins" href="pins"></Tab>
                                    </Tabs>
                                    
                                </div>
                            </Card.Header>
        { 
          showMapModal && (
            <AreaOfCoverageUpdateDialog  
            dispensaryId={dispensaryId}

              show={showMapModal} 
              type={type}
              onHide={()=>{setShowMapModal(false)}} 
              coverage={coverage}
              address={address}
              address_line_2={address_line_2}
              addresszip={addresszip}
              city={city}
              state={state}
              location={location}
              fetchDispensary={fetchDispensary}
              businessType={businessType}
            />
          )
        }
        { 
          showAddDeliveryPoint && (
            <AddPinpointToMapModal  
            dispensaryId={dispensaryId}
              show={showAddDeliveryPoint} 
              editingPoint={editingPoint}
              type={type}
              onHide={()=>{setShowAddDeliveryPoint(false)}} 
              coverage={coverage}
              address={address}
              address_line_2={address_line_2}
              addresszip={addresszip}
              city={city}
              state={state}
              location={location}
              fetchDispensary={fetchDispensary}
              businessType={businessType}
              locations={locations}
              deletePoint = {(x)=>deletePoint({locations: locations.filter(y => y._id != x._id)})}
            />
          )
        }
       
        <div className="card-body d-flex flex-column align-items-center px-0">

        <div className='row w-100'>
          <div className='col'>
          {

            tab === 'coverage' && (
              (!mapURL) ? (
                <div style={{paddingLeft:"2.25rem", paddingRight:"2.25rem"}} className='d-flex align-items-center flex-column justify-content-center'>
                  <h4>Area of coverage not found</h4>
                  {/* <br></br> */}
                  <p className='font-weight-bold text-muted'><center>Please set the area of coverage in order to offer delivery service in a specific area</center></p>
                  <button className='btn btn-primary' onClick={()=>setShowMapModal(true)}>
                    Set area
                  </button>
                </div>
              ) : (
                <img src={mapURL} style={{width:"100%", height:"100%"}} />
              )
            )
          }
          {
            tab === 'pins' && (
              (!locations || !locations.length > 0) ? (
                <div style={{paddingLeft:"2.25rem", paddingRight:"2.25rem"}} className='d-flex align-items-center flex-column justify-content-center'>
                  <h4>You haven't set any pins yet</h4>
                  {/* <br></br> */}
                  {
                    coverage && coverage.coordinates && coverage.coordinates.length > 0 ? (
                      <button className='btn btn-primary' onClick={()=>setShowAddDeliveryPoint(true)}><center>Set my first pin</center></button>
                
                    )  : (
                      <span className='font-weight-bold text-muted'>Add area of coverage first</span>
                    )
                  }
                  </div>
              ) : (
                <div className='d-dlex flex-column w-100'>
          {/* {
                  locations && locations.map((x, i) =>
                    <div className="d-flex align-items-center bg-light-info rounded pl-3 pr-3 mb-2 ml-1 mr-1">
                      <span className="svg-icon svg-icon-warning svg-icon-md">
                        <span className='text-bolder text-success' style={{fontSize:'14px'}}><strong>{i+1}</strong></span>
                      </span>
      
                      <div className="d-flex flex-column flex-grow-1 mr-2">
                        
                  
                      </div>
      
                      <span className="font-weight-bolder text-warning py-1 font-size-lg">
                        <div className="card-toolbar">
                          <Dropdown 
                            className="dropdown-inline" 
                            alignRight
                          >
                            <Dropdown.Toggle
                              id="dropdown-toggle-top"
                              as={DropdownCustomToggler}
                            >
                              <i className="ki ki-bold-more-ver" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                              <DropdownMenu4 opts={
                                [
                                  { label:'Edit', icon:'edit', action: async()=>{
                                    await setEditingPoint(x)
                                    setShowAddDeliveryPoint(true)
                                  }},
                                
                                ]
                              }/>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </span>
                    </div>
                  )
                } */}
                <button className='btn btn-primary mb-3 ml-auto' onClick={()=>setShowAddDeliveryPoint(true)}>Add a marker</button><br/>
                <MarkersMap locations={locations} coverage={coverage} setEditingPoint={setEditingPoint} setShowAddDeliveryPoint={setShowAddDeliveryPoint} />
                  </div>
                  
              )
            )
          }

</div>
          </div>   <hr />
        {
          
            <div style={{paddingLeft:"2.25rem", paddingRight:"2.25rem"}} className='d-inline-flex flex-wrap w-100'>
            

              
              <div className='w-100'>
              
               
                        
                   
             
                <div class={` align-items-center ${(
                            deliveryTax != deliveryValues.deliveryTax || deliveryMinPurchase != deliveryValues.deliveryMinPurchase) 
                              ? 'd-none' 
                              : 'd-flex'
                            }`}>
									
                  <h4 className='d-flex align-items-center'>
                    <span class="switch switch-icon">
									  	  <label>
                            <input 
                              type="checkbox" 
                              checked={isDeliveryServiceAvailable} 
                              name="select" 
                              id="isDeliveryServiceAvailable" 
                              onClick={
                                ()=>saveItem({isDeliveryServiceAvailable:!isDeliveryServiceAvailable})
                              }
                            />
									  	  	  <span></span>
									  	  </label>
									  </span>
                    Online Ordering for Delivery
                  </h4>
								</div>
                {
                  <div class="form-group row">
                      <div className={`col-md-6 col-sm-12`}>
                        <label htmlFor='deliveryTax'>Delivery fee:</label>
                        <input 
                          type='number' 
                          id='deliveryTax' 
                          min="0" 
                          max="200"
                          name='deliveryTax' 
                          className='form-control' 
                          value={deliveryValues.deliveryTax} 
                          onChange={(e)=>handleState(e)}
                        />
                        {/* <button type='button' className='btn btn-success'>Save</button> */}
                        {/* <div className='btn-group'>
                          <button 
                            className='btn btn-success'
                            onClick={
                              ()=> !((deliveryTax - 1) < 0) && saveItem({deliveryTax: ((deliveryTax - 1) < 1) ? 0 : deliveryTax-1})
                            }
                          >
                            -
                          </button>
                          <button disabled={true} className='btn btn-success'>${deliveryTax}</button>
                          <button 
                            className='btn btn-success'
                            onClick={
                              ()=>saveItem({deliveryTax: deliveryTax +1})
                            }
                          >
                            +
                          </button>
                        </div> */}
                      </div>
                      <hr />
                      <div className='col-md-6 col-sm-12'>
                        <label htmlFor='deliveryTax'>Minimum purchase:</label>
                        <input 
                          type='number' 
                          min="0" 
                          max="10000"
                          id='deliveryMinPurchase' 
                          name='deliveryMinPurchase' 
                          className='form-control' 
                          value={deliveryValues.deliveryMinPurchase} 
                          onChange={(e)=>handleState(e)}
                        />
                        {/* <div className='btn-group'>
                          <button 
                            className='btn btn-success'
                            onClick={
                              ()=> !deliveryMinPurchase -10 < 0 && saveItem({deliveryMinPurchase: ((deliveryMinPurchase -10) < 1) ? 0 : deliveryMinPurchase-10})
                            }
                          >
                            -10
                          </button>
                          <button 
                            className='btn btn-success'
                            onClick={
                              ()=> !deliveryMinPurchase -1 < 0 && saveItem({deliveryMinPurchase: ((deliveryMinPurchase -1) < 1) ? 0 : deliveryMinPurchase-1})
                            }
                          >
                            -1
                          </button>
                          <button disabled={true} className='btn btn-success'>${deliveryMinPurchase}</button>
                          <button 
                            className='btn btn-success'
                            onClick={
                              ()=>saveItem({deliveryMinPurchase: deliveryMinPurchase +1})
                            }
                          >
                            +1
                          </button>
                          <button 
                            className='btn btn-success'
                            onClick={
                              ()=>saveItem({deliveryMinPurchase: deliveryMinPurchase +10})
                            }
                          >
                            +10
                          </button>
                        </div> */}
                      </div>
                      
                      
                      
                      <div className='col-12 d-flex justify-content-end pt-4'>
                       
                        <button 
                          type='button' 
                          disabled={actionsLoading}
                          className={`btn btn-success ${(
                            deliveryTax != deliveryValues.deliveryTax || deliveryMinPurchase != deliveryValues.deliveryMinPurchase) 
                              ? '' 
                              : 'd-none'
                            }`
                          }
                          onClick={()=>saveItem(deliveryValues)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                }
              </div>
            </div>
          
        }
        </div>
      </Card>
    </>
  );
}






const TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN; 



const styles = {
  width: "100%",
  position: "relative",
  height:"500px"
};


function MarkersMap({coverage, locations, setEditingPoint, setShowAddDeliveryPoint}) {
  
  const mapInitialState=null
  const [map, setMap] = useState(mapInitialState);
  const mapContainer = useRef(null);
  const showInteractiveMap = (newLong, newLat) => {

    var geoJson = {
      type: 'FeatureCollection',
      features: locations.map(x => ({
        type: 'Feature',
        geometry: x
      }))
    }
       
    var centroid = turf.centroid(coverage);
    var bbox = turf.bbox({
        "type": "FeatureCollection",
        "features": [
          {
            "type": "Feature",
            "properties": {},
            "geometry": coverage
          }
        ]
    });
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const initializeMap = ({ setMap, mapContainer }) => {

     
        var map = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
            center: centroid.geometry.coordinates,
            zoom: 5
        });
        map.fitBounds(bbox, {padding: 20});
        locations.map(x => {
          const marker = new mapboxgl.Marker({
            color: "#6c3478",
            draggable: false,
         
            }).setLngLat(x.coordinates)
            .addTo(map);

            marker.getElement().addEventListener('click', async () => {
              await setEditingPoint(x)
              setShowAddDeliveryPoint(true)
            
            });
        })
        
        

       
        map.on("load", () => {
          
           map.addSource('coverage', {
               'type': 'geojson',
               'data': geoJson
               
               });

               map.on('click', 'coverage', (e) => {
                console.log(e.features[0].properties)
              });
                 
            //     // Add a new layer to visualize the polygon.
            //     map.addLayer({
            //     'id': 'coverage',
            //     'type': 'fill',
            //     'source': 'coverage', // reference the data source
            //     'layout': {},
            //     'paint': {
            //     'fill-color': '#0080ff', // blue color fill
            //     'fill-opacity': 0.1
            //     }
            //     });
            //     // Add a black outline around the polygon.
            //     map.addLayer({
            //     'id': 'outline',
            //     'type': 'line',
            //     'source': 'coverage',
            //     'layout': {},
            //     'paint': {
            //     'line-color': '#0080ff',
            //     'line-width': 3,
            //     'line-opacity': 0.2
            //     }
            //     });

               
    
    
            setMap(map);
            map.resize();
        });
    };
    initializeMap({ setMap, mapContainer });
}


  useEffect(() => {
    showInteractiveMap()
  }, [locations])

  return (
    <>
      <div ref={el => (mapContainer.current = el)} style={styles} id='map-container2' />

      {/* <ControlPanel /> */}
    </>
  );
}
