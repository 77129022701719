/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler, DropdownMenu4 } from "../../dropdowns";
import { toAbsoluteUrl } from "../../../_helpers";
import { LicensesEditDialog } from './LicensesEditDialog'
import Swal from 'sweetalert2'

import { dispensaryService } from '../../../../app/services'

export function LicensesWidget({licenses, className, fetchDispensary, fetched, dispensaryId=undefined}) {

  const showEditModalInitialState = false
  const [showEditModal, setShowEditModal] = useState(showEditModalInitialState)
  const [ scopedItem, setScopedItem ] = useState(null)

  const editLicense = (item) => {
    setScopedItem(item)
    setShowEditModal(true)
  }

  const deleteLicense = (item) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.queue([{
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: () => { 
        
        return dispensaryService.update(dispensaryId, {licenses:licenses.filter(x=>x._id!=item._id)}).then(data => {
          Swal.fire(
            'Done!',
            'The item has been deleted!',
            'success'
          )
          fetchDispensary()
        })
        .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message || error.response.data.errors[0]['message'],
            })
        })
      } 
    }])
  }

  return (
    <>
      <div className={`card card-custom ${className}`}>
        { showEditModal && <LicensesEditDialog dispensaryId={dispensaryId} show={showEditModal} onHide={()=>{setShowEditModal(false)}} fetchDispensary={fetchDispensary} scopedItem={scopedItem} licenses={licenses} /> }
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">Licenses</h3>

          {
            (fetched &&  (!licenses || licenses.length>0)) && <div className="card-toolbar">
              <button
                onClick={()=>{setShowEditModal('true');setScopedItem(null)}}
                className="btn btn-success font-weight-bolder font-size-sm"
              >
                Add
              </button>
            </div>
          }
          
        </div>

        {/* Body */}
        <div className="card-body pt-0">
          {
            (fetched &&  (!licenses || !licenses.length>0)) ? (
              <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                  flexDirection: 'column',
              }}>
                <h4 className='font-size-h4'>Please add a license</h4>
                <button onClick={()=>{setShowEditModal('true');setScopedItem(null)}} className='btn btn-info'>Add license</button>
              </div>
            ) : (
                licenses.map(x=>
                  <div className="d-flex align-items-center bg-light-info rounded p-5 mb-4">
                    <span className="svg-icon svg-icon-warning mr-5 svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")}
                      ></SVG>
                    </span>
    
                    <div className="d-flex flex-column flex-grow-1 mr-2">
                      <a
                        href="#"
                        className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                      >
                        {x.license}
                      </a>
                      <span className="text-muted font-weight-bold">{x.licenseType}</span>
                    </div>
    
                    <span className="font-weight-bolder text-warning py-1 font-size-lg">
                      <div className="card-toolbar">
                        <Dropdown className="dropdown-inline" alignRight>
                          <Dropdown.Toggle
                            id="dropdown-toggle-top"
                            as={DropdownCustomToggler}
                          >
                            <i className="ki ki-bold-more-ver" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                            <DropdownMenu4 opts={
                              [
                                { label:'Edit', icon:'edit', action: ()=>{editLicense(x)} },
                                { label:'Delete', icon:'trash', action: ()=>{deleteLicense(x)}}
                              ]
                            }/>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </span>
                  </div>
                )
            )
          }
        </div>
      </div>
    </>
  );
}
