import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { accountService } from '../../../../app/services'
import Swal from 'sweetalert2'
import { shallowEqual, useSelector } from "react-redux";
import {ModalProgressBar} from "../../../../_metronic/_partials/controls";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect, useDispatch } from 'react-redux'
import * as auth from "../../../../app/modules/Auth/_redux/authRedux";

function UserNotesUpdateDialog(props) {

    const dispatch = useDispatch()

    const actionsLoadingInitialState = false
    const [ actionsLoading, setActionsLoading ] = useState(actionsLoadingInitialState)

    const EditSchema = Yup.object().shape({
        notes: Yup.string()
              .max(5000, 'Too long')
    });
      
    

    const onSubmit = (fields) => {
        setActionsLoading(true)
        accountService.updateOwn(fields).then(data => {
            // resetForm({});
            Swal.fire(
              'Done!',
              'The item has been saved!',
              'success'
            )
            dispatch(props.requestUser);
            setActionsLoading(false)
            props.onHide()
        })
        .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message || error.response.data.errors[0]['message'],
            })
            setActionsLoading(false)
        })
    }

    return (
        <Modal
          size="lg"
          show={props.show}
          onHide={props.onHide}
          aria-labelledby="example-modal-sizes-title-lg"
        >
            <>
              {actionsLoading && <ModalProgressBar />}
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Update Notes</Modal.Title>
              </Modal.Header>
                <div
                    className='overlay overlay-block cursor-default modal-body'
                >
                    <Formik
                      enableReinitialize={true}
                      initialValues={{notes: props.notes}}
                      validationSchema={EditSchema}
                      onSubmit={onSubmit}
                    >
                         {({ errors, touched, setFieldValue, handleSubmit, isSubmitting, setFieldTouched, values, handleChange, handleReset }) => (
                            <Form className="form form-label-right">
                                <div className="form-group row">
                                    <div className='col'>
                                        <label>Notes</label>
                                        <Field
                                          name="notes"
                                          as="textarea"
                                          
                                          className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <button
                                      type="button"
                                    
                                      className='btn btn-light'
                                      onClick={()=>handleReset()}
                                    >Reset</button>
                                    <button
                                      type="submit"
                                      className='btn btn-primary'
                                    >Save</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </>
        </Modal>
    )
    
}

export default connect(null, auth.actions)(UserNotesUpdateDialog);