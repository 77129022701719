import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPrivateApi+"notes";


export const noteService = {
    getById,
    create,
    update,
    findOne
};

function getById(id) {return axios.get(`${baseUrl}/${id}`);}

function create(params) {return axios.post(baseUrl, params);}

function update(id, params) {return axios.put(`${baseUrl}/${id}`, params)}

function findOne(value, params='key=_id') {return axios.get(`${baseUrl}/${value}?${params}`);}
