import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { dispensaryService } from '../../../../app/services'
import Swal from 'sweetalert2'
import EditForm from './EditForm'


export function DispensaryInfoDialog({ 
    dispensary,
    user,
    dispensaryId, 
    show, 
    onHide
}) {

    const actionsLoadingInitialState = false
    const [ actionsLoading, setActionsLoading ] = useState(actionsLoadingInitialState)

    return (
        <Modal
          size="lg"
          show={show}
          enforceFocus={false}
          onHide={onHide}
          aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header>
                <Modal.Title>Update Business</Modal.Title>
            </Modal.Header>
            {console.log(dispensaryId)}
            <EditForm
                actionsLoading={actionsLoading}
                setActionsLoading={setActionsLoading}
                dispensary={dispensary}
                user={user}
                dispensaryId={dispensaryId}
                onHide={onHide}
            />   
        </Modal>
    )
    
}
