import React, { useState, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { mapboxService } from '../../../../app/services';
import Swal from 'sweetalert2'
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import CircleMode from 'mapbox-gl-draw-circle/lib/modes/CircleMode';
import { DirectMode, SimpleSelectMode } from 'mapbox-gl-draw-circle';
import DragCircleMode from 'mapbox-gl-draw-circle/lib/modes/DragCircleMode';
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import _, { debounce } from 'lodash'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { PlacesList } from './PlacesList'
import * as turf from "@turf/turf";

export const AddPinpointToMap = ({
    type,
    coverage,
    actionsLoading,
    setActionsLoading,
    saveItem,
    onHide,
    address,
    address_line_2,
    addresszip,
    city,
    state,
    location,
    locations,
    businessType,
    editingPoint,
    deletePoint
}) => {

    const [ selectedPlace, setSelectedPlace ] = useState({})
    const mapContainer = useRef(null);

    const [ _locations, _setLocations ] = useState([])

    //Initial states
    const mapInitialState=null
    const longitudeInitialValue=null; 
    const latitudeInitialValue=null;

    //States
    const [map, setMap] = useState(mapInitialState);
    const [latitude, setLatitude] = useState(latitudeInitialValue)
	const [longitude, setLongitude] = useState(longitudeInitialValue)
	const [geometry, setGeometry] = useState(null);


    const styles = {
        width: "100%",
        position: "relative",
        height:"500px"
    };
    
    

    const showInteractiveMap = (newLong, newLat) => {
       
        var centroid = turf.centroid(coverage);
        var bbox = turf.bbox({
            "type": "FeatureCollection",
            "features": [
              {
                "type": "Feature",
                "properties": {},
                "geometry": coverage
              }
            ]
        });
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
        const initializeMap = ({ setMap, mapContainer }) => {
			let _longitude = newLong || longitude
			let _latitude = newLat || latitude
         
            var map = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
                center: centroid.geometry.coordinates,
                zoom: type === 'POLYGON' ? 11 : 17
            });
            map.fitBounds(bbox, {padding: 20});
			let draw = null;
			
			var marker = new mapboxgl.Marker({
				draggable: true
			})

			map.on('click', (e)=>addMarker(e.lngLat.wrap().lng, e.lngLat.wrap().lat));
            marker.on('dragend', (e)=>addMarker(marker.getLngLat().lng, marker.getLngLat().lat));
            
           
            
            
			
            function addMarker(lng, lat){
      
                var isInside1 = turf.inside(
                    {
                        "type": "Feature",
                        "properties": {},
                        "geometry": {
                            "type": "Point",
                            "coordinates": [lng, lat]
                        }
                    }, {
                        "type": "Feature",
                        "properties": {},
                        "geometry": coverage
                    }
                );
                if(isInside1){
                    marker.setLngLat([lng, lat]).addTo(map)
                    var lngLat = marker.getLngLat();
                    setLatitude(lngLat.lat);
                    setLongitude(lngLat.lng);
                } else {
                    alert('Point must be inside the coverage area')
                }
            }

            map.on("load", () => {
                map.addSource('coverage', {
                    'type': 'geojson',
                    'data': {
                    'type': 'Feature',
                    'geometry': {
                    'type': 'Polygon',
                    // These coordinates outline Maine.
                    'coordinates': coverage.coordinates
                    
                    }
                    }
                    });
                     
                    // Add a new layer to visualize the polygon.
                    map.addLayer({
                    'id': 'coverage',
                    'type': 'fill',
                    'source': 'coverage', // reference the data source
                    'layout': {},
                    'paint': {
                    'fill-color': '#0080ff', // blue color fill
                    'fill-opacity': 0.1
                    }
                    });
                    // Add a black outline around the polygon.
                    map.addLayer({
                    'id': 'outline',
                    'type': 'line',
                    'source': 'coverage',
                    'layout': {},
                    'paint': {
                    'line-color': '#0080ff',
                    'line-width': 3,
                    'line-opacity': 0.2
                    }
                    });

                    if(editingPoint){
                        addMarker(editingPoint.coordinates[0], editingPoint.coordinates[1])
                    }
				
				
                setMap(map);
                map.resize();
            });
        };
        initializeMap({ setMap, mapContainer });
    }

    useEffect(() => {
        if(!_.isEmpty(selectedPlace)){
            setLongitude(selectedPlace.center[0])
            setLatitude(selectedPlace.center[1])
            showInteractiveMap(selectedPlace.center[0], selectedPlace.center[1])
        }
    }, [selectedPlace])

    // useEffect(() => {
    //     if(!_.isEmpty(location)){
    //         setLongitude(location.coordinates[0])
    //         setLatitude(location.coordinates[1])
    //         showInteractiveMap(location.coordinates[0], location.coordinates[1])
    //     }
    // }, [location])

    useEffect(() => {
        if(locations && locations.length > 0){
            _setLocations(locations)
        }
    }, [locations])
 
    useEffect(() => {
        if(!_.isEmpty(coverage)){
            setGeometry(coverage)
        }
    }, [coverage])

    useEffect(() => {
        const el = document.getElementById('map-container')
        if(!_.isEmpty(coverage) && !el.childNodes.length>0){
            if(!_.isEmpty(geometry)){
                showInteractiveMap()
            }
        }
    }, [geometry])

    const updateDeliveryPoints = () => {
        console.log({longitude, latitude})
        let newlocations = locations
        if(editingPoint){
            newlocations = locations.map( x => {
                if(x._id === editingPoint._id){
                    x = Object.assign({}, x , {coordinates:[longitude, latitude], type:'Point'})
                    console.log(x)
                }
                
                return x
            } )
        } else {
            newlocations = locations ? locations.concat({coordinates:[longitude, latitude], type:'Point'}) : [{coordinates:[longitude, latitude], type:'Point'}]
        }

        console.log(newlocations)
        
        saveItem({locations:newlocations})

    }

    return (
        <>
            <div
                className='overlay overlay-block cursor-default modal-body'
            >
           
                {
                    ((_.isEmpty(location) && _.isEmpty(coverage) && _.isEmpty(selectedPlace))) ? (
                        <PlacesList 
                            actionsLoading={actionsLoading}
                            setActionsLoading={setActionsLoading}
                            setSelectedPlace={setSelectedPlace}
                            address={address}
                            address_line_2={address_line_2}
                            addresszip={addresszip}
                            city={city}
                            state={state}
                        />
                    ) : (
                        <div className="form-group row">
                            
                            <div className={`col d-flex flex-column`}>
                                <h4>{selectedPlace.place_name}</h4>
                               
                                <div ref={el => (mapContainer.current = el)} style={styles} id='map-container' />
                            </div>
                        </div>
                    )
                }
            </div>
            <div class="modal-footer">
                {
                    !_.isEmpty(editingPoint) && <button type="button" onClick={()=>deletePoint(editingPoint)} class="btn btn-danger btn-elevate">Delete</button> 
                }
                <button type="button" onClick={()=>onHide()} class="btn btn-light btn-elevate">Cancel</button> 
                {
                    <button type="submit" disabled={ !longitude || !latitude || actionsLoading===true} onClick={()=>updateDeliveryPoints()} class="btn btn-primary btn-elevate">Save</button>
                }
            </div>
        </>
    )
}

