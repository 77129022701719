import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPrivateApi+"mails";


export const mailService = {
    getAll,
    getById,
    create,
    update,
};




function getAll(query) {
    console.log(query)
    var fetchParam=""
    if(!query){fetchParam=`${baseUrl}`}
    else{fetchParam=`${baseUrl}${query}`}
    return axios.get(fetchParam);
}

function getById(id) {
    return axios.get(`${baseUrl}/${id}`);
}

function create(params) {
    return axios.post(baseUrl, params);
}

function update(id, params) {
    return axios.put(`${baseUrl}/${id}`, params)
}

