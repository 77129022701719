import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { EditForm } from "./LicensesEditForm";
import { EditDialogHeader } from './LicensesEditDialogHeader'
import { dispensaryService } from '../../../../app/services'
import Swal from 'sweetalert2'

export function LicensesEditDialog({ show, onHide, fetchDispensary, licenses, scopedItem, dispensaryId }) {

    const actionsLoadingInitialState = false
    const [ actionsLoading, setActionsLoading ] = useState(actionsLoadingInitialState)

    const initItem = {
        license: (scopedItem) ? scopedItem.license : '',
	      licenseType: (scopedItem) ? scopedItem.licenseType : '',
    }

    const saveItem = (fields, { setStatus, setSubmitting, resetForm }) => {
        let updatedLicenses = licenses
        if(scopedItem){
          updatedLicenses = licenses.filter(x=>x._id!=scopedItem._id)
        } 
        updatedLicenses.push(fields)
        console.log(updatedLicenses)
        setActionsLoading(true)
        dispensaryService.update(dispensaryId, {licenses: updatedLicenses}).then(data => {
            resetForm({});
            Swal.fire(
              'Done!',
              'The item has been saved!',
              'success'
            )
            fetchDispensary()
            setActionsLoading(false)
            onHide()
        })
        .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message || error.response.data.errors[0]['message'],
            })
            setActionsLoading(false)
        })
      }

   
    return (
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <EditDialogHeader 
          item={initItem} 
          actionsLoading={actionsLoading} 
        />
        <EditForm
          saveCustomer={saveItem}
          actionsLoading={actionsLoading}
          initialValues={initItem}
          onHide={onHide}
          setActionsLoading={setActionsLoading}
        />  
      </Modal>
    )
    
}
