import React from 'react'
import { EditDialog } from './EditDialog'
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { packageService } from '../../../../../app/services'
import * as Yup from "yup";
import {
  Input,
  Select,
  DatePickerField,
} from "../../../../../_metronic/_partials/controls";
import Swal from "sweetalert2";


export const EditForm = ({   
        saveCustomer,
        actionsLoading,
        initialValues,
        onHide,
        setActionsLoading,
    }) => {

    const validationSchema = Yup.object().shape({
        opens_at: Yup.string()
            .required('Opens hour is required'),
        closes_at: Yup.string()
            .required('Closes hour is required'),

    });
        
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema}  onSubmit={saveCustomer}>
        {({ errors, touched, setFieldValue, values, handleChange,  isSubmitting, handleReset }) => (
        <>
        <Form className="form form-label-right">
          <Modal.Body className="overlay overlay-block cursor-default">
            {actionsLoading && (
              <div style={{zIndex:"5"}} className="overlay-layer bg-transparent">
                <div className="spinner spinner-lg spinner-success" />
              </div>
            )}
            {console.log(initialValues)}
            <div className="form-group row">
                <div className="cl col-lg-4">
                    <Field
                      type="time"
                      name="opens_at"
                      component={Input}
                      placeholder="Opens At"
                      label="Opens At"
                    />


                </div>
                
                <div className="col col-lg-4">
                    <Field
                      type="time"
                      name="closes_at"
                      component={Input}
                      placeholder="Closes At"
                      label="Closes At"
                    />
                </div>
                
                <div className="col col-lg-4">
                    <Select name="isEnabled" label="status">
                        <option value={false}>Disabled</option> 
                        <option value={true}>Enabled</option> 
                    </Select>
                </div>
            </div>
                
 
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="submit"
              disabled={actionsLoading}
              className="btn btn-primary btn-elevate"
            >Save</button>
          </Modal.Footer>
        </Form>
      </>
        )}
        </Formik>
    )


}

