import axios from "axios";
const apiUrl = `https://api.mapbox.com`
const getMapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN


export const mapboxService = {
    reverseGeocoding,
    getStaticMap,
    geocoding
};

function reverseGeocoding(longitude, latitude, types) {
    return axios.get(`${apiUrl}/geocoding/v5/mapbox.places/${longitude},${latitude}.json?${types ? types : ''}access_token=${getMapboxAccessToken}`);
}

function geocoding(value='', types=['place', 'locality', 'address', 'poi', 'postcode']) {
    let _value = value.replace(/_/g, '#')
    return axios.get(`${apiUrl}/geocoding/v5/mapbox.places/${_value.replaceAll('#', '')}.json?country=US&types=${encodeURIComponent(types)}&access_token=${getMapboxAccessToken}`);
}

function getStaticMap(_longitude, _latitude, zoom=16, style='streets-v11', moreParams='') {
    return `https://api.mapbox.com/styles/v1/mapbox/${style}/static/${_longitude},${_latitude},${zoom},0/400x210@2x?access_token=${getMapboxAccessToken}&${moreParams}`;
}