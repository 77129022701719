import React, {Suspense, lazy} from "react";
import { useSelector } from "react-redux";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {DashboardPage} from "./pages/DashboardPage";
import { accountService } from "./services";

const BusinessPage = lazy(() =>
  import("./modules/ADMIN/Business/pages/BusinessPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ADMIN/ECommerce/pages/eCommercePage")
);
const FinancePage = lazy(() =>
  import("./modules/ADMIN/Finance/pages/FinancePage")
);
const SystemPage = lazy(() =>
  import("./modules/ADMIN/System/pages/SystemPage")
);
const AccountPage = lazy(() =>
  import("./modules/Account/pages/AccountPage")
);
const SettingsPage = lazy(() =>
  import("./modules/Setting/pages/SettingsPage")
);

const AddEntityPage = lazy(() =>
  import("./modules/ENTITY/pages/AddEntityPage")
);
const DispensaryPage = lazy(() =>
  import("./modules/DISPENSARY/pages/DispensaryPage")
);

const BusinessesPage = lazy(() =>
  import("./modules/DISPENSARY/pages/BusinessesList")
);
const BrandPage = lazy(() =>
  import("./modules/BRAND/pages/BrandPage")
);
const PublisherPage = lazy(() =>
  import("./modules/Publisher/pages/PublisherPage")
);

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    const user = useSelector(state => state.auth.user)
   
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
          {console.log()}
            <Switch>
              
                {
                  (user.type === 'DISPENSARY' || user.type === 'DELIVERY' || user.type === 'DOCTOR') ? <Redirect exact from="/" to="/businesses/"/> : user.type === 'BRAND' ? <Redirect exact from="/" to="/brands"/> : 
                  (user.type === 'PUBLISHER') ? <Redirect exact from="/" to="/publisher/"/> :
                  <Redirect exact from="/" to="/admin_panel/"/>
                    
                }
                {/* <ContentRoute path="/dashboard" component={DashboardPage}/> */}
                <Route path="/admin/e-commerce" component={ECommercePage}/>
                <Route path="/admin/business" componment={BusinessPage}/>
                <Route path="/admin/finance" component={FinancePage}/>
                <Route path="/admin/system" component={SystemPage}/>
                <Route path="/account" component={AccountPage}/>
                <Route path="/settings" component={SettingsPage}/>
                {/* <Route path="/dispensary" component={DispensaryPage}/> */}
                <Route path="/businesses/:business_id" component={DispensaryPage}/>
                <Route path="/businesses" component={BusinessesPage}/>
                <Route path="/brands" component={BrandPage}/>
				        <Route path="/publisher" component={PublisherPage}/> 
                {/* <Route path='/entity' component={AddEntityPage}/> */}
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
