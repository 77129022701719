/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { orderService, productService } from '../../../../app/services'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import Spinner from '../../spinner/Spinner'
import { Link } from 'react-router-dom'
import moment from "moment";

export function BestProducts({ className, brand }) {
  const tabs = {
    tab1: "kt_tab_pane_3_1",
    tab2: "kt_tab_pane_3_2",
    tab3: "kt_tab_pane_3_3",
  };

  const [activeTab, setActiveTab] = useState(tabs.tab1);

  const [ items, setItems ] = useState([])

  const [ loading, setLoading ] = useState(false)

  const queries = {
    kt_tab_pane_3_1: `?filterfield[]=brand&filtervalue[]=${brand}&filtertype[]=eq&sortField=avgRating&sortOrder=desc&size=3&filterfield[]=avgRating&filtertype[]=gt&filtervalue[]=0&filterfield[]=reviewsAmt&filtertype[]=gt&filtervalue[]=0`,
    kt_tab_pane_3_3: `?filterfield[]=brand&filtervalue[]=${brand}&filtertype[]=eq&sortField=reviewsAmt&sortOrder=desc&size=3&filterfield[]=avgRating&filtertype[]=gt&filtervalue[]=0&filterfield[]=reviewsAmt&filtertype[]=gt&filtervalue[]=0`
  }

  const fetch = async (query) => {
    try {

      let { 
        data: {
          totalData
        } 
      } = await productService.getAll(query)

      console.log(totalData)
      setItems(totalData)
      setLoading(false)

    } catch(err) {

      console.log(err)

    }
  }

  useEffect(() => {
    setLoading(true)
    fetch(queries[activeTab])
  }, [activeTab])

  let textNoWrap = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block'
}

  return (
    <>
      {/* begin::Base Table Widget 6 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Best Products
            </span>
            {/* <span className="text-muted mt-3 font-weight-bold font-size-sm">
              Completed within the last 24 hours
            </span> */}
          </h3>
          <div className="card-toolbar">
            <ul className="nav nav-pills nav-pills-sm nav-dark-75">
              <li className="nav-item">
                <a
                  className={`nav-link py-2 px-4 ${activeTab === tabs.tab1 &&
                    "active"}`}
                  data-toggle="tab"
                  href={`#${tabs.tab1}`}
                  onClick={() => setActiveTab(tabs.tab1)}
                >
                  Higher Rating
                </a>
              </li>
              {/* <li className="nav-item">
                <a
                  className={`nav-link py-2 px-4 ${activeTab === tabs.tab2 &&
                    "active"}`}
                  data-toggle="tab"
                  href={`#${tabs.tab2}`}
                  onClick={() => setActiveTab(tabs.tab2)}
                >
                  In process
                </a>
              </li> */}
              <li className="nav-item">
                <a
                  className={`nav-link py-2 px-4 ${activeTab === tabs.tab3 &&
                    "active"}`}
                  data-toggle="tab"
                  href={`#${tabs.tab3}`}
                  onClick={() => setActiveTab(tabs.tab3)}
                >
                  Most Reviewed
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-2 pb-0">
          {/* begin::Table */}
          {
            loading ? (
              <div className='mt-5 mb-5 pb-5 pt-5'>
                <Spinner />
              </div>
            ) : (
              items.length < 1 ? (
                <div className='d-flex h-100 align-items-center justify-content-center pt-4 pb-4'>
                  <div className='card-spacer'>
                    <center><h4>No products reviewed.</h4></center>
                  </div>
                </div>
              ) : (
                <div className="table-responsive">
                  <table className="table table-borderless table-vertical-center">
                    <thead>
                      <tr>
                        <th className="p-0" style={{ width: "50px" }} />
                        <th className="p-0" style={{ minWidth: "150px" }} />
                     
                        <th className="p-0" style={{ minWidth: "70px" }} />
                        <th className="p-0" style={{ minWidth: "50px" }} />
                      </tr>
                    </thead>
                    <tbody>
                    {
                        items && items.map(item => 
                          <tr className='rounded pl-2 pr-2' style={{borderRadius:'0.42rem'}}>
                            <td className="">
                              <div className="symbol symbol-50 symbol-light mr-2 mt-2">
                                <span className="symbol-label">
                                  {
                                    item.picture && item.picture.length > 0 && <img className='w-100 h-100' src={item.picture[0]}></img>
                                  }
                             
                                </span>
                              </div>
                            </td>
                            <td className="pl-0">
                              <Link to={`/brands/${brand}/products/${item._id}/details`}>
                              <a
                                href="#"
                                className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                               
                              >
                                {/* { 
                                  item.user ? item.user.nickname : item.name 
                                } */}
                                {
                                  item.name
                                }
                              </a>
                              </Link>
                              <span className="text-muted font-weight-bold d-block">
                                {
                                  item.category ? item.category.name : ''
                                }
                                {/* {
                                  item.user ? item.user.email : item.email
                                } */}
                              </span>
                            </td>
                       
                            <td className="text-right">
                              <span className="text-muted font-weight-bold d-block font-size-sm">
                                {item.reviewsAmt} Review{item.reviewsAmt > 1 ? 's' : ''}
                              </span>
                              {console.log(item)}
                              <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                <i className='fa fa-star'></i> {item.avgRating.toFixed(1)}
                              </span>
                            </td>
                            <td className="text-right">
                              <Link to={`/brands/${brand}/products/${item._id}/details`} className="btn btn-icon btn-light btn-sm">
                                <span className="svg-icon svg-icon-md svg-icon-success">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Navigation/Arrow-right.svg"
                                    )}
                                  ></SVG>
                                </span>
                              </Link>
                            </td>
                          </tr>
                        )
                      }
                     

                    </tbody>
                  </table>
                </div>
              )
            )
          }
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Base Table Widget 6 */}
    </>
  );
}
