import React, { useState, useEffect } from "react";
import {ListsWidget10, MixedWidget6, UserNotesWidget, MixedWidget10, MixedWidget11, MixedWidget12, TilesWidget1, TilesWidget3, TilesWidget10, TilesWidget11, TilesWidget12, TilesWidget13, TilesWidget14, MyLocationWidget, DispensaryProductsCountTile, DispensaryFulfilledOrdersCountTile } from "../widgets";
import { HoursOfOperationTable } from '../widgets/advance-tables/HoursOfOperationTable'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { accountService, dispensaryService } from '../../../app/services'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import { getPrivateApi } from "../../../app/helpers/config";
import { getUserByToken } from "../../../app/modules/Auth/_redux/authCrud";

export function PublisherDashboard({getDashboardInfo}) {

    // const user = useSelector(state => state.auth.user)

    const dispensaryInitialState = {}
    const [ dispensary, setDispensary ] = useState({dispensaryInitialState})

    const fetchedInitialState = false
    const [ fetched, setFetched ] = useState(fetchedInitialState)

    const [ user, setUserInfo ] = useState({})
    
    
    async function fetchPublisher() {
        setFetched(false)
        await getUserByToken()
            .then((res) => {
                setUserInfo(res.data)
                setFetched(true)
            })
            .catch((error) => {
                
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data.message || error.response.data.errors[0].message,
                })
            })
    }

    useEffect(() => {
        fetchPublisher()
    }, [])

    const updateName = () => {

        try {

            Swal.fire({
                title: 'Submit your Name',
                input: 'text',
                inputValue: user.name,
                inputAttributes: {
                  autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Update',
                showLoaderOnConfirm: true,
                preConfirm: (name) => {
                  return accountService.updateOwn({name})
                    .then(response => {
                      if (response.status != 200) {
                        throw new Error(response.statusText)
                      }
                      return response
                    })
                    .catch(error => {
                      Swal.showValidationMessage(
                        `Request failed: ${error}`
                      )
                    })
                },
                allowOutsideClick: () => !Swal.isLoading()
              }).then((result) => {
                  console.log(result)
                if (result.isConfirmed) {
                  Swal.fire('Done',
                  'Info updated',
                  'success')
                  fetchPublisher()
                }
              })

        } catch (er) {
            console.log(er)
        }

    }

    const updateNickame = () => {

        try {

            Swal.fire({
                title: 'Submit your Nickname',
                input: 'text',
                inputValue: user.nickname,
                inputAttributes: {
                  autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Update',
                showLoaderOnConfirm: true,
                preConfirm: (nickname) => {
                  return accountService.updateOwn({nickname})
                    .then(response => {
                      if (response.status != 200) {
                        throw new Error(response.statusText)
                      }
                      return response
                    })
                    .catch(error => {
                      Swal.showValidationMessage(
                        `Request failed: ${error}`
                      )
                    })
                },
                allowOutsideClick: () => !Swal.isLoading()
              }).then((result) => {
                  console.log(result)
                if (result.isConfirmed) {
                  Swal.fire('Done',
                  'Info updated',
                  'success')
                  fetchPublisher()
                }
              })

        } catch (er) {
            console.log(er)
        }

    }
    
    const handleImageUpload = (e) => {
        setFetched(false)
        const { name, files } = e.target
        const formData = new FormData();

        formData.append('file', files[0]);
        formData.append('upload_preset', 'spj28hqq');
        const options = {
            method: 'POST',
            body: formData,
        };

        return fetch('https://api.Cloudinary.com/v1_1/timj111/image/upload', options)
            .then(res => res.json())
            .then( res =>{
                accountService.updateOwn({'picture':res.secure_url})
            }).then(()=>fetchPublisher())
    }

    const updateBio = () => {

        try {

            Swal.fire({
                title: 'Submit your Bio',
                input: 'textarea',
                inputValue: user.bio,
                inputAttributes: {
                  autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Update',
                showLoaderOnConfirm: true,
                preConfirm: (bio) => {
                  return accountService.updateOwn({bio})
                    .then(response => {
                      if (response.status != 200) {
                        throw new Error(response.statusText)
                      }
                      return response
                    })
                    .catch(error => {
                      Swal.showValidationMessage(
                        `Request failed: ${error}`
                      )
                    })
                },
                allowOutsideClick: () => !Swal.isLoading()
              }).then((result) => {
                  console.log(result)
                if (result.isConfirmed) {
                  Swal.fire('Done',
                  'Info updated',
                  'success')
                  fetchPublisher()
                }
              })

        } catch (er) {
            console.log(er)
        }

    }

    //LOADING SPINNER
    if(!fetched) return <div style={{zIndex:"5"}} className="overlay-layer bg-transparent" style={{width: "100%", zIndex: "5", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}><div className="spinner spinner-lg spinner-success" /></div>
    
    return <>
        <div className="row">
            <div className="col-12">
                <div
                  className={`card card-custom`}
              
                >
                    {console.log(user)}
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                        <img src={user.picture} style={{borderRadius:'100%', width:'48px', height:'48px'}}></img>
                        <h2 className="ml-4 mb-0 pb-0">{user.name}</h2>
                    </div>
                    <div
                      className={`mt-3`}
                    >
                      <p>{user.bio}</p>
                    </div>
                    <div className={`mt-2`}>
                        <span><strong>Live link:</strong> https://weedzly.com/author/{user.nickname}</span>
                    </div> <br/>
                    <div>
                        <button onClick={()=>updateName()} className="btn btn-sm btn-primary mr-2">Update Name</button>
                        <button onClick={()=>updateBio()} className="btn btn-sm btn-primary mr-2">Update Bio</button>
                        <button onClick={()=>updateNickame()} className="btn btn-sm btn-primary mr-2">Update Nickname</button>
                    </div>
                    <hr/>
                    <div>
                        <span><strong>Update profile picture</strong></span> <br /><br />
                        <input onChange={(e)=>handleImageUpload(e)} type="file" />
                    </div>
                  </div>
                </div>
            </div>
        </div>

    </>;
}
