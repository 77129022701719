import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";
import queryString from 'query-string'
const baseUrl = getPrivateApi+"products";
const publicBaseUrl = getPublicApi+"products";

export const productService = {
    getAll,
    getById,
    create,
    createOnMenu,
    update,
    updateOnMenu,
    getMyMenu,
    getMenu,
    getFromMenuById,
    delete: _delete,
    deleteFromMenu: deleteFromMenu,
	updateIsFeature,
    updateRetailer,
    removeFromMenu
};

function getFromMenuById(business_id, id){
    return axios.get(`${baseUrl}/menu/${business_id}/${id}`);
}

function getAll(query) {
    return axios.get(`${baseUrl}${query}`);
}

function getMyMenu(business_id, query='') {
    return axios.get(`${baseUrl}/menu/${business_id}${query}`);
}

function getMenu(id, query) {
    var fetchParam=""
    if(!query){fetchParam=`${baseUrl}`}
    else{fetchParam=`${baseUrl}${query}`}
    return axios.get(`${baseUrl}/menu/${id}${query}`);
}

function getById(id) {
    return axios.get(`${baseUrl}/${id}`);
}

function create(params) {
    return axios.post(baseUrl, params);
}


function createOnMenu(business_id, params) {
    return axios.post(baseUrl+'/menu/'+business_id, params);
}

function update(id, params, queryParams='') {
    return axios.put(`${baseUrl}/${id}${queryParams}`, params)
}
function updateOnMenu(business_id, id, params, queryParams='') {
    return axios.put(`${baseUrl}/${business_id}/${id}${queryParams}`, params)
}

function removeFromMenu(business_id, id) {
    return axios.delete(`${baseUrl}/menu/${business_id}/${id}`)
}

function updateIsFeature(id, params) {
    return axios.put(`${baseUrl}/${id}/featured`, params)
}

function _delete(id) {
    return axios.delete(`${baseUrl}/${id}`)
}

function deleteFromMenu(business_id, id) {
    return axios.delete(`${baseUrl}/menu/${business_id}/${id}`)
}

function updateRetailer(business_id, id, payload) {
    return axios.put(`${baseUrl}/menu/${business_id}/${id}`, payload)
}
