import React from "react";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import Select from 'react-select'

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  withClassColour=true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  return (
    <>
    
      {label && <label>{!props.disabled && 'Enter'} {label}</label>}
      <input
        type={type}
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        {...field}
        {...props}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}

export function Selecter({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  value,
  label,
  options,
  setFieldValue,
  withFeedbackLabel = true,
  withClassColour=true,
  customFeedbackLabel,
  isMulti=false,
  type = "text",
  ...props
}) {
  return (
    <>
      {label && <label>{!props.disabled && 'Enter'} {label}</label>}
      <Select
  		    isMulti={isMulti}
  		    name={field.name}
			    value={value}
  		    options={options}
			    onChange={selectedOption => setFieldValue(field.name, selectedOption)}
          className={getFieldCSSClasses(touched[field.name], errors[field.name]).replace('form-control', '')}
  		/>
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
