import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { orderService, claimService } from '../../../../app/services'
import Swal from 'sweetalert2'
// import QrReader from 'react-qr-reader'
import _ from 'lodash'
import { toAbsoluteUrl } from "../../../_helpers/AssetsHelpers";
import moment from 'moment'
import {CircularProgress} from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom'

export function QrCodeScannerModal({ show, onHide, fetchDispensary, licenses, scopedItem }) {

    const actionsLoadingInitialState = false
    const [ actionsLoading, setActionsLoading ] = useState(actionsLoadingInitialState)

    const history = useHistory()

    const [ qrData, setQrData ] = useState({})
    const [ response, setResponse ] = useState({})

    const handleScan = data => {
      if (data) {
        setQrData(JSON.parse(data))
      }
    }

    const handleError = err => {
      console.error(err)
    }

    const fetchData = async (qrData) => {
      try {

        if(qrData.type==='claim'){
          setActionsLoading(true)
          let claim = await claimService.getById(qrData._id)
          setResponse(claim.data)
          setActionsLoading(false)
        } else if (qrData.type==='order'){
          console.log('object')
        }
        
      } catch(err) {
        setActionsLoading(false)
        console.log(err)
      }
    }

    useEffect(() => {
      if(!_.isEmpty(qrData)){
        fetchData(qrData)
      }
    }, [qrData])
 
    return (
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className='pt-3'>
          {
            actionsLoading ? (
              <div className='p- m- d-flex flex-column align-items-center justify-content-center'>
                <center><h2>Fetching data...</h2><h3>Please wait</h3></center><br />
                <CircularProgress className="splash-screen-spinner" />
              </div>
            ) : !_.isEmpty(response) ? (
              <div className='pt-4 pb-4'>
                <div className='d-flex align-items-center flex-column'> 
                  <center><h2>QR codified information</h2></center><br />
                  <img src={toAbsoluteUrl("/media/default-user.png")} width='150' height='150'></img>
                  <h3>{response.user.name}</h3><br/>
                  <p>
                    <center>
                      <span>{response.user.email}</span>
                      <br/><span>{response.user.phone}</span>
                      <br/><span>{response.user.nickname}</span>
                      {
                        response.user.address_line_1 && <><br/><span>{response.user.address_line_1}</span></>
                      }
                      {
                        response.user.address_line_2 && <><br/><span>{response.user.address_line_2}</span></>
                      }
                      {
                        response.user.address_line_3 && <><br/><span>{response.user.address_line_3}</span></>
                      }
                    </center>
                  </p>
                </div>
                <hr></hr>
                <div className='d-flex align-items-center flex-column'> 
                  <small className='text-muted'>This QR code applies for</small>
                  <h3>{response.deal.name}</h3>
                  <button className='btn btn-text btn-link' onClick={
                    ()=>{
                      history.push(`/businesses/:business_id/deals/${response.deal._id}/details`)
                      onHide()
                    }
                  }>See the deal info</button>
                  <br />
                  <p>
                    <center>
                      <span>This claim was done the {moment(response.createdAt).format('MMMM Do YYYY')} at {moment(response.createdAt).format('HH:mm:ss')}</span><br/>
                      <strong><span>Expiration date {moment(response.deal.expiration).format('MMMM Do YYYY')} at {moment(response.deal.expiration).format('HH:mm:ss')}</span></strong>
                    </center>
                  </p>
                  <div className='d-flex'>
                    <button 
                      className='btn btn-primary mr-2'
                      onClick={
                        ()=>setResponse({})
                      }
                    >Scan another QR code</button>
                    <button 
                      className='btn btn-danger'
                      onClick={
                        onHide
                      }
                    >
                      Close
                    </button>
                  </div>
                </div>
              </ div>
            ) : (
              <div>
                <center><h2>Please place the Qr code in the camera viewport</h2></center>
                <div className='p-4 d-flex align-items-center justify-content-center'>
                  {/* <QrReader
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: '90%' }}
                  /> */}
                </div>
                <p>{qrData.result}</p>
              </div>
            )
          }
        </div>
      </Modal>
    )
    
}
