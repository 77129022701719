/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { NewBusinessModal } from "../widgets/maps/NewBusinessModal";

export function DropdownMenu1({businesses, selectedBusiness, user}) {

    const [ showBusinessModal, setShowBusinessModal ] = useState(false)

    return (<>
        {/*begin::Navigation*/}
        <ul className="navi navi-hover">
            <NewBusinessModal 
              show={showBusinessModal} 
              onHide={()=>{setShowBusinessModal(false)}} 
            />
            <li className="navi-header font-weight-bold py-5">
                <span className="font-size-lg">Choose:</span>
            </li>
            <li className="navi-separator mb-3 opacity-70"></li>
            {
                businesses.map(x => <>
                    <li className="navi-item" style={selectedBusiness && selectedBusiness._id === x._id ? {backgroundColor:'#e0e7ff'} : {}}>
                        <Link to={"/businesses/"+x._id} className="navi-link">
                    <span className="navi-text">
                        <span className="navi-item cursor-pointer hover-primary hoverable d-flex align-items-center">
                            {
                                x.type === 'DELIVERY' ? <i className="fa fa-truck text-primary icon-md mr-2"></i> : <i className="fa fa-store text-primary icon-md mr-2"></i>
                            }
                            {
                                (x.address && x.city && x.city.name && x.city.state.name) ? (
                                    <div className='d-flex ml-1 flex-column'>
                                        <span>{x.address}</span>
                                        <span>{x.city.name}, ({x.city.state.name})</span>
                                    </div>
                                ) : (
                                    <div className='d-flex ml-1 flex-column'>
                                        <span>{x.address}</span>
                                        <span>Location Needed</span>
                                    </div>
                                )
                                
                            
                            }
                        </span>
                    </span>
                        </Link>
                    </li>
                </>)
            }
            <li className="navi-separator mt-3 opacity-70"></li>
            {
                user.type!='BRAND' && <li className="navi-footer pt-5 pb-4">
                    <a className="btn btn-clean font-weight-bold btn-sm" href="#" onClick={()=>setShowBusinessModal(true)}>
                        <i className="ki ki-plus icon-sm"></i>
                        Add new
                    </a>
                </li>
            }
        </ul>
        {/*end::Navigation*/}
    </>);
}
