import React, { useState, useRef, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useSelector } from 'react-redux'
import * as Yup from 'yup';
import "mapbox-gl/dist/mapbox-gl.css";
import MaskedInput from 'react-text-mask'

import {Input} from '../../../../_metronic/_partials/controls'
import Swal from 'sweetalert2'

import { Link, useHistory } from 'react-router-dom'
import Spinner from '../../../../_metronic/_partials/spinner/Spinner'
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { dispensaryService } from '../../../../app/services';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})


export function NewBusinessForm(props) {

    const history = useHistory()

    const initialValues = {
		phone: '',
		email: '',
		type: '',
		address: '',
        address_line_2: '',
        addresszip: '',
		city: '',
		state: '',
    };

    const validationSchema = Yup.object().shape({

        phone: Yup.string()
            .min(10, "Minimum 10 symbols")
 			.max(20, "Maximum 20 symbols")
            .required('Phone is required'),

        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),

        type: Yup.string()
            .min(5, "Minimum 5 symbols")
 			.max(20, "Maximum 20 symbols")
            .required('Business type is required'),

        address: Yup.string()
            .min(5, "Minimum 5 symbols")
            .max(100, "Maximum 100 symbols")
            .required('Address is required'),

        address_line_2: Yup.string()
            .max(100, "Maximum 100 symbols"),
          
        addresszip: Yup.string()
            .min(3, "Minimum 3 symbols")
 			.max(10, "Maximum 10 symbols")
            .required('Zip code is required'),

        city: Yup.string()
            .min(1, "Minimum 1 symbols")
 			.max(50, "Maximum 50 symbols")
            .required('City is required'),

        state: Yup.string()
            .min(1, "Minimum 1 symbols")
 			.max(50, "Maximum 30 symbols")
            .required('State is required'),

    });


    function onSubmit(fields, { setStatus, setSubmitting }) {

        setStatus();
        let payload = Object.assign({}, fields)
        
        payload.pendingLocation = {
            city: payload.city,
            state: payload.state
        }
        delete payload.city
        delete payload.state
        props.setActionsLoading(true)
        dispensaryService.create(payload)
            .then((info) => {
                Toast.fire({
                    icon: 'success',
                    title: 'New business created.'
                })
                setSubmitting(false);
                props.setActionsLoading(false)
                props.onHide()
                history.push('/businesses/'+info.data._id+'/settings')
            })
            .catch(error => {
                props.setActionsLoading(false)
                setSubmitting(false);
                Toast.fire({
                    icon: 'warning',
                    title: error.message || error.errors[0]['message'],
                })
            });
    }


	return (
		<Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting, values, handleChange, setFieldValue, setFieldTouched }) => (
                <Form className="form form-label-right">
                    {props.actionsLoading && <Spinner></Spinner>}
                    {console.log(errors)}
                    {/* <h3>Business Basic Info</h3> */}
                    <div className="form-group row">
                        <div className="col-lg-6">
                            <label>Phone number *</label>
                            <Field  name="phone"
                                
							    render={({ field, form, setFieldTouched }) => (
								    <MaskedInput
                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')} 
                                        {...field}
                                        guide={true}
                                        placeholder="(123) 456-7890"
                                        onBlur={setFieldTouched}
                                        onChange={(e) => {
                                            handleChange(e)
                                            const value = e.target.value || '';
                                            setFieldValue('phone', value);
                                        }}
                                    />
								)}
								type="text"  
							/>
                            </div>
                            
                            <div className="col-lg-6">
                            <Field
                                name="email"
                                component={Input}
                                type="email"
                                placeholder="Business Email"
                                label="Business Email *"
                            />
                        
                        </div>
                    </div>
                    <div className='form-group row'>

                    <div className='col-md-6 col-12'>
                                        <div className="form-group">
                                            <label className="form-label">Business Type</label>
                                            <Field as="select" type="text" placeholder="Enter business type" name='type' className={'form-control' + (errors.type && touched.type ? ' is-invalid' : '')} >
                                                <option value=''>Select a business type</option>
                                                <option value='DISPENSARY'>Dispensary</option>
                                                <option value='DELIVERY'>Delivery</option>
                                                {/* <option value='DOCTOR'>Doctor</option> */}
                                            </Field>
                                            <ErrorMessage name="type" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>

                    </div>
                    <hr/>
                        <div className='form-group row'>
                            <div className='col-md-6 col-12'>
                            <Field
                                name="address"
                                component={Input}
                                type="text"
                                placeholder="address"
                                label="address *"
                            /> 
                            </div>
                            <div className='col-md-6 col-12'>
                            <Field
                                name="address_line_2"
                                component={Input}
                                type="text"
                                placeholder="Address line 2"
                                label="Address line 2 *"
                            /> 
                            </div>
                        </div>     
                        <div className='form-group row'>
                            <div className='col-md-6 col-12'>
                            <Field
                                name="city"
                                component={Input}
                                type="text"
                                placeholder="city"
                                label="city *"
                            /> 
                            </div>
                            <div className='col-md-6 col-12'>
                            <Field
                                name="state"
                                component={Input}
                                type="text"
                                placeholder="state"
                                label="state *"
                            /> 
                            </div>
                        </div>      
                        <div className='form-group row'>
                            <div className='col-md-6 col-12'>
                            <Field
                                name="addresszip"
                                component={Input}
                                type="text"
                                placeholder="ZIP"
                                label="ZIP *"
                            /> 
                            </div>
                        </div>     
                    <hr/>
                    <div className="text-right">
                        <div className="d-flex" style={{justifyContent:"space-between"}}>
                            <Link to="/" className="btn btn-link">Cancel</Link>
                            <button type="submit" disabled={props.actionsLoading} className="btn btn-primary ml-aut">
                            {
                                props.actionsLoading && <span className="spinner-border spinner-border-sm mr-1"></span>
                            }
                                Save
                            </button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
	);
}
