import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPrivateApi+"menuproducts";


export const menuproductService = {
    getAll,
    getById,
    create,
    update,
    getMyList,
    getAddList,
    deleteFromDispensary,
    delete: _delete,
    getRetailers,
    getMenu,
    getCount
};


function getAll(query) {
    var fetchParam=""
    if(!query){fetchParam=`${baseUrl}`}
    else{fetchParam=`${baseUrl}${query}`}
    return axios.get(fetchParam);
}

function getCount() {
    return axios.get(`${baseUrl}/getcount`)
}

function getMyList(query) {
    var fetchParam=""
    if(!query){fetchParam=`${baseUrl}/mylist`}
    else{fetchParam=`${baseUrl}/mylist${query}`}
    return axios.get(fetchParam);
}

function getRetailers(query) {
    var fetchParam=""
    if(!query){fetchParam=`${baseUrl}/retailers`}
    else{fetchParam=`${baseUrl}/retailers/${query}`}
    return axios.get(fetchParam);
}

function getMenu(query) {
    var fetchParam=""
    if(!query){fetchParam=`${baseUrl}/getmenu`}
    else{fetchParam=`${baseUrl}/getmenu/${query}`}
    return axios.get(fetchParam);
}

function getAddList() {
    return axios.get(baseUrl+"/addlist");
}
function getById(id) {
    return axios.get(`${baseUrl}/${id}`);
}

function deleteFromDispensary(id) {
    return axios.delete(`${baseUrl}/businesses/:business_id/${id}`);
}

function getByUserId(id) {
   
    return axios.get(`${baseUrl}/user/${id}`)  .then(dispensary => {
        return dispensary;
})}

function create(params) {
    console.log(params)
    return axios.post(baseUrl, params);
}

function update(id, params) {
    return axios.put(`${baseUrl}/${id}`, params)

}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(id) {
    return axios.delete(`${baseUrl}/${id}`)
}

