import React, { useState, useEffect } from "react";
import {LicensesWidget, BaseTablesWidget1,UserNotesWidget, ListsWidget10, BestSaleWidget, LastestOrdersWidget, MixedWidget10, MixedWidget11, MixedWidget12, TilesWidget1, TilesWidget3, TilesWidget10, TilesWidget11, TilesWidget12, TilesWidget13, TilesWidget14, MyLocationWidget, DispensaryProductsCountTile, DispensaryFulfilledOrdersCountTile, StatsWidget10, IncomeChartWidget, StatsWidget12, BaseTablesWidget2, BaseTablesWidget6, RecentReviews } from "../widgets";
import { HoursOfOperationTable } from '../widgets/advance-tables/HoursOfOperationTable'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { dispensaryService, orderService } from '../../../app/services'
import { Link } from "react-router-dom";
import moment from 'moment'

const MAPBOX_ACCESS_TOKEN = process.env.MAPBOX_ACCESS_TOKEN

export function DeliveryDashboard({getDashboardInfo}) {

    const user = useSelector(state => state.auth.user)

    const dispensaryInitialState = {}
    const [ dispensary, setDispensary ] = useState({dispensaryInitialState})

    const fetchedInitialState = false
    const [ fetched, setFetched ] = useState(fetchedInitialState)

  
    const fetchDispensary = () => {

        dispensaryService.getById(user._id)
            .then((res) => {
                console.log(res.data)
                setDispensary(res.data);
                setFetched(true)
            })
            // .catch((error) => {
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'Oops...',
            //         text: error.response.data.message || error.response.data.errors[0].message,
            //     })
            // })

    }

    useEffect(() => {
        fetchDispensary();
    }, [])

    const [ range, setRange ] = useState('3D')
    const [ rangeDates, setRangeDates ] = useState({})

    useEffect(() => {
        switch (range) {
            case '3D':
                setRangeDates({
                    start: moment().subtract(3, 'days').format('YYYY-MM-DD'),
                    end: moment().format('YYYY-MM-DD')
                })
                break;
            case '1W':
                setRangeDates({
                    start: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
                    end: moment().format('YYYY-MM-DD')
                })
                break;
            case '1M':
                setRangeDates({
                    start: moment().subtract(1, 'months').format('YYYY-MM-DD'),
                    end: moment().format('YYYY-MM-DD')
                })
                break;
            case '3M':
                setRangeDates({
                    start: moment().subtract(3, 'months').format('YYYY-MM-DD'),
                    end: moment().format('YYYY-MM-DD')
                })
                break;
        
            default:
                break;
        }
    }, [range])
    
    //LOADING SPINNER
    if(!fetched) return <div style={{zIndex:"5"}} className="overlay-layer bg-transparent" style={{width: "100%", zIndex: "5", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}><div className="spinner spinner-lg spinner-success" /></div>
    
    return <>
        {/* <div className="row">
            <div className="col-xl-3">
                <DispensaryProductsCountTile className="gutter-b" baseColor="primary" widgetHeight="150px" />
            </div>
            <div className="col-xl-3">
                <DispensaryFulfilledOrdersCountTile className="gutter-b" iconColor="success" widgetHeight="150px" />
            </div>
            <div className="col-xl-3">
                <TilesWidget11 className="gutter-b" baseColor="primary" widgetHeight="150px" />
            </div>
            <div className="col-xl-3">
                <TilesWidget12 className="gutter-b" iconColor="success" widgetHeight="150px" />
            </div>
        </div> */}

        {/* begin::Row */}
        {
            dispensary.status === 'UNPUBLISHED' &&
            <div className='row'>
                <div className="col-12">
                    <div class="alert d-flex align-items-center justify-content-between alert-danger" role="alert">
                        { 
                            dispensary.status === 'UNPUBLISHED' && <>
                                Your dispensary has unpublished status, please fill all the requested information to change the status. 
                                <Link to={"/businesses/"+dispensary._id+"/settings"} to className='btn btn-light'> Go to settings</Link>
                            </>
                        }
                    </div>
                </div>

            </div>
        }
        <div className="row">
            <div className="col-xl-12">
                <IncomeChartWidget
                    className="gutter-b"
                    symbolShape="circle"
                    baseColor="info"
                    dispensarySlug={dispensary.slug}
                    rangeDates={rangeDates}
                />
            </div>
            <div className='col-xl-6'>
                <LastestOrdersWidget className="card-stretch gutter-b" />
            </div>
            <div className='col-xl-6'>
                <BestSaleWidget className="card-stretch gutter-b" />
            </div>
        </div>
        <div className="row">
            <div className='col-xl-6'>
                <UserNotesWidget className="card-stretch gutter-b" />
            </div>
            <div className='col-xl-6'>
                <RecentReviews className="card-stretch gutter-b" />
            </div>
        </div>
    </>
}
