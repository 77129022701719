/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { orderService, reviewService } from '../../../../app/services'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import Spinner from '../../spinner/Spinner'
import { Link } from 'react-router-dom'

export function RecentReviews({ className }) {




  const [ items, setItems ] = useState([])

  const [ loading, setLoading ] = useState(false)

  const fetch = async () => {
    try {

      let { 
        data: {
          totalData
        } 
      } = await reviewService.getAll('?sortField=createdAt&sortOrder=desc&size=3')

      setItems(totalData)
      setLoading(false)

    } catch(err) {

    }
  }

  useEffect(() => {
    setLoading(true)
    fetch()
  }, [])

  return (
    <>
      {/* begin::Base Table Widget 6 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Recent Reviews
            </span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">
              Read and reply
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-2 pb-0">
          {/* begin::Table */}
          {
            loading ? (
              <div className='mt-5 mb-5 pb-5 pt-5'>
                <Spinner />
              </div>
            ) : (
              items.length < 1 ? (
                <div className='d-flex h-100 align-items-center justify-content-center pt-4 pb-4'>
                  <div className='card-spacer'>
                    <center><h4>There are no recent reviews</h4></center>
                  </div>
                </div>
              ) : (
               
              
                    
                        items && items.map(item => 
                          <div className='d-flex align-items-center mb-9 bg-light-primary rounded p-5 justify-content-beetween' style={{backgroundColor: '#f3f6f9', borderRadius:'6px', justifyContent:'space-between'}}>

                            <div className="pl-3">
                              <a
                                href="#"
                                className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                              >
                                {
                                  item.user ? item.user.email : item.email
                                }
                                
                              </a>
                              <span className="text-muted font-weight-bold d-block">
                                { 
                                  item.user ? item.user.name : item.name 
                                }
                              </span>
                            </div>
                           
                            <div className="text-right d-flex">
                              <div className='mr-3'>
                                <span className="text-muted font-weight-bold d-block font-size-sm">
                                  {
                                    item.rating
                                  } Stars
                                </span>
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                  {
                                      item.title
                                  }
                                </span>
                              </div>
                              <Link to={`/businesses/:business_id/reviews/${item._id}/edit`} className="btn btn-icon btn-light btn-sm">
                                <span className="svg-icon svg-icon-md svg-icon-success">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Navigation/Arrow-right.svg"
                                    )}
                                  ></SVG>
                                </span>
                              </Link>
                            </div>
                         
                              
                          
                          </div>
                        )
                      
                     

              )
            )
          }
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Base Table Widget 6 */}
    </>
  );
}
