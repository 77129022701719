/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import Swal from "sweetalert2";
import { dispensaryService } from "../../../../../app/services";
import { useHistory } from "react-router";

export function AdminAsideMenuList({ layoutProps }) {
	const location = useLocation();
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
			: "";
	};
	const history = useHistory()

	const addBusinesses = () => {
		
			Swal.fire({
				title: 'Submit wm api urls',
				input: 'textarea',
				inputAttributes: {
				  autocapitalize: 'off'
				},
				showCancelButton: true,
				confirmButtonText: 'Look up',
				showLoaderOnConfirm: true,
				preConfirm: (urls) => {
				  return dispensaryService.addDispensariesUrls({urls: urls})
					.then(response => {
					  return response
					})
					.catch(error => {
						
					  Swal.showValidationMessage(
						`Request failed: ${error.response && error.response.data && error.response.data.message ? error.response.data.message : error}`
					  )
					})
				},
				allowOutsideClick: () => !Swal.isLoading()
			  }).then((result) => {
	
					if(result.dismiss === 'cancel' || result.dismiss === 'backdrop'){

					} else {
				
						window.location.replace(`/admin/e-commerce/${result.value.data.type.toLowerCase().replace('y', 'ies')}/${result.value.data._id}/settings`)
					}
					
				
			  })
		
	}

	return (
		<>
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				<li
					className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/dashboard">
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
						</span>
						<span className="menu-text">Dashboard</span>
					</NavLink>
				</li>

				<li
					className={`menu-item menu-item-submenu ${getMenuItemActive(
						"/admin/e-commerce", true
					)}`}
					aria-haspopup="true"
					data-menu-toggle="hover"
				>
					<NavLink className="menu-link menu-toggle" to="/admin/e-commerce">
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")} />
						</span>
						<span className="menu-text">eCommerce</span>
						<i className="menu-arrow" />
					</NavLink>
					<div className="menu-submenu">
						<i className="menu-arrow" />
						<ul className="menu-subnav">
							<li className="menu-item menu-item-parent" aria-haspopup="true">
								<span className="menu-link">
									<span className="menu-text">eCommerce</span>
								</span>
							</li>
							{/*begin::2 Level*/}

							{/*end::2 Level*/}
							{/*begin::2 Level*/}
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/products"
								)}`}
								aria-haspopup="true"

								data-menu-toggle="hover"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/products">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Products</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/parentcategories"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/addedproducts">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Added Products</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/brands"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/brands">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Brands</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/categories"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/categories">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Categories</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/parentcategories"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/parentcategories">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Parent Categories</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/strains"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/strains">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Strains</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/straincollections"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/straincollections">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Strain Collections</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/dictionary"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/dictionary">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Dictionary</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/users"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/users">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Users</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/dispensaries?filterfield[]=type&filtertype[]=eq&filtervalue[]=DISPENSARY&page=1"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/dispensaries?filterfield[]=type&filtertype[]=eq&filtervalue[]=DISPENSARY&page=1">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Dispensaries</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/deliveries?filterfield[]=type&filtertype[]=eq&filtervalue[]=DELIVERY&page=1"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/dispensaries?filterfield[]=type&filtertype[]=eq&filtervalue[]=DELIVERY&page=1">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Deliveries</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/reviews"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/reviews">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Reviews</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/new-business-requests"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/new-business-requests">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">New Business Requests</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/menu-requests"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/menu-requests">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Menu Requests</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/business-change"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/business-change">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Business Change</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/account-change"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/account-change">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Account Change</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/deals"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/deals">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Deals</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/states"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/states">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">States</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/receipts"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/receipts">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Receipts</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/sponsor"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/sponsor">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Sponsor Zones</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/subzones"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/subzones">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Sponsor Subzones</span>
								</NavLink>
							</li>
							{/*end::2 Level*/}
						</ul>
					</div>
				</li>
				{/*end::1 Level*/}




				<li
					className={`menu-item menu-item-submenu ${getMenuItemActive(
						"/admin/finance", true
					)}`}
					aria-haspopup="true"
					data-menu-toggle="hover"
				>
					<NavLink className="menu-link menu-toggle" to="/admin/finance">
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")} />
						</span>
						<span className="menu-text">Finance</span>
						<i className="menu-arrow" />
					</NavLink>
					<div className="menu-submenu">
						<i className="menu-arrow" />
						<ul className="menu-subnav">
							<li className="menu-item menu-item-parent" aria-haspopup="true">
								<span className="menu-link">
									<span className="menu-text">Finance</span>
								</span>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/finance/plans"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/finance/plans">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Plans</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/finance/subscriptions"
								)}`}
								aria-haspopup="true"
							>

								<NavLink className="menu-link" to="/admin/finance/subscriptions">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Subscriptions</span>
								</NavLink>
							</li>
						</ul>
					</div>
				</li>


				<li
					className={`menu-item menu-item-submenu ${getMenuItemActive(
						"/admin/e-commerce", true
					)}`}
					aria-haspopup="true"
					data-menu-toggle="hover"
				>
					<NavLink className="menu-link menu-toggle" to="/admin/e-commerce">
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl("/media/svg/icons/Home/Book.svg")} />
						</span>
						<span className="menu-text">Blog</span>
						<i className="menu-arrow" />
					</NavLink>
					<div className="menu-submenu">
						<i className="menu-arrow" />
						<ul className="menu-subnav">
							<li className="menu-item menu-item-parent" aria-haspopup="true">
								<span className="menu-link">
									<span className="menu-text">Blog</span>
								</span>
							</li>
							{/*begin::2 Level*/}

							{/*end::2 Level*/}
							{/*begin::2 Level*/}
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/e-commerce/articles"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/e-commerce/articles">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Articles</span>
								</NavLink>
							</li>
							
							
							{/*end::2 Level*/}
						</ul>
					</div>
				</li>

				<li
					className={`menu-item menu-item-submenu ${getMenuItemActive(
						"/admin/business", true
					)}`}
					aria-haspopup="true"
					data-menu-toggle="hover"
				>
					<NavLink className="menu-link menu-toggle" to="/admin/business">
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
						</span>
						<span className="menu-text">Business</span>
						<i className="menu-arrow" />
					</NavLink>
					<div className="menu-submenu">
						<i className="menu-arrow" />
						<ul className="menu-subnav">
							<li className="menu-item menu-item-parent" aria-haspopup="true">
								<span className="menu-link">
									<span className="menu-text">Business</span>
								</span>
							</li>
							{/*begin::2 Level*/}

							{/*end::2 Level*/}
							{/*begin::2 Level*/}
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/business/receipts"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/business/receipts">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Receipts</span>
								</NavLink>
							</li>
							{/*end::2 Level*/}
						</ul>
					</div>
				</li>

				<li
					className={`menu-item menu-item-submenu ${getMenuItemActive(
						"/admin/system", true
					)}`}
					aria-haspopup="true"
					data-menu-toggle="hover"
				>
					<NavLink className="menu-link menu-toggle" to="/admin/system">
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl("/media/svg/icons/Code/Settings4.svg")} />
						</span>
						<span className="menu-text">System</span>
						<i className="menu-arrow" />
					</NavLink>
					<div className="menu-submenu">
						<i className="menu-arrow" />
						<ul className="menu-subnav">
							<li className="menu-item menu-item-parent" aria-haspopup="true">
								<span className="menu-link">
									<span className="menu-text">System</span>
								</span>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/system/settings"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/system/settings">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Settings</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/system/activity"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/system/activity">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Activity</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive(
									"/admin/system/sessions"
								)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admin/system/sessions">
									<i className="menu-bullet menu-bullet-dot">
										<span />
									</i>
									<span className="menu-text">Sessions</span>
								</NavLink>
							</li>
						</ul>
					</div>
				</li>








			</ul>
			<div style={{padding:'15px 25px'}} >
				<button onClick={()=>addBusinesses()} className="btn btn-primary btn-block
				">Add Business</button>
			</div>
		</>
	);
}
