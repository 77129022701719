import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPrivateApi+"reviews";


export const reviewService = {
    getAll,
    getById,
    update,
    report,
    delete: _delete,
};


function getAll(business_id='null', query='') {
    return axios.get(`${baseUrl}/${business_id}/${query}`);
}

function getById(business_id='null', id) {
    return axios.get(`${baseUrl}/${business_id}/${id}`);
}


function update(business_id='null', id, params) {
    return axios.put(`${baseUrl}/${business_id}/${id}`, params)
}

function _delete(id) {
    console.log(id)
    return axios.delete(`${baseUrl}/${id}`)
}

function report(business_id='null', id, params) {
    return axios.put(`${baseUrl}/report/${business_id}/${id}`, params);
}
