/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { toAbsoluteUrl } from "../../../_helpers";
import { DropdownCustomToggler, DropdownMenu4 } from "../../dropdowns";
import { useHtmlClassService } from "../../../layout";
import { analyticsService, dispensaryService } from "../../../../app/services";
import queryString from 'query-string'
import _ from "lodash";
import Spinner from "../../spinner/Spinner";
import StarRatings from "react-star-ratings";

export function ReviewsWidget({ className, chartColor = "success", dispensarySlug, rangeDates, dispensary, business_id }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseColor: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${chartColor}`
      ),
      colorsThemeLightColor: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${chartColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, chartColor]);


  const fetchData = async () => {

    try {
      let data = await analyticsService.reviews(business_id, '?'+queryString.stringify(rangeDates))
      setAnalytics(data.data)
      console.log(data.data)
      setLoading(false)

    } catch(er) {
      console.log(er)
    }

  }


  const [ loading, setLoading ] = useState(true)
  const [ analytics, setAnalytics ] = useState(true)

  useEffect(() => {
    
    if(!_.isEmpty(rangeDates)){
      setLoading(true)
      fetchData()
    }
    
  }, [rangeDates])

  // useEffect(() => {
  //   const element = document.getElementById("kt_tiles_widget_1_chart");

  
  //   if (!element || !analytics) {
  //     return;
  //   }

  //   if(!analytics.historygram || !analytics.historygram.length > 0){
  //     return;
  //   }
  //   element.innerHTML = "";

  //   const options = getChartOption(layoutProps, analytics);
  //   const chart = new ApexCharts(element, options);
  //   chart.render();
  //   return function cleanUp() {
  //     chart.destroy();
  //   };
  // }, [analytics]);



  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card h-100 card-custom gutter-b ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">



              {console.log(analytics)}
              <div className="font-weight-bolder">Total Rating</div>
              <div className='mt-4'>
              <div style={{display:'flex', alignItems:'flex-start'}}>
                                    <StarRatings
                                        rating={dispensary.avgRating ? dispensary.avgRating : 0}
                                        starRatedColor="rgb(255, 215, 0)"
                                        starEmptyColor='#dadada'
                                        starDimension="20px"
                                        starSpacing="0px"
                                        numberOfStars={5}
                                        name='rating'
                                    />&nbsp; <span className='ml-2' style={{fontSize:'16px'}}><h1>{dispensary.avgRating.toFixed(1)||0.0}</h1></span>
                                </div>
               
                </div>
              
              
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0 pt-0">
          {/* begin::Chart */}
          {
                loading && <Spinner />
              }
         
          {/* end::Chart */}

          {/* begin::Items */}
         
          <div className="flex-grow-1 card-spacer-x">
          <div
            // id="kt_tiles_widget_1_chart"
            data-color={chartColor}
            // style={{ height: "150px" }}
          >

            {console.log(analytics)}

{/* {!loading && analytics && analytics.total && <p className="mt-2  text-info">{analytics.total} Reviews from {rangeDates.start} to {rangeDates.end}</p>} */}
          
          </div>
            <span className='text-muted mt-3 font-weight-bold font-size-sm mb-4'>{analytics.lastest && analytics.lastest.length > 0 ? 'Recent Reviews' : 'No Recent Reviews'}</span>
            {/* begin::Item */}
            
              {
                analytics.lastest && analytics.lastest.length > 0 && analytics.lastest.map(x => <div className="d-flex align-items-center justify-content-between mb-10"><div className="d-flex align-items-center mr-2 w-100" style={{overflow:'hidden'}}>
                <div className="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
                  <div className="symbol-label">
                    <span className="svg-icon">
                      <SVG
                        className=" h-50"
                        src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                      ></SVG>
                    </span>
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <a
                    href="#"
                    className="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder"
                  >
                    {x.user.name || x.author}
                  </a>
                  <div className="font-size-sm text-muted font-weight-bold mt-1" style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'inline-block'
                  }}>
                    {x.body}
                  </div>
                </div>
              </div><div className="label label-light label-inline font-weight-bold text-dark-50 py-4 px-3 font-size-base">
                {x.rating}
              </div></div>)
              }
              
         
            {/* end::Item */}

            {/* begin::Item */}
           
            {/* end::Item */}

            {/* begin::Item */}
            
            {/* end::Item */}
          </div>
          {/* end::Items */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}
    </>
  );
}

function getChartOption(layoutProps, analytics) {

  console.log(analytics)
  const options = {
    series: [
      {
        name: "Total",
        data: analytics.historygram.map(x => {
          return x.total
        }),
      },
    ],
    chart: {
      type: "area",
      height: 150,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 0.55,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0.2,
        stops: [25, 50, 100],
        colorStops: [],
      },
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [layoutProps.colorsThemeBaseColor],
    },
    xaxis: {
      categories: analytics.historygram.map(x => {
        return x.date
      }),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3,
        },
      },
      // tooltip: {
      //   enabled: true,
      //   formatter: undefined,
      //   offsetY: 0,
      //   style: {
      //     fontSize: "12px",
      //     fontFamily: layoutProps.fontFamily,
      //   },
      // },
    },
    yaxis: {
      // min: 0,
      // max: 37,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function(val) {
          return  val ;
        },
      },
    },
    colors: [layoutProps.colorsThemeLightColor],
    markers: {
      colors: [layoutProps.colorsThemeLightColor],
      strokeColor: [layoutProps.colorsThemeBaseColor],
      strokeWidth: 3,
    },
    padding: {
      top: 0,
      bottom: 0,
    },
  };
  return options;
}
