export default function setupAxios(axios, store) {
  
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();
      
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );


  axios.interceptors.response.use(function (response) {
      return response;
  }, function (error) {
      if (error.response && error.response.status && 401 === error.response.status) {
          window.location = '/logout';
          
      } else {
          return Promise.reject(error);
      }
  });
}
