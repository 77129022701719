import React, { useState, useEffect } from "react";
import {LicensesWidget, MixedWidget1, BaseTablesWidget1, SubscriptionWidget, ReviewsWidget,  ListsWidget10, BestSaleWidget, LastestOrdersWidget, RecentReviews, MixedWidget10, MixedWidget11, MixedWidget12, TilesWidget1, TilesWidget3, TilesWidget10, TilesWidget11, TilesWidget12, TilesWidget13, TilesWidget14, MyLocationWidget, DispensaryProductsCountTile, DispensaryFulfilledOrdersCountTile, StatsWidget10, IncomeChartWidget, StatsWidget12, BaseTablesWidget2, BaseTablesWidget6, UserNotesWidget } from "../widgets";
import { HoursOfOperationTable } from '../widgets/advance-tables/HoursOfOperationTable'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { dispensaryService, orderService, subscriptionService } from '../../../app/services'
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";
import moment from "moment";

const MAPBOX_ACCESS_TOKEN = process.env.MAPBOX_ACCESS_TOKEN

export function DispensaryDashboard({business_id}) {
    
    const [ loadingMonthOrders, setLoadingMonthOrder ] = useState(true)
    const [ amountThisMonth, setAmountThisMonth ] = useState(null)

    const fetchOrdersThisMonth = async () => {
        
        try {
            setLoadingMonthOrder(true)
            let data = await orderService.getAllThisMonth(business_id)
            setAmountThisMonth(data.data.amount)
            setLoadingMonthOrder(false)

        } catch (er) {

            console.log(er)

        }

    }

    const dispensaryInitialState = {}
    const [ dispensary, setDispensary ] = useState({dispensaryInitialState})

    const fetchedInitialState = false
    const [ fetched, setFetched ] = useState(fetchedInitialState)

  
    const fetchDispensary = () => {

        dispensaryService.getById(business_id)
            .then((res) => {
                console.log(res.data)
                setDispensary(res.data);
                setFetched(true)
            })
            // .catch((error) => {
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'Oops...',
            //         text: error.response.data.message || error.response.data.errors[0].message,
            //     })
            // })

    }

    useEffect(() => {
        fetchDispensary();
        fetchSubs()
        fetchOrdersThisMonth()
    }, [])

    const [ range, setRange ] = useState('3D')
    const [ rangeDates, setRangeDates ] = useState({})
    
    const [ subscription, setSubscription ] = useState(null)
    const [ loadingSubscription, setLoadingSubscription ] = useState(true)


    const fetchSubs = async () => {
        try {
            setLoadingSubscription(true)
            let subscription = await subscriptionService.getById(business_id, '?sortField=createdAt&sortOrder=desc&page=0&size=1')
            console.log('asdasdasdasdasdasdasd');
            console.log(subscription);
            if(subscription.data.totalData.length > 0){
                setSubscription(subscription.data.totalData[0])
            } else {
                setSubscription(null)
            }
            setLoadingSubscription(false)
        } catch(err) {
            console.log(err)
        }
    }




    useEffect(() => {
        switch (range) {
            case '3D':
                setRangeDates({
                    start: moment().subtract(3, 'days').format('YYYY-MM-DD'),
                    end: moment().format('YYYY-MM-DD')
                })
                break;
            case '1W':
                setRangeDates({
                    start: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
                    end: moment().format('YYYY-MM-DD')
                })
                break;
            case '1M':
                setRangeDates({
                    start: moment().subtract(1, 'months').format('YYYY-MM-DD'),
                    end: moment().format('YYYY-MM-DD')
                })
                break;
            case '3M':
                setRangeDates({
                    start: moment().subtract(3, 'months').format('YYYY-MM-DD'),
                    end: moment().format('YYYY-MM-DD')
                })
                break;
        
            default:
                break;
        }
    }, [range])


    //LOADING SPINNER
    if(!fetched) return <div style={{zIndex:"5"}} className="overlay-layer bg-transparent" style={{width: "100%", zIndex: "5", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}><div className="spinner spinner-lg spinner-success" /></div>
    
    return <>

        {console.log(dispensary.status)}
        {
            dispensary.status === 'UNPUBLISHED' &&
            <div className='row'>
                <div className="col-12">
                    <div class="alert d-flex align-items-center justify-content-between alert-danger" role="alert">
                        { 
                            dispensary.status === 'UNPUBLISHED' && <>
                            Your dispensary has unpublished status, please fill all the requested information to change the status. 
                            <Link to={"/businesses/"+dispensary._id+"/settings"} className='btn btn-light'> Go to settings</Link>
                        </>
                        }
                    </div>
                </div>

            </div>
        }
        <div 
            className="row"
            style={{    
                position: 'sticky',
                top: '50px',
                zIndex: '1'
            }}
        >
            <div className="col-xl-12" style={{marginBottom:'25px'}}>
                <div className={`card card-custom h-100`}>
                    <div className='card-body d-flex justify-content-between'>
                        <div className='d-flex'>
                            <img src={dispensary.logo} style={{height:'48px', width:'48px', borderRadius:'100%'}}></img>
                            <div className='ml-3 d-flex flex-column'>
                                <span className='text-bold' style={{fontSize:'18px', fontWeight:'600'}}>{dispensary.name}</span>
                                <small><strong>{dispensary.type}</strong></small>
                            </div>
                        </div>
                        <div>
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    <button type="button" onClick={()=>setRange('3D')} className={`btn btn-success ${range === '3D' ? 'active' : ''}`}>3D</button>
                                    <button type="button" onClick={()=>setRange('1W')} className={`btn btn-success ${range === '1W' ? 'active' : ''}`}>1W</button>
                                    <button type="button" onClick={()=>setRange('1M')} className={`btn btn-success ${range === '1M' ? 'active' : ''}`}>1M</button>
                                    <button type="button" onClick={()=>setRange('3M')} className={`btn btn-success ${range === '3M' ? 'active' : ''}`}>3M</button>
                                </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-xl-8">
                <IncomeChartWidget
                    // className="gutter-b"
                    symbolShape="circle"
                    baseColor="info"
                    dispensarySlug={dispensary.slug}
                    rangeDates={rangeDates}
                    business_id={business_id}
                    subscription={subscription}
                    loadingSubscription={loadingSubscription}
                />  
            </div>
            <div className='col-xl-4'>
                <ReviewsWidget 
                    dispensarySlug={dispensary.slug}
                    rangeDates={rangeDates}
                    dispensary={dispensary}
                    business_id={business_id}
                />
            </div>
        </div>
        <div className="row">
            <div className='col-xl-6' style={{marginTop:'50px'}}>
                <LastestOrdersWidget 
                    amountThisMonth={amountThisMonth} 
                    loadingMonthOrders={loadingMonthOrders} 
                    className="card-stretch" 
                    business_id={business_id} 
                    subscription={subscription}
                    loadingSubscription={loadingSubscription}
                />
            </div>
            <div className='col-xl-6' style={{marginTop:'50px'}}>
                <SubscriptionWidget 
                    className="card-stretch gutter-b" 
                    amountThisMonth={amountThisMonth}
                    dispensary={dispensary} 
                    business_id={business_id} 
                    subscription={subscription}
                    loadingSubscription={loadingSubscription}
                />
            </div> 
        </div>
        
    </>
}
