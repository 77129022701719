import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPublicApi+"cities";
const basePrivateUrl = getPrivateApi+"cities";

export const cityService = {
    getAll,
    getByState,
    getById,
    update
};



function getAll(query) {
    var fetchParam=""
    if(!query){fetchParam=`${baseUrl}`}
    else{fetchParam=`${baseUrl}${query}`}
    return axios.get(fetchParam);
}


function getByState(id) {

    return axios.get(`${baseUrl}/state/${id}`);
}


function getById(id) {return axios.get(`${basePrivateUrl}/${id}`);}

function update(id, params) {return axios.put(`${basePrivateUrl}/${id}`, params)}