import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPrivateApi+"strains";


export const strainService = {
    getAll,
    getById,
    create,
    update,
    getProductsByCategoryId,
    delete: _delete,
};

function getAll(query) {
    var fetchParam=""
    if(!query){fetchParam=`${baseUrl}`}
    else{fetchParam=`${baseUrl}${query}`}
    return axios.get(fetchParam);
}

function getById(id) {return axios.get(`${baseUrl}/${id}`);}

function getProductsByCategoryId(id) {return axios.get(`${baseUrl}/${id}/products/`);}

function create(params) {return axios.post(baseUrl, params);}

function update(id, params) {return axios.put(`${baseUrl}/${id}`, params)}

function _delete(id) {return axios.delete(`${baseUrl}/${id}`)}