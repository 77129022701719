import React from 'react'
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { packageService } from '../../../../app/services'
import * as Yup from "yup";
import {
  Input,
  Select,
  DatePickerField,
} from "../../../../_metronic/_partials/controls";
import Swal from "sweetalert2";


export const EditForm = ({   
        saveCustomer,
        actionsLoading,
        initialValues,
        onHide,
        setActionsLoading,
    }) => {

    const validationSchema = Yup.object().shape({
        license: Yup.string()
            .max(100, 'Too long'),
        licenseType: Yup.string()
            .required('License type is required')
            .min(1, 'Too short')
            .max(100, 'Too long'),

    });
        
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema}  onSubmit={saveCustomer}>
        {({ errors, touched, setFieldValue, values, handleChange,  isSubmitting, handleReset }) => (
        <>
        <Form className="form form-label-right">
          <Modal.Body className="overlay overlay-block cursor-default">
            {actionsLoading && (
              <div style={{zIndex:"5"}} className="overlay-layer bg-transparent">
                <div className="spinner spinner-lg spinner-success" />
              </div>
            )}

            <div className="form-group row">
                <div className="cl col-lg-4">
                  <Select name="licenseType" label="License Type">
                    <option value="">Select license type</option>
                    <option value="I-71">I-71</option>
                    {/* <option value="Recreational Cultivation">Recreational Cultivation</option>
                    <option value="Recreational Mfg.">Recreational Mfg.</option> */}
                    <option value="Recreational Nonstorefront">Recreational Nonstorefront</option>
                    <option value="Recreational Retail">Recreational Retail</option>
                    {/* <option value="Medical Cultivation">Medical Cultivation</option>
                    <option value="Medical Mfg.">Medical Mfg.</option> */}
                    <option value="Medical Nonstorefront">Medical Nonstorefront</option>
                    <option value="Medical Retail">Medical Retail</option>
                    <option value="Microbusiness">Microbusiness</option>
                    <option value="Adult Use Storefront">Adult Use Storefront</option>
                    <option value="Adult Use Non-Storefront">Adult Use Non-Storefront</option>
                    {/* <option value="Testing Lab">Testing Lab</option>
                    <option value="Event">Event</option>
                    <option value="Distributor">Distributor</option> */}
                  </Select>
                </div>
                
                <div className="col col-lg-4">
                    <Field
                      type="text"
                      name="license"
                      component={Input}
                      placeholder="License"
                      label="License"
                    />
                </div>
            </div>
                
 
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="submit"
              disabled={actionsLoading}
              className="btn btn-primary btn-elevate"
            >Save</button>
          </Modal.Footer>
        </Form>
      </>
        )}
        </Formik>
    )


}

