import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPrivateApi+"pointstransactions";


export const pointsTransactionsService = {
    getAll,
    findOne
};


function getAll(business_id, query = '') {
    return axios.get(baseUrl+'/'+business_id+query);
}

function findOne(business_id, id) {
    return axios.get(`${baseUrl}/${business_id}/${id}`);
}


