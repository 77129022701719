import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MapDialogHeader } from './MapDialogHeader'
import { dispensaryService, mapboxService, sponsor_areaService, sponsor_subareaService } from '../../../../app/services'
import { AreaOfSponsorUpdateMap } from './AreaOfSponsorUpdateMap'
import Swal from 'sweetalert2'
import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import './style.css'
import _ from "lodash";
import Spinner from "../../spinner/Spinner";
import * as turf from "@turf/turf";
const isValidCoordinates = require('is-valid-coordinates')

export function SponsoredZoneAdmin({ 
    businessId,
    businessType,
    selectedLocation
}) {

    const actionsLoadingInitialState = false
    const [ actionsLoading, setActionsLoading ] = useState(actionsLoadingInitialState)

    const [ delete_id, setDelete_id ] = useState('')
    const [ selectedArea, setSelectedArea ] = useState('')

    const [ my_zones, setMy_zones ] = useState(false)
    const [ loadingMyZones, setLoadingMyZones ] = useState(false)

    const getMyZones = async () => {

        try {

            setLoadingMyZones(true)
            const res = await sponsor_subareaService.getAll('?filterfield[]='+businessType.toLowerCase()+'&filtertype[]=eq&filtervalue[]='+businessId+'&page=0&size=50')
            setMy_zones(res.data.totalData)
            setLoadingMyZones(false)

        } catch (er) {
            
            console.log(er)

        }

    }


    const deleteZone = (_props) => {
       
      setActionsLoading(true)
      setReady(false)
      _setSelectedLocation({center:[parseFloat(_props.long), parseFloat(_props.lat)]})
      sponsor_subareaService.update(_props.id, {[businessType.toLowerCase()]: businessType === 'BRAND' ? businessId : ''}, '?pull=1').then(data => {
          // resetForm({});
          Swal.fire(
            'Done!',
            'The item has been saved!',
            'success'
          )
          
          
            setDelete_id(null)
            getMyZones()
            setReady(true)
          setActionsLoading(false)
      })
      .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.message || error.response.data.errors[0]['message'],
          })
        
      })
  }

    const saveItem = (_props) => {
       
        setActionsLoading(true)
        setReady(false)
        _setSelectedLocation({center:[parseFloat(_props.long), parseFloat(_props.lat)]})
        sponsor_subareaService.update(_props.id, {[businessType.toLowerCase()]: businessId}, '?push=1').then(data => {
            // resetForm({});
            Swal.fire(
              'Done!',
              'The item has been saved!',
              'success'
            )
           
         
            getMyZones()
            setReady(true)
            setActionsLoading(false)
    
        })
        .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message || error.response.data.errors[0]['message'],
            })
      
        })
    }

    const [ loading, setLoading ] = useState(true)
    const [ data, setData ] = useState(null)

   

    const [ search, setSearch ] = useState('')
    const [ searchResults, setSearchResults ] = useState([])
    const [ ready, setReady ] = useState(true)

    useEffect(() => {
        if(search.length > 4){
            searchLocations(search)
        }
    }, [search])
    

    const searchLocations = async (search) => {

        try {
            setActionsLoading(true)
            let res = await mapboxService.geocoding(search)
            setSearchResults(res.data.features)
            setActionsLoading(false)
        } catch(er) {
            console.log(er)
        }

    }

    useEffect(() => {
        console.log('first')
        if(businessType != 'BRAND'){
            _setSelectedLocation({center:selectedLocation.coordinates})
            getMyZones()
        } else {
           
            getMyZones()
        }
    }, [])
    


    const [ _selectedLocation, _setSelectedLocation ] = useState(false)

    const [ _movedLocation, _setMovedLocation ] = useState(false)

    return (

        <>
            <div style={{marginLeft:'1.75rem', marginRight:'1.75rem', position:'relative'}}>
                <h4>Covered zones</h4>
                {
                    loadingMyZones ? <div className="mt-4 mb-4"><Spinner></Spinner></div>: <div className="d-flex">
                        {
                                    //_setSelectedLocation({center:[parseFloat(_props.long), parseFloat(_props.lat)]})
                            my_zones && my_zones.length > 0 ? my_zones.map((x, i) => <button onClick={()=>{
                                setReady(false)
                                _setSelectedLocation({center:turf.center(x.area).geometry.coordinates})
                                setTimeout(() => {
                                    setReady(true)
                                }, 100);
                            }
                            } className="btn btn-lg btn-light m-1">
                                Zone {i+1}
                            </button>) : (
                                <span><strong>There are no covered zones yet</strong></span>
                            )
                        }
                    </div>
                }
                
                {
                    !loadingMyZones && ready && businessType === 'BRAND' && (
                        
                            (_.isEmpty(_selectedLocation)) ? (
                                <>
                                    <input placeholder="Search location" className="form-control mt-3" value={search} onChange={(e)=>setSearch(e.target.value)}>
                                    </input>
                                    {
                                        searchResults.length > 0 && <div style={{width:'100%', zIndex:'1', borderRadius: '5px', marginTop: '5px', border:'1px solid #727272', backgroundColor:'white'}}>
                                            <ul style={{paddingTop:'1rem', paddingBottom:'1rem', paddingLeft:'0rem', marginBottom:0, listStyle:'none'}}>
                                                {
                                                    searchResults.map(x => <li 
                                                        className="option-l" 
                                                        style={{cursor:'pointer', padding:'1rem'}}
                                                        onClick={()=>_setSelectedLocation(x)}
                                                    >
                                                        {x.place_name}
                                                    </li>)
                                                }
                                            </ul>
                                        </div>
                                    }
                                </>
                            ) : (
                                <div style={{
                                    padding: '1rem',
                                    backgroundColor: '#d8e5ff',
                                    borderRadius: '5px',
                                    border: '1px solid #b6ceff',
                                    display:'flex',
                                    justifyContent:'space-between',
        
                                }} className='mt-3'>
                                    <span>
                                        {/* {_selectedLocation.place_name} */}
                                    </span>
                                    <button onClick={()=>{
                                        _setSelectedLocation({})
                                        setSearch('')
                                        setSearchResults([])
                                    }} className="btn btn-text" style={{padding:'0', color:'blue'}}>Search place</button>
                                </div>
                            )
                        
                    )
                }
                
            </div>
            {
                (!_.isEmpty(_selectedLocation) && isValidCoordinates(_selectedLocation.center[0], _selectedLocation.center[1])) && <div style={{marginLeft:'1.75rem', marginRight:'1.75rem', position:'relative', marginTop:'2rem'}}>
                    <h4>Map</h4>
                </div>
            }
            
           
            {
                !ready ? <div className="mt-4 mb-4"><Spinner /></div> : 
                (!_.isEmpty(_selectedLocation) && isValidCoordinates(_selectedLocation.center[0], _selectedLocation.center[1])) ? <AreaOfSponsorUpdateMap
                    dispensaryType={businessType}
                    actionsLoading={actionsLoading}
                    dispensaryId={businessId}
                    saveItem={saveItem}
                    _selectedLocation={_selectedLocation}
                    _setSelectedLocation={_setSelectedLocation}
                    // onHide={onHide}
                    delete_id={delete_id}
                    deleteZone={deleteZone}
                    setDelete_id={setDelete_id}
                    setActionsLoading={setActionsLoading}
                    selectedArea={selectedArea}
                    setSelectedArea={setSelectedArea}
                    // city={city}
                    data={data}
                /> : <>
                    
                </>
            }   
        </>
              
   
    )
    
}