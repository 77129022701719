import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MapDialogHeader } from './MapDialogHeader'
import { dispensaryService } from '../../../../app/services'
import { AreaOfCoverageUpdateMap } from './AreaOfCoverageUpdateMap'
import Swal from 'sweetalert2'

export function AreaOfCoverageUpdateDialog({ 
    location,
    show, 
    onHide, 
    fetchDispensary, 
    coverage, 
    type, 
    address, 
    addresszip, 
    city, 
    state, 
    address_line_2,
    businessType,
    dispensaryId
}) {

    const actionsLoadingInitialState = false
    const [ actionsLoading, setActionsLoading ] = useState(actionsLoadingInitialState)

    const saveItem = (fields) => {
      
        setActionsLoading(true)
        dispensaryService.update(dispensaryId, fields).then(data => {
            // resetForm({});
            Swal.fire(
              'Done!',
              'The item has been saved!',
              'success'
            )
            fetchDispensary()
            setActionsLoading(false)
            onHide()
        })
        .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message || error.response.data.errors[0]['message'],
            })
            setActionsLoading(false)
        })
    }

    return (
        <Modal
          size="lg"
          show={show}
          onHide={onHide}
          aria-labelledby="example-modal-sizes-title-lg"
        >
            <MapDialogHeader 
              actionsLoading={actionsLoading} 
              title={type === 'POLYGON' ? 'area of coverage' : 'exact location'}
            />
            <AreaOfCoverageUpdateMap
                actionsLoading={actionsLoading}
                saveItem={saveItem}
                onHide={onHide}
                setActionsLoading={setActionsLoading}
                coverage={coverage}
                type={type}
                address={address}
                address_line_2={address_line_2}
                addresszip={addresszip}
                city={city}
                state={state}
                location={location}
                businessType={businessType}
            />   
        </Modal>
    )
    
}
