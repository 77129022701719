/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";

export function PublisherAsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>



          <li
              className={`menu-item ${getMenuItemActive("/publisher/dashboard", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/publisher/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>


          <li
              className={`menu-item ${getMenuItemActive("/publisher/article", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/publisher/article">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}/>
            </span>
              <span className="menu-text">Articles</span>
            </NavLink>
          </li>

        </ul>
      </>
  );
}
