import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import Swal from 'sweetalert2'

const initialValues = {
	name: "",
	email: "",
	birthdate: "",
	password: "",
	confirmPassword: "",
	type: "DISPENSARY",
	acceptTerms: false,
};

function Registration(props) {
	const { intl } = props;
	const history = useHistory()
	const [loading, setLoading] = useState(false);

	const Toast = Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		onOpen: (toast) => {
			toast.addEventListener('mouseenter', Swal.stopTimer)
			toast.addEventListener('mouseleave', Swal.resumeTimer)
		}
	})




	const RegistrationSchema = Yup.object().shape({
		name: Yup.string()
			.min(3, "Minimum 3 symbols")
			.max(50, "Maximum 50 symbols")
			.required(
				intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD",
				})
			),
		email: Yup.string()
			.email("Wrong email format")
			.min(3, "Minimum 3 symbols")
			.max(50, "Maximum 50 symbols")
			.required(
				intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD",
				})
			),
		birthdate: Yup.string()
			// .min(3, "Minimum 3 symbols")
			// .max(50, "Maximum 50 symbols")
			.required(
				intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD",
				})
			),
		password: Yup.string()
			.min(3, "Minimum 3 symbols")
			.max(50, "Maximum 50 symbols")
			.required(
				intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD",
				})
			),
		confirmPassword: Yup.string()
			.required(
				intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD",
				})
			)
			.when("password", {
				is: (val) => (val && val.length > 0 ? true : false),
				then: Yup.string().oneOf(
					[Yup.ref("password")],
					"Password and Confirm Password didn't match"
				),
			}),
		acceptTerms: Yup.bool().required(
			"You must accept the terms and conditions"
		),
	});

	const enableLoading = () => {
		setLoading(true);
	};

	const disableLoading = () => {
		setLoading(false);
	};

	const getInputClasses = (fieldname) => {
		if (formik.touched[fieldname] && formik.errors[fieldname]) {
			return "is-invalid";
		}

		if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return "is-valid";
		}

		return "";
	};

	const formik = useFormik({
		initialValues,
		validationSchema: RegistrationSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			enableLoading();
			register(values)
				.then(() => {
					Toast.fire({
						icon: 'success',
						title: 'Signed in successfully, now you can log in'
					})
					history.push(`auth/login`)
				})
				.catch(() => {
					setSubmitting(false);
					const Toast = Swal.mixin({
						toast: true,
						position: 'top-end',
						showConfirmButton: false,
						timer: 3000,
						timerProgressBar: true,
						onOpen: (toast) => {
							toast.addEventListener('mouseenter', Swal.stopTimer)
							toast.addEventListener('mouseleave', Swal.resumeTimer)
						}
					})

					Toast.fire({
						icon: 'warning',
						title: 'Ooops, something went wrong'
					})
					disableLoading();
				});
		},
	});

	return (
		<div className="login-form login-signin" style={{ display: "block" }}>
			<div className="text-center mb-10 mb-lg-20">
				<h3 className="font-size-h1">
					<FormattedMessage id="AUTH.REGISTER.TITLE" />
				</h3>
				<p className="text-muted font-weight-bold">
					Enter your details to create your account
        </p>
			</div>

			<form
				id="kt_login_signin_form"
				className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
				onSubmit={formik.handleSubmit}
			>
				{/* begin: Alert */}
				{formik.status && (
					<div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
						<div className="alert-text font-weight-bold">{formik.status}</div>
					</div>
				)}
				{/* end: Alert */}

				{/* begin: name */}
				<div className="form-group fv-plugins-icon-container">
					<input
						placeholder="Full name"
						type="text"
						className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
							"name"
						)}`}
						name="name"
						{...formik.getFieldProps("name")}
					/>
					{formik.touched.name && formik.errors.name ? (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">{formik.errors.name}</div>
						</div>
					) : null}
				</div>
				{/* end: name */}

				{/* begin: Email */}
				<div className="form-group fv-plugins-icon-container">
					<input
						placeholder="Email"
						type="email"
						className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
							"email"
						)}`}
						name="email"
						{...formik.getFieldProps("email")}
					/>
					{formik.touched.email && formik.errors.email ? (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">{formik.errors.email}</div>
						</div>
					) : null}
				</div>
				{/* end: Email */}

				{/* begin: Username */}
				<div className="form-group fv-plugins-icon-container">
					<input
						placeholder="Birthdate"
						type="date"
						className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
							"birthdate"
						)}`}
						name="birthdate"
						{...formik.getFieldProps("birthdate")}
					/>
					{formik.touched.birthdate && formik.errors.birthdate ? (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">{formik.errors.birthdate}</div>
						</div>
					) : null}
				</div>
        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <select name="type" className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "type"
            )}`} {...formik.getFieldProps("type")}>
            <option value=''>Select a company type</option>
            <option value='DISPENSARY'>Dispensary</option>
            <option value='DELIVERY'>Delivery</option>
            <option value='BRAND'>Cannabis brand</option>
            <option value='DOCTOR'>Doctor</option>
          </select>
          {formik.touched.type && formik.errors.type ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.type}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}



				{/* begin: Password */}
				<div className="form-group fv-plugins-icon-container">
					<input
						placeholder="Password"
						type="password"
						className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
							"password"
						)}`}
						name="password"
						{...formik.getFieldProps("password")}
					/>
					{formik.touched.password && formik.errors.password ? (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">{formik.errors.password}</div>
						</div>
					) : null}
				</div>
				{/* end: Password */}

				{/* begin: Confirm Password */}
				<div className="form-group fv-plugins-icon-container">
					<input
						placeholder="Confirm Password"
						type="password"
						className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
							"confirmPassword"
						)}`}
						name="confirmPassword"
						{...formik.getFieldProps("confirmPassword")}
					/>
					{formik.touched.confirmPassword && formik.errors.confirmPassword ? (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								{formik.errors.confirmPassword}
							</div>
						</div>
					) : null}
				</div>
				{/* end: Confirm Password */}

				{/* begin: Terms and Conditions */}
				<div className="form-group">
					<label className="checkbox">
						<input
							type="checkbox"
							name="acceptTerms"
							className="m-1"
							{...formik.getFieldProps("acceptTerms")}
						/>
						<Link to="/terms" target="_blank" className="mr-1" rel="noopener noreferrer">
							I agree the Terms & Conditions
            </Link>
						<span />
					</label>
					{formik.touched.acceptTerms && formik.errors.acceptTerms ? (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">{formik.errors.acceptTerms}</div>
						</div>
					) : null}
				</div>
				{/* end: Terms and Conditions */}
				<div className="form-group d-flex flex-wrap flex-center">
					<button
						type="submit"
						disabled={formik.isSubmitting || !formik.values.acceptTerms}
						className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
					>
						<span>Submit</span>
						{loading && <span className="ml-3 spinner spinner-white"></span>}
					</button>

					<Link to="/auth/login">
						<button
							type="button"
							className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
						>
							Cancel
            </button>
					</Link>
				</div>
			</form>
		</div>
	);
}

export default injectIntl(connect(null, auth.actions)(Registration));
