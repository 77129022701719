import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPrivateApi+"settings";


export const settingService = {
    get,
    update,
};


function get() {
    return axios.get(`${baseUrl}`);
}

function update(params) {
    return axios.put(`${baseUrl}`, params)
}