import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register, resetPassword, validateToken } from "../_redux/authCrud";
import Swal from 'sweetalert2'
import queryString from "query-string";
import { validate } from "@material-ui/pickers";

const initialValues = {
	password: "",
};

function RecoveryPassword(props) {
	const { intl } = props;
	const history = useHistory()
	const [loading, setLoading] = useState(false);
	const [expired, setExpired] = useState(false);
	const [verified, setVerified] = useState(false) ;
	// eslint-disable-next-line no-restricted-globals
	let { token } = queryString.parse(window.location.search);

	const Toast = Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		onOpen: (toast) => {
			toast.addEventListener('mouseenter', Swal.stopTimer)
			toast.addEventListener('mouseleave', Swal.resumeTimer)
		}
	})




	const RegistrationSchema = Yup.object().shape({
		password: Yup.string()
			.min(3, "Minimum 3 symbols")
			.max(50, "Maximum 50 symbols")
			.required(
				intl.formatMessage({
					id: "AUTH.VALIDATION.REQUIRED_FIELD",
				})
			),
	});

	const enableLoading = () => {
		setLoading(true);
	};

	const disableLoading = () => {
		setLoading(false);
	};

	const getInputClasses = (fieldname) => {
		if (formik.touched[fieldname] && formik.errors[fieldname]) {
			return "is-invalid";
		}

		if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return "is-valid";
		}

		return "";
	};

	const validate = async () => {
		validateToken(token).then(e=>{
			setVerified(true);
		}).catch(e=>{
			setExpired(true);
		})
	}

	useEffect(() => {
		validate()
	}, [])


	

	const formik = useFormik({
		initialValues,
		validationSchema: RegistrationSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			enableLoading();
			resetPassword(token,values.password)
				.then(() => {
					Toast.fire({
						icon: 'success',
						title: 'password reset successfully, now you can log in'
					})
					history.push(`auth/login`)
				})
				.catch(() => {
					setSubmitting(false);
					const Toast = Swal.mixin({
						toast: true,
						position: 'top-end',
						showConfirmButton: false,
						timer: 3000,
						timerProgressBar: true,
						onOpen: (toast) => {
							toast.addEventListener('mouseenter', Swal.stopTimer)
							toast.addEventListener('mouseleave', Swal.resumeTimer)
						}
					})

					Toast.fire({
						icon: 'warning',
						title: 'Ooops, something went wrong'
					})
					disableLoading();
				});
		},
	});

	return (
		<div className="login-form login-signin" style={{ display: "block" }}>
			<div className="text-center mb-10 mb-lg-20">
				<h3 className="font-size-h1">
					{console.log(queryString.parse(window.location.search).create)}
					{
						queryString.parse(window.location.search).create === 'true' ? 'Create Password' : 'Recover Password'
					}
				</h3>
				{/* <p className="text-muted font-weight-bold">
					Enter your details to create your account
        		</p> */}
			</div>
			{expired && <div className="text-center mb-10 mb-lg-20">
				<h3 className="font-size-h1">
					<FormattedMessage id="AUTH.RECOVERY.EXPIRED" />
				</h3>
			</div>}
			{!expired && <form
				id="kt_login_signin_form"
				className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
				onSubmit={formik.handleSubmit}
			>
				{/* begin: Alert */}
				{formik.status && (
					<div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
						<div className="alert-text font-weight-bold">{formik.status}</div>
					</div>
				)}
				{/* end: Alert */}

				{/* begin: Password */}
				<div className="form-group fv-plugins-icon-container">
					<input
						placeholder="New Password"
						type="password"
						className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
							"password"
						)}`}
						name="password"
						{...formik.getFieldProps("password")}
					/>
					{formik.touched.password && formik.errors.password ? (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">{formik.errors.password}</div>
						</div>
					) : null}
				</div>
				{/* end: Password */}

				{/* begin: Confirm Password */}
				{/* end: Confirm Password */}

				<div className="form-group d-flex flex-wrap flex-center">
					<button
						type="submit"
						disabled={formik.isSubmitting}
						className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
					>
						<span>Submit</span>
						{loading && <span className="ml-3 spinner spinner-white"></span>}
					</button>

					<Link to="/auth/login">
						<button
							type="button"
							className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
						>
							Cancel
            			</button>
					</Link>
				</div>
			</form>}
		</div>
	);
}

export default injectIntl(connect(null, auth.actions)(RecoveryPassword));
