/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../../layout";
import { analyticsService, orderService } from '../../../../app/services'
import Swal from 'sweetalert2'
import Spinner from '../../spinner/Spinner'
import { saService } from "../../../../app/services/sa.service";
import queryString from "query-string";
import _ from "lodash";
import moment from "moment";

export function IncomeChartWidget({ 
  className, 
  symbolShape, 
  baseColor, 
  data, 
  dispensarySlug, 
  rangeDates, 
  business_id,
  subscription,
  loadingSubscription
}) {
  const uiService = useHtmlClassService();

  const layoutProps = {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${baseColor}`
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${baseColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
      data:salesData
    };
  




  const salesDataInitialState = false
  const [ salesData, setSalesData ] = useState(salesDataInitialState)
  const [ loading, setLoading ] = useState(true)
    const [ dontShow, setDontShow ] = useState(false)

  useEffect(() => {
    setLoading(true)
    if(!_.isEmpty(rangeDates)){
      fetch()
    } else {
      setLoading(false)
     
    }
    
  }, [rangeDates])

  const queries = (selectedTab) => {
    let timeframe = 0
    switch (selectedTab) {
      case 'Week':
        timeframe = 'week'
        break;

      case 'Month':
        timeframe = 'month'
        break;

      case 'Year':
        timeframe = 60 * 60 * 24 * 365
        break;
    
      default:
        break;
    }
    return '?timeframeStr='+timeframe
  }
  
  const fetch = async (query) => {

      analyticsService.getProfileViews(business_id, '?'+queryString.stringify(rangeDates))
          .then((res) => {
              if(!res.data.historygram){

              } else {
                setSalesData(res.data);
                setLoading(false)
                
              }
              setDontShow(false)
          })
          .catch((error) => {

              setDontShow(true)
          })

  }

  const tabs = {
    tab1: "Month",
    // tab2: "Month",
    // tab3: "Year",
  };

  const [activeTab, setActiveTab] = useState(tabs.tab1);


  // useEffect(() => {
  //   setLoading(true)
  //   fetch(queries(activeTab))
  // }, [])

  
  useEffect(() => {
    const element = document.getElementById("kt_stats_widget_11_chart");
    
    if (!element || !salesData) {
      return;
    }

    if(!salesData.historygram.length > 0){
      return;
    }

    element.innerHTML = "";

    const options = getChartOption(layoutProps, salesData);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [salesData]);

  if(dontShow) return (
    <div className={`card card-custom h-100`}>
   
      <div className="card-header border-0 pt-5">
        <div className='d-flex justify-content-between w-100'>
          {/* <span className={`symbol ${symbolShape} symbol-50 symbol-light-${baseColor} mr-2`}>
            <span className="symbol-label">
              <span className={`svg-icon svg-icon-xl svg-icon-${baseColor}`}>
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Shopping/Wallet3.svg"
                  )}
                ></SVG>
              </span>
            </span>
          </span> */}
          <h3 className="card-title align-items-start">
            <span className="card-label font-weight-bolder text-dark">
              Page Views
            </span>
            {/* <span className="text-muted mt-3 font-weight-bold font-size-sm">
              The best of your menu
            </span> */}
         
          </h3>
      
        </div>
   
      </div>
      <div className="card-body p-3 d-flex justify-content-center align-items-center" >
         
            <h4>Upgrade your plan to display the profile page views chart</h4>
     
      </div>
    </div>
  )

  return (
    <div className={`card card-custom ${className}`}>
      
      <div className="card-header border-0 pt-5">
        <div className='d-flex justify-content-between w-100'>
          {/* <span className={`symbol ${symbolShape} symbol-50 symbol-light-${baseColor} mr-2`}>
            <span className="symbol-label">
              <span className={`svg-icon svg-icon-xl svg-icon-${baseColor}`}>
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Shopping/Wallet3.svg"
                  )}
                ></SVG>
              </span>
            </span>
          </span> */}
          <h3 className="card-title align-items-start">
            <span className="card-label font-weight-bolder text-dark">
              Page Views
            </span>
            {/* <span className="text-muted mt-3 font-weight-bold font-size-sm">
              The best of your menu
            </span> */}
            
          </h3>
          {
              loading && salesData.historygram && salesData.historygram.length > 0 && <div className="spinner spinner-lg spinner-success mr-4" />
            }
        </div>
        {/* <div className="card-toolbar">
          <ul className="nav nav-pills nav-pills-sm nav-dark-75">
            <li className="nav-item">
              <a
                className={`nav-link py-2 px-4 ${activeTab === tabs.tab1 &&
                  "active"}`}
                data-toggle="tab"
                href={`#${tabs.tab1}`}
                onClick={() => setActiveTab(tabs.tab1)}
              >
                Month
              </a>
            </li> 
            <li className="nav-item">
              <a
                className={`nav-link py-2 px-4 ${activeTab === tabs.tab2 &&
                  "active"}`}
                data-toggle="tab"
                href={`#${tabs.tab2}`}
                onClick={() => setActiveTab(tabs.tab2)}
              >
                Month
              </a>
            </li> 
            <li className="nav-item">
              <a
                className={`nav-link py-2 px-4 ${activeTab === tabs.tab3 &&
                  "active"}`}
                data-toggle="tab"
                href={`#${tabs.tab3}`}
                onClick={() => setActiveTab(tabs.tab3)}
              >
                Year
              </a>
            </li> 
          </ul>
        </div> */}
      </div>
      <div className="card-body p-3">
     
      {
          loading && !salesData.historygram && <Spinner /> ? (
            <div className='card-spacer'>
              <div className='pt-10 pb-10'>
                <Spinner />
              </div>
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between pr-10 flex-grow-1">
                <span></span>
                <div className="d-flex flex-column text-right">
                  <span className="text-dark-75 font-weight-bolder font-size-h3">
                    
                  </span>
                </div>
              </div>
            </>
          )
        }
        
         {
           salesData && !salesData.historygram.length > 0 ? (
              <div className='d-flex h-100 align-items-center justify-content-center pt-8 pb-8'>
                <div className='card-spacer'>
                  <center><h4>You haven't had any visit this month</h4></center>
                </div>
            </div>
           ) : salesData && salesData.historygram.length > 0 ? (
              <div
              id="kt_stats_widget_11_chart"
              className={`card-rounded-bottom`}
              style={{ height: "320px", minHeight:'320px' }}
            ></div>
           ) : ''
         }
        
      </div>
    </div>
  );
}

// sa_api_key_6nNMZH4F5D3OYacl7ZB3zvDmSYgWbAoPwr1a

function getChartOption(layoutProps, salesData) {

  console.log(salesData)
  const options = {
    series: [
      {
        name: "Profile Views",
        data: salesData.historygram.map(x => {
          return x.pageviews
        }),
      },
      // {
      //   name: "Unique Visitors",
      //   data: salesData.historygram.map(x => {
      //     return x.visitors
      //   }),
      // },
    ],
    chart: {
      type: "area",
      height: 320,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      // sparkline: {
      //   enabled: true,
      // },
    },
    plotOptions: {},
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
      style: {
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        colors: ['#1BC5BD']
      },
    },
    // fill: {
    //   type: "solid",
    //   opacity: 1,
    // },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [layoutProps.colorsThemeBaseSuccess],
    },
    labels: salesData.historygram.map(x => {
      return moment(x.date).format('MMM DD')
    }),
    xaxis: {
      // type: 'datetime',
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        show: true,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
      crosshairs: {
        show: true,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    yaxis: {
      // min: 0,
      // max: 55,
      labels: {
        show: true,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function(val) {
          return val ? val.toFixed(0) : ''
        },
      },
    },
    // colors: [layoutProps.colorsThemeLightSuccess],
    markers: {
      // colors: [layoutProps.colorsThemeLightSuccess],
      // strokeColor: [layoutProps.colorsThemeBaseSuccess],
      strokeWidth: 3,
    },
  };
  return options;
}
// const options = {
//   series: [
//     {
//       name: "Net Profit",
//       data: [3500, 2304, 2268, 250, 1500, 530, 50, 0, 0, 4100, 0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
//     },
//   ],
//   chart: {
//     type: "area",
//     height: 350,
//     toolbar: {
//       show: false,
//     },
//     zoom: {
//       enabled: false,
//     },
//     // sparkline: {
//     //   enabled: true,
//     // },
//   },
//   plotOptions: {},
//   legend: {
//     show: true,
//   },
//   dataLabels: {
//     enabled: true,
//     style: {
//       fontSize: '14px',
//       fontFamily: 'Helvetica, Arial, sans-serif',
//       fontWeight: 'bold',
//       colors: ['#1BC5BD']
//     },
//   },
//   fill: {
//     type: "solid",
//     opacity: 1,
//   },
//   stroke: {
//     curve: "smooth",
//     show: true,
//     width: 3,
//     colors: [layoutProps.colorsThemeBaseSuccess],
//   },
//   xaxis: {
//     categories: [
//       "01", 
//       "02", 
//       "03", 
//       "04", 
//       "05", 
//       "06", 
//       "07", 
//       "08", 
//       "09", 
//       "10", 
//       "11", 
//       "12", 
//       "13", 
//       "14", 
//       "15", 
//       "16", 
//       "17", 
//       "18", 
//       "19", 
//       "20",
//       "21", 
//       "22", 
//       "23", 
//       "24", 
//       "25", 
//       "26", 
//       "27", 
//       "28", 
//       "29", 
//       "30",
//     ],
//     axisBorder: {
//       show: false,
//     },
//     axisTicks: {
//       show: false,
//     },
//     labels: {
//       show: true,
//       style: {
//         colors: layoutProps.colorsGrayGray500,
//         fontSize: "12px",
//         fontFamily: layoutProps.fontFamily,
//       },
//     },
//     crosshairs: {
//       show: false,
//       position: "front",
//       stroke: {
//         color: layoutProps.colorsGrayGray300,
//         width: 1,
//         dashArray: 3,
//       },
//     },
//     tooltip: {
//       enabled: true,
//       formatter: undefined,
//       offsetY: 0,
//       style: {
//         fontSize: "12px",
//         fontFamily: layoutProps.fontFamily,
//       },
//     },
//   },
//   yaxis: {
//     min: 0,
//     max: 55,
//     labels: {
//       show: false,
//       style: {
//         colors: layoutProps.colorsGrayGray500,
//         fontSize: "12px",
//         fontFamily: layoutProps.fontFamily,
//       },
//     },
//   },
//   states: {
//     normal: {
//       filter: {
//         type: "none",
//         value: 0,
//       },
//     },
//     hover: {
//       filter: {
//         type: "none",
//         value: 0,
//       },
//     },
//     active: {
//       allowMultipleDataPointsSelection: false,
//       filter: {
//         type: "none",
//         value: 0,
//       },
//     },
//   },
//   tooltip: {
//     style: {
//       fontSize: "12px",
//       fontFamily: layoutProps.fontFamily,
//     },
//     y: {
//       formatter: function(val) {
//         return "$" + val + " thousands";
//       },
//     },
//   },
//   colors: [layoutProps.colorsThemeLightSuccess],
//   markers: {
//     colors: [layoutProps.colorsThemeLightSuccess],
//     strokeColor: [layoutProps.colorsThemeBaseSuccess],
//     strokeWidth: 3,
//   },
// };