import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPrivateApi+"receipts";


export const receiptService = {
    getAll,
    getById,
    update,
};


function getAll(query = '') {
    return axios.get(baseUrl+query);
}

function getById(id) {
    return axios.get(`${baseUrl}/${id}`);
}

function update(id, params) {
    return axios.put(`${baseUrl}/${id}`, params)
}

