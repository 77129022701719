import React, { useState, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { mapboxService } from '../../../../app/services';
import Swal from 'sweetalert2'
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import CircleMode from 'mapbox-gl-draw-circle/lib/modes/CircleMode';
import { DirectMode, SimpleSelectMode } from 'mapbox-gl-draw-circle';
import DragCircleMode from 'mapbox-gl-draw-circle/lib/modes/DragCircleMode';
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import _, { debounce } from 'lodash'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { PlacesList } from './PlacesList'




export const AreaOfCoverageUpdateMap = ({
    type,
    coverage,
    actionsLoading,
    setActionsLoading,
    saveItem,
    onHide,
    address,
    address_line_2,
    addresszip,
    city,
    state,
    location,
    businessType
}) => {

    const [ selectedPlace, setSelectedPlace ] = useState({})
    const mapContainer = useRef(null);

    //Initial states
    const mapInitialState=null
    const longitudeInitialValue=0; 
    const latitudeInitialValue=0;

    //States
    const [map, setMap] = useState(mapInitialState);
    const [latitude, setLatitude] = useState(location ? location.coordinates[1] : latitudeInitialValue)
	const [longitude, setLongitude] = useState(location ? location.coordinates[0] : longitudeInitialValue)
	const [geometry, setGeometry] = useState(null);
    const [markerAddress, setMarkerAddress] = useState(null);
    const [markerZip, setMarkerZip] = useState(null);
    const [markerCity, setMarkerCity] = useState(null);
    const [markerState, setMarkerState] = useState(null);
    const [enableSearch, setEnableSearch] = useState(false);
    const [instMode, setInstMode] = useState(false);

    const getAddress = async () => {
        {console.log(longitude, latitude)}
        let response = await mapboxService.reverseGeocoding(longitude, latitude, '&types=address&')
        console.log(response)
        if(response.data.features.length > 0 ){
            setMarkerAddress(response.data.features[0].address ? response.data.features[0].address+' '+response.data.features[0].text : response.data.features[0].text)
            setMarkerZip(response.data.features[0].context.find(x => x.id.includes('postcode')))
            setMarkerCity(response.data.features[0].context.find(x => x.id.includes('place')))
            setMarkerState(response.data.features[0].context.find(x => x.id.includes('region')))
        }
    }

    useEffect(() => {
        if(longitude !=0 && latitude !=0) {
            getAddress()
        }
    }, [longitude, latitude])

    const styles = {
        width: "100%",
        position: "relative",
        height:"500px"
    };
    
    

    const showInteractiveMap = (newLong, newLat, add) => {
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
        const initializeMap = ({ setMap, mapContainer }) => {
			let _longitude = newLong || longitude
			let _latitude = newLat || latitude
			if(type==='POLYGON'){
				_longitude = (!_.isEmpty(coverage) && coverage.coordinates.length > 0) ? coverage.coordinates[0][0][0] : newLong;
				_latitude = (!_.isEmpty(coverage) && coverage.coordinates.length > 0) ? coverage.coordinates[0][0][1] : newLat;
			}
            var map = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
                center: [_longitude, _latitude],
                zoom: type === 'POLYGON' ? 11 : 17
            });
			let draw = null;
			if (type === "POLYGON") {
				// userProperties has to be enabled
				draw = new MapboxDraw({
                  
                    userProperties: true,
                    modes: {
                      ...MapboxDraw.modes,
                    //   draw_circle  : CircleMode,
                    //   drag_circle  : DragCircleMode,
                    //   direct_select: DirectMode,
                    //   simple_select: SimpleSelectMode
                    }
                  });
				map.addControl(draw);
                // draw.changeMode('draw_circle', { initialRadiusInKm: 2 });
				map.on('draw.create', function (e) {
					setGeometry(e.features[0].geometry)
				});
				map.on('draw.update', function (e) {
					setGeometry(e.features[0].geometry)
				});
			} else {
				var marker = new mapboxgl.Marker({
					draggable: true
				})

           
				map.on('click', addMarker);

            }
            
            function initializeMarker(e){
                var lng = newLong || longitude;
                var lat =  newLat || latitude;
                marker.setLngLat([lng, lat]).addTo(map)
			}
			
			function initializeZone(e){
				// if(geometry){
                //     draw.set({
                //         type: 'FeatureCollection',
                //         features: [{
                //             id: 'unique-id',
                //             type: 'Feature',
                //             properties: {
                //                 center: [_longitude, _latitude],
                //                 isCircle: true,
                //                 radiusInKm: 2,
                //             },
                //             'geometry': geometry
                //         }]
                //     });
                // }
				/* draw.add({
					
					
				}); */
			}

            function addMarker(e){
                var lng = e.lngLat.wrap().lng;
                var lat =  e.lngLat.wrap().lat;
                marker.setLngLat([lng, lat]).addTo(map)
                var lngLat = marker.getLngLat();
                setLatitude(lngLat.lat);
                setLongitude(lngLat.lng);
            }


            map.on("load", () => {
                if(type === "POLYGON"){
					initializeZone()
				}else{
					initializeMarker();
				}
                setMap(map);
                map.resize();
            });
        };
        initializeMap({ setMap, mapContainer });
    }

    useEffect(() => {
        if(!_.isEmpty(selectedPlace)){
            setLongitude(selectedPlace.center[0])
            setLatitude(selectedPlace.center[1])
            showInteractiveMap(selectedPlace.center[0], selectedPlace.center[1], true)
        }
    }, [selectedPlace])


    // useEffect(() => {
 
    //     showInteractiveMap(longitude, latitude)
        
    // }, [longitude, latitude])

    useEffect(() => {
        if(!_.isEmpty(location)){
            setLongitude(location.coordinates[0])
            setLatitude(location.coordinates[1])
            showInteractiveMap(location.coordinates[0], location.coordinates[1])
        }
    }, [location])

    useEffect(() => {
        if(!_.isEmpty(coverage)){
            setGeometry(coverage)
        }
    }, [coverage])

    useEffect(() => {
        const el = document.getElementById('map-container')
        if(!_.isEmpty(coverage) && !el.childNodes.length>0){
            if(!_.isEmpty(geometry)){
                showInteractiveMap()
            }
        }
    }, [geometry])

    // useEffect(() => {
    //     if(!instMode){

    //             showInteractiveMap()
            
        
    //     }
    // }, [instMode])

    return (
        <>
            <div
                className='overlay overlay-block cursor-default modal-body'
            >
               
                {
                    ((_.isEmpty(location) && _.isEmpty(coverage) && _.isEmpty(selectedPlace)) || (enableSearch)) ? (
                        <PlacesList 
                            actionsLoading={actionsLoading}
                            setActionsLoading={setActionsLoading}
                            setSelectedPlace={(e) => {
                                setEnableSearch(false);
                                setSelectedPlace(e)
                            }}
                            address={address}
                            address_line_2={address_line_2}
                            addresszip={addresszip}
                            city={city}
                            state={state}
                        />
                    ) : (
                        <div className="form-group row">
                            
                            <div className={`col d-flex flex-column`}>
                            {
                                    !_.isEmpty(location) && <div><button className='btn btn-primary' onClick={()=>setEnableSearch(true)}>Enter your address</button></div>
                                }
                                <br/> 
                                <h4>{selectedPlace.place_name}</h4>
                                {
                                    instMode && <>
                                        <small className={"form-hint"} >
                                            {
                                                type==='POLYGON' ? (<>
                                                    <strong>
                                                        1 - Navigate around the map and find your area of coverage.<br></br>
                                                        2 - When found click the Polygon tool at right.<br></br>
                                                        {/* <img height='80' src={toAbsoluteUrl("/media/ax.jpg")}></img><br></br> */}
                                                        3 - When selected, click on the map and create your radius. When finished, click on the orange circle or hit enter.
                                                        {/* <br></br><img height='80' src={toAbsoluteUrl("/media/acc.jpg")}></img><br></br> */}
                                                    </strong>

                                                </>) : (
                                                    <strong>
                                                        * Navigate around the map and click in the exact place of your location.<br></br>
                                                    </strong>
                                                )
                                            }
                                            <img src={toAbsoluteUrl("/media/acx.gif")}></img>
                                            <button className='btn btn-block btn-primary' onClick={()=>setInstMode(false)}>Go to map</button>
                                        </small>
                                    </>
                                }
                                {
                                    type!='POLYGON' && <strong>
                                    * Navigate around the map and click in the exact place of your location.<br></br>
                                </strong>
                                }
                                {
                                    (type === "POLYGON" && !instMode) && <div className='d-flex justify-content-between align-items-center'><h4>Add your area in the map</h4><button onClick={()=>setInstMode(true)} className='btn btn-info'>How to use the map tool?</button></div>
                                }
                                <br></br>
                                {
                                    <div ref={el => (mapContainer.current = el)} style={{...styles}} className={`${instMode ? 'd-none' : ''}`} id='map-container' /> 
                                }
                                       
                                {
                                    type != "POLYGON" ? <>
                                         <hr/>
                                        <strong>Address</strong>
                                        <input className='form-control' type='text' value={markerAddress} onChange={(e)=>{setMarkerAddress(e.target.value)}} />
                                        <strong>City</strong>
                                        <input className='form-control' readOnly={true} type='text' value={markerCity ? markerCity.text : ''} onChange={(e)=>{setMarkerCity(e.target.value)}} />
                                        <strong>Zip</strong>
                                        <input className='form-control' readOnly={true} type='text' value={markerZip ? markerZip.text : ''} onChange={(e)=>{setMarkerZip(e.target.value)}} />
                                        <strong>State</strong>
                                        <input className='form-control' readOnly={true} type='text' value={markerState ? markerState.text : ''} onChange={(e)=>{setMarkerState(e.target.value)}} />
                                    </> : <span className='text-danger'>If you update the area of coverage, the current delivery point will be reset
</span>
                                }
                                </div>
                        </div>
                    )
                }
            </div>
            <div class="modal-footer">
                <button type="button" onClick={()=>onHide()} class="btn btn-light btn-elevate">Cancel</button> 
                {
                    type==='POLYGON' ? (
                        <button type="submit" disabled={_.isEmpty(geometry) || actionsLoading===true} onClick={()=>saveItem({coverage:geometry})} class="btn btn-primary btn-elevate">Save</button>
                    ) : (
                        <button type="submit" disabled={ (longitude===0 && latitude===0) || actionsLoading===true} onClick={()=>saveItem({location: {coordinates:[longitude, latitude], type:'Point'}, address:markerAddress})} class="btn btn-primary btn-elevate">Save</button>
                    )
                }
            </div>
        </>
    )
}



