import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { EditForm } from "./EditForm";
import { EditDialogHeader } from './EditDialogHeader'
import { dispensaryService } from '../../../../../app/services'
import Swal from 'sweetalert2'
import Moment from 'moment'

const baseHoursofoperation = {
  sunday: {
    opens_at:'',
    closes_at:'',
    isEnabled:true
  },
  monday: {
    opens_at:'',
    closes_at:'',
    isEnabled:true
  },
  tuesday: {
    opens_at:'',
    closes_at:'',
    isEnabled:true
  },
  wednesday:{
    opens_at:'',
    closes_at:'',
    isEnabled:true
  },
  thursday: {
    opens_at:'',
    closes_at:'',
    isEnabled:true
  },
  friday: {
    opens_at:'',
    closes_at:'',
    isEnabled:true
  },
  saturday: {
    opens_at:'',
    closes_at:'',
    isEnabled:true
  }
}

export function EditDialog({ show, onHide, scopedDay, fetchDispensary, hoursofoperation={}, dispensaryId }) {

    const actionsLoadingInitialState = false
    const [ actionsLoading, setActionsLoading ] = useState(actionsLoadingInitialState)
    const initItem = {
        opens_at: (scopedDay) ? Moment.utc().startOf('day').add(scopedDay.opens_at, 'minutes').format('HH:mm') : '',
	      closes_at: (scopedDay) ? Moment.utc().startOf('day').add(scopedDay.closes_at, 'minutes').format('HH:mm') : '',
        isEnabled: (scopedDay) ? scopedDay.isEnabled : false,
        day: (scopedDay) ? scopedDay.day : '',
    }

    const saveItem = (fields, { setStatus, setSubmitting, resetForm }) => {

      console.log(fields)

      const _hoursofoperation = Object.assign({}, baseHoursofoperation)
      if(hoursofoperation.hasOwnProperty('monday')) _hoursofoperation['monday'] = hoursofoperation['monday']
      if(hoursofoperation.hasOwnProperty('tuesday')) _hoursofoperation['tuesday'] = hoursofoperation['tuesday']
      if(hoursofoperation.hasOwnProperty('wednesday')) _hoursofoperation['wednesday'] = hoursofoperation['wednesday']
      if(hoursofoperation.hasOwnProperty('thursday')) _hoursofoperation['thursday'] = hoursofoperation['thursday']
      if(hoursofoperation.hasOwnProperty('friday')) _hoursofoperation['friday'] = hoursofoperation['friday']
      if(hoursofoperation.hasOwnProperty('saturday')) _hoursofoperation['saturday'] = hoursofoperation['saturday']
      if(hoursofoperation.hasOwnProperty('sunday')) _hoursofoperation['sunday'] = hoursofoperation['sunday']
      


      if(!scopedDay) { 
          Object.keys(_hoursofoperation).map(x => {
            _hoursofoperation[x]['opens_at'] = Moment.duration(Moment(fields.opens_at, 'HH:mm').diff(Moment('00:00', 'HH:mm'))).asMinutes()
            _hoursofoperation[x]['closes_at'] = Moment.duration(Moment(fields.closes_at, 'HH:mm').diff(Moment('00:00', 'HH:mm'))).asMinutes()
            _hoursofoperation[x]['isEnabled'] = fields.isEnabled
        })
      } else {
        _hoursofoperation[scopedDay.day] = {
            opens_at: Moment.duration(Moment(fields.opens_at, 'HH:mm').diff(Moment('00:00', 'HH:mm'))).asMinutes(),
            closes_at: Moment.duration(Moment(fields.closes_at, 'HH:mm').diff(Moment('00:00', 'HH:mm'))).asMinutes(),
            isEnabled: fields.isEnabled
        }
      }

   

      setActionsLoading(true)
      dispensaryService.update(dispensaryId, { hoursofoperation: _hoursofoperation })
      .then(data => {
          resetForm({});
          Swal.fire(
            'Done!',
            'The item has been saved!',
            'success'
          )
          fetchDispensary()
          setActionsLoading(false)
          onHide()
    
      })
      .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.message || error.response.data.errors[0]['message'],
          })
          setActionsLoading(false)
      })
    }

   
    return (
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
      <EditDialogHeader 
        item={initItem} 
        actionsLoading={actionsLoading} 
      />
      <EditForm
        saveCustomer={saveItem}
        actionsLoading={actionsLoading}
        initialValues={initItem}
        onHide={onHide}
        setActionsLoading={setActionsLoading}
      />  
      </Modal>
    )
    
}
