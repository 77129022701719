import React, { useState, useEffect } from "react";
import {LicensesWidget, MixedWidget1, BaseTablesWidget1, SubscriptionWidget, ReviewsWidget,  ListsWidget10, BestSaleWidget, LastestOrdersWidget, RecentReviews, MixedWidget10, MixedWidget11, MixedWidget12, TilesWidget1, TilesWidget3, TilesWidget10, TilesWidget11, TilesWidget12, TilesWidget13, TilesWidget14, MyLocationWidget, DispensaryProductsCountTile, DispensaryFulfilledOrdersCountTile, StatsWidget10, IncomeChartWidget, StatsWidget12, BaseTablesWidget2, BaseTablesWidget6, UserNotesWidget } from "../widgets";
import { HoursOfOperationTable } from '../widgets/advance-tables/HoursOfOperationTable'
import { useSelector, useDispatch, connect } from 'react-redux'
import Swal from 'sweetalert2'
import { accountService, brandService, dispensaryService, subscriptionService } from '../../../app/services'
import { getUserByToken } from "../../../app/modules/Auth/_redux/authCrud";
import * as auth from "../../../app/modules/Auth/_redux/authRedux";
import { useHistory } from "react-router";
import moment from "moment";
import { Link } from "react-router-dom";
import { BestProducts } from "../widgets/base-tables/BestProducts";
import { CitiesWidget } from "../widgets/base-tables/CitiesWidget";
import { SponsoredZoneAdmin } from "../widgets/maps/SponsoredZoneAdmin";
import { ImagesWidget } from "../widgets/maps/ImagesWidget";
import { openUploadBox } from "../widgets/cloudinary/cloudinary";
import { brands_image_preset } from "../../../app/helpers/imageRepositories";

function BrandDashboard(props) {

    const user = useSelector(state => state.auth.user)

    const dispensaryInitialState = {}
    const [ dispensary, setDispensary ] = useState({dispensaryInitialState})

    const [ range, setRange ] = useState('3D')
    const [ rangeDates, setRangeDates ] = useState({})

    const fetchedInitialState = false
    const [ fetched, setFetched ] = useState(fetchedInitialState)

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })

    function removePicture(type){
        swalWithBootstrapButtons.queue([{
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            showLoaderOnConfirm: true,
            reverseButtons: true,
            preConfirm: () => { 
              return brandService.update(dispensary._id, {[type]: ''}).then(data => {
                Swal.fire(
                  'Done!',
                  'The item has been deleted!',
                  'success'
                )
                fetchBrand()
              })
              .catch(error => {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data.message || error.response.data.errors[0]['message'],
                  })
              })
            } 
          }])
    }

    useEffect(() => {
        switch (range) {
            case '3D':
                setRangeDates({
                    start: moment().subtract(3, 'days').format('YYYY-MM-DD'),
                    end: moment().format('YYYY-MM-DD')
                })
                break;
            case '1W':
                setRangeDates({
                    start: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
                    end: moment().format('YYYY-MM-DD')
                })
                break;
            case '1M':
                setRangeDates({
                    start: moment().subtract(1, 'months').format('YYYY-MM-DD'),
                    end: moment().format('YYYY-MM-DD')
                })
                break;
            case '3M':
                setRangeDates({
                    start: moment().subtract(3, 'months').format('YYYY-MM-DD'),
                    end: moment().format('YYYY-MM-DD')
                })
                break;
        
            default:
                break;
        }
    }, [range])

    const dispatch = useDispatch()
    
    const checkUser = async () => {

        try {

            let res = await getUserByToken()
        
            if(res.data.brand){
                dispatch(props.requestUser);
            }

        } catch (er) {



        }

    }
    const history = useHistory()
    // useEffect(() => {
    //     if(!user.brand){
    //         checkUser()
    //     }
    // }, [user])

    const openUpdateMailDialog = () => {

        
        Swal.fire({
            title: 'Submit your Email',
            input: 'email',
            inputValue: dispensary.email,
            inputAttributes: {
              autocapitalize: 'off',
            },
            showCancelButton: true,
            confirmButtonText: 'Submit',
            showLoaderOnConfirm: true,
            preConfirm: (email) => {
              return brandService.update(dispensary._id, {email:email})
                .then(response => {
                  
                    fetchBrand()
                  
               
                })
                .catch(error => {
                  Swal.showValidationMessage(
                    `Request failed: ${error}`
                  )
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: `Success`,
                text:'Email updated'
              })
            }
          })
    }


    const openUpdateDescDialog = () => {
        console.log(dispensary.description)
        Swal.fire({
            title: 'Submit your Description',
            input: 'textarea',
            inputValue: dispensary.description,
            inputAttributes: {
              autocapitalize: 'off',
            },
            showCancelButton: true,
            confirmButtonText: 'Submit',
            showLoaderOnConfirm: true,
            preConfirm: (description) => {
              return brandService.update(dispensary._id, {description:description})
                .then(response => {
                  
                    fetchBrand()
                  
               
                })
                .catch(error => {
                  Swal.showValidationMessage(
                    `Request failed: ${error}`
                  )
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: `Success`,
                text:'Description updated'
              })
            }
          })
    }


    const fetchBrand = async () => {

        try {
            {console.log(props)}
            const res =await brandService.getById(props.business_id)
            setDispensary(res.data);
            setFetched(true)

        } catch (er){
            console.log(er)
        }
    
    }

    useEffect(() => {
        if(props.business_id){
            fetchBrand();
            fetchSubs()
        }
    }, [props.business_id])
    
    const [ subscription, setSubscription ] = useState(null)
    const [ loadingSubscription, setLoadingSubscription ] = useState(true)


    const fetchSubs = async () => {
        try {
            setLoadingSubscription(true)
            let subscription = await subscriptionService.getById(props.business_id, '?sortField=createdAt&sortOrder=desc&page=0&size=1')
            if(subscription.data.totalData.length > 0){
                setSubscription(subscription.data.totalData[0])
            } else {
                setSubscription(null)
            }
            setLoadingSubscription(false)
        } catch(err) {
            console.log(err)
        }
    }

    function showWidget(aspectRatio, mandatoryCropping, image_preset, type, sources=['local']){
        openUploadBox(url => {
            brandService.update(dispensary._id, {[type]: url})
                .then(()=>fetchBrand())
        }, aspectRatio, mandatoryCropping, image_preset, sources)
    }

    if(!fetched) return <div style={{zIndex:"5"}} className="overlay-layer bg-transparent" style={{width: "100%", zIndex: "5", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}><div className="spinner spinner-lg spinner-success" /></div>
    
    return <>
       {console.log(props)}

    {/* begin::Row */}
    {
        dispensary.status === 'UNPUBLISHED' &&
        <div className='row'>
            <div className="col-12">
                <div class="alert d-flex align-items-center justify-content-between alert-danger" role="alert">
                    { 
                        dispensary.status === 'UNPUBLISHED' && <>
                        Your dispensary has unpublished status, please fill all the requested information to change the status. 
                        <Link to={"/businesses/"+dispensary._id+"/settings"} className='btn btn-light'> Go to settings</Link>
                    </>
                    }
                </div>
            </div>

        </div>
    }
    <div 
        className="row"
        style={{    
            position: 'sticky',
            top: '50px',
            zIndex: '1'
        }}
    >
        <div className="col-xl-12" style={{marginBottom:'25px'}}>
            <div className={`card card-custom h-100`}>
                <div className='card-body d-flex justify-content-between'>
                    <div className='d-flex'>
                        <img src={dispensary.logo} style={{height:'48px', width:'48px', borderRadius:'100%'}}></img>
                        <div className='ml-3 d-flex flex-column'>
                            <span className='text-bold' style={{fontSize:'18px', fontWeight:'600'}}>{dispensary.name}</span>
                            <span style={{fontSize:'12px', fontWeight:'500'}}><strong>BRAND</strong></span>
                            <span>{dispensary.email} </span>
                            <span className="mt-2">
                                <button onClick={()=>openUpdateMailDialog()} className='btn btn-sm btn-success'>Update email</button>
                                <button onClick={()=>openUpdateDescDialog()} className='btn btn-sm btn-success ml-2'>Update description</button>
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className="btn-group" role="group" aria-label="Basic example">
                        <button type="button" onClick={()=>setRange('3D')} className={`btn btn-success ${range === '3D' ? 'active' : ''}`}>3D</button>
                        <button type="button" onClick={()=>setRange('1W')} className={`btn btn-success ${range === '1W' ? 'active' : ''}`}>1W</button>
                        <button type="button" onClick={()=>setRange('1M')} className={`btn btn-success ${range === '1M' ? 'active' : ''}`}>1M</button>
                            <button type="button" onClick={()=>setRange('3M')} className={`btn btn-success ${range === '3M' ? 'active' : ''}`}>3M</button>
                         
                         
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="row">
        {/* <div className="col-xl-8">
            <IncomeChartWidget
                // className="gutter-b"
                symbolShape="circle"
                baseColor="info"
                business_id={props.business_id}
                rangeDates={rangeDates}
            />  
        </div> */}
        <div className='col-xl-4'>
            <ReviewsWidget 
                business_id={props.business_id}
                rangeDates={rangeDates}
                dispensary={dispensary}
            />
        </div>
        <div className='col-xl-8'>
            <BestProducts className="card-stretch gutter-b" brand = { props.business_id }/>
        </div>
    </div> 
    <div className="row">
        
        <div className='col-xl-12' style={{marginTop:'2rem'}}>
        <SubscriptionWidget 
                    className="card-stretch gutter-b" 
                  
                    dispensary={dispensary} 
                    business_id={props.business_id} 
                    subscription={subscription}
                    loadingSubscription={loadingSubscription}
            />
        </div> 
    </div>
    <div className="row">
        {/* <div className='col-xl-6' style={{marginTop:'50px'}}>
            <CitiesWidget className="card-stretch gutter-b" _cities={dispensary.cities} fetchBrand={fetchBrand}/>
        </div> */}
        <div className='col-12'>
        <div class={`card card-custom card-stretch gutter-b pt-8 pb-8 mt-8`}>
							 
            <SponsoredZoneAdmin 
                businessId={dispensary._id}
                businessType={'BRAND'}
                // getMapUrl={getMapUrl}
                // city={dispensary.city}
                // fetchDispensary={fetchBrand} 
                // selectedLocation={dispensary.location}
            />
            </div>
           
        </div>
    </div>
    <br />
    <br />
    <div className="row">
        <div className="col-12">
            <ImagesWidget 
                bannerSize={3.7}
                enableStorefront = { false }
                disableSliderImage = { true }
                showWidget = { showWidget } 
                logo = { dispensary.logo } 
                picture = { dispensary.picture } 
                banner = { dispensary.banner } 
                storefront_picture = { dispensary.storefront_picture } 
                image_preset = {brands_image_preset}
                removePicture = {removePicture}
                className = "card-stretch gutter-b" 
                dispensaryId={dispensary._id}
            />
        </div>
    </div>
</>
}
export default connect(null, auth.actions)(BrandDashboard);