/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import moment from 'moment'
import {EditDialog} from './HoursOfOperationEdit/EditDialog'
import Spinner from '../../spinner/Spinner'
import _ from "lodash";
import tz from 'moment-timezone'
import Swal from 'sweetalert2'
import { dispensaryService } from "../../../../app/services";



export function HoursOfOperationTable({ className, hoursofoperation, fetchDispensary, fetched, dispensaryId=undefined }) {

  const showEditModalInitialState = false
  const setScopedDayInitialState = null

  const [showEditModal, setShowEditModal] = useState(showEditModalInitialState)
  const [ scopedDay, setScopedDay ] = useState(setScopedDayInitialState)
  const [ isFullTime, setIsFullTime ] = useState(false)

  const enableFullTime = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success mr-1 ml-1',
        cancelButton: 'btn btn-danger mr-1 ml-1'
      },
      buttonsStyling: false
    })

    const fullDays = {hoursofoperation:{
      "sunday": {
        "opens_at": 0,
        "closes_at": 1439,
        "isEnabled": true,
      },
      "monday": {
        "opens_at": 0,
        "closes_at": 1439,
        "isEnabled": true,
      },
      "tuesday": {
        "opens_at": 0,
        "closes_at": 1439,
        "isEnabled": true,
      },
      "wednesday": {
        "opens_at": 0,
        "closes_at": 1439,
        "isEnabled": true,
      },
      "thursday": {
        "opens_at": 0,
        "closes_at": 1439,
        "isEnabled": true,
      },
      "friday": {
        "opens_at": 0,
        "closes_at": 1439,
        "isEnabled": true,
      },
      "saturday": {
        "opens_at": 0,
        "closes_at": 1439,
        "isEnabled": true,
      }
    }}
    
    swalWithBootstrapButtons.queue([{
      title: 'Enable 24/7',
      text: "Do you want to enable 24/7?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, please',
      cancelButtonText: 'No',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: () => { 

        return dispensaryService.update(dispensaryId, fullDays).then(data => {
          Swal.fire(
            'Done!',
            'The item has been deleted!',
            'success'
          )
          fetchDispensary()
        })
        .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message || error.response.data.errors[0]['message'],
            })
        })
      } 
    }])
  }

  useEffect(() => {
    if(hoursofoperation && hoursofoperation.length > 0){
      let acum = 0
    Object.keys(hoursofoperation).map(x => {
      acum += hoursofoperation[x]['opens_at']
      acum += hoursofoperation[x]['closes_at']
    })
    if(acum === 10073){
      setIsFullTime(true)
    } else {
      setIsFullTime(false)
    }
    }
    
  }, [hoursofoperation])


  
  // if(!hoursofoperation) return <Spinner />
  return (
    <div className={`card card-custom ${className}`}>
      
   
      { showEditModal && <EditDialog dispensaryId={dispensaryId} show={showEditModal} onHide={()=>{setShowEditModal(false)}} scopedDay={scopedDay} fetchDispensary={fetchDispensary} hoursofoperation={hoursofoperation} /> }
      {/* begin::Header */}
      {
        (fetched &&  (hoursofoperation)) &&
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Hours of Operation
              </span>
              <span className="text-muted mt-3 font-weight-bold font-size-sm">
                days of the week
              </span>
            </h3>
             <div className="card-toolbar">
                <button
                  onClick={() => {setShowEditModal(true); setScopedDay(null)}}
                  className="btn btn-success font-weight-bolder font-size-sm"
                >
                  Update all
                </button>
                <button
                  onClick={() => {enableFullTime()}}
                  className="btn btn-success font-weight-bolder font-size-sm ml-2"
                  disabled={isFullTime}
                >
                  Enable 24/7
                </button>
              </div>

          </div> 
      }
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body py-0">
        {/* begin::Table */}
        {
        
          (fetched 
            && (!_.isEmpty(hoursofoperation))
            && hoursofoperation.hasOwnProperty('monday')
            && hoursofoperation.hasOwnProperty('tuesday')
            && hoursofoperation.hasOwnProperty('wednesday')
            && hoursofoperation.hasOwnProperty('thursday')
            && hoursofoperation.hasOwnProperty('friday')
            && hoursofoperation.hasOwnProperty('saturday')
            && hoursofoperation.hasOwnProperty('sunday')
          ) ? (
            <div className="table-responsive">
              <table
                className="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_1"
              >
                <thead>
                  <tr className="text-left">
                    <th >
                      DAY
                    </th>
                    <th >OPENS AT</th>
                    <th >CLOSES AT</th>
                    <th >STATUS</th>
                    <th className="pr-0 text-right" >
                      action
                    </th>
                  </tr>
                </thead>
                <tbody>
                {console.log(_.isObject(hoursofoperation))}
                {console.log(hoursofoperation)}
                {
                  hoursofoperation 
                  && typeof hoursofoperation === 'object' 
                  && !_.isEmpty(hoursofoperation) 
                  && hoursofoperation.hasOwnProperty('monday')
                  && hoursofoperation.hasOwnProperty('tuesday')
                  && hoursofoperation.hasOwnProperty('wednesday')
                  && hoursofoperation.hasOwnProperty('thursday')
                  && hoursofoperation.hasOwnProperty('friday')
                  && hoursofoperation.hasOwnProperty('saturday')
                  && hoursofoperation.hasOwnProperty('sunday')
                  && Object.keys(hoursofoperation).map( (day, index) => (
                    <tr key={index}>
                      <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {day.toUpperCase()}
                        </span>
                      </td>
                      <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          { moment.utc().startOf('day').add(hoursofoperation[day].opens_at, 'minutes').format('h:mm A') }
                        </span>
                      </td>
                      <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        { moment.utc().startOf('day').add(hoursofoperation[day].closes_at, 'minutes').format('h:mm A') }
                        </span>
                      </td>
                      <td>
                          {(hoursofoperation[day].isEnabled==1)?<span className="badge badge-success">Active</span>:<span className="badge badge-warning">Disabled</span>}
                      </td>
                      <td className="pr-0 text-right">
                        <button
                          type="button"
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                          onClick={()=>{setScopedDay({...hoursofoperation[day], day}); setShowEditModal(true)}}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Write.svg"
                              )}
                            ></SVG>
                          </span>
                        </button>
                      </td>
                    </tr>
                ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              flexDirection: 'column',
            }}>
              <h4 className='font-size-h4'>Please fill hours of operation table</h4>
              <button onClick={()=>{setShowEditModal('true');setScopedDay(null)}} className='btn btn-info'>Update hours of operation</button>
            </div>
          ) 
        }
        {/* end::Table */}
      </div>
      {/* end::Body */}
    </div>
  );
}
