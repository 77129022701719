import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { brandService } from '../../../../app/services'
import Swal from 'sweetalert2'
import { shallowEqual, useSelector } from "react-redux";
import {ModalProgressBar} from "../../controls";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Input,
  Select,
  DatePickerField,
} from "../../../../_metronic/_partials/controls";
import * as Yup from "yup";
import { connect, useDispatch } from 'react-redux'
import * as auth from "../../../../app/modules/Auth/_redux/authRedux";
import { toAbsoluteUrl } from "../../../_helpers";
import _ from 'lodash'
import { openUploadBox } from "../../../../_metronic/_partials/widgets/cloudinary/cloudinary";
import { brands_image_preset } from "../../../../app/helpers/imageRepositories";

function LandingUpdateDialog(props) {

    const actionsLoadingInitialState = false
    const [ actionsLoading, setActionsLoading ] = useState(actionsLoadingInitialState)
    const [ image, setImage ] = useState(props.image ? props.image : false)

    function showWidget(aspectRatio, mandatoryCropping, image_preset, type, sources=['local']){
        openUploadBox(url => {
            setImage(url)
        }, aspectRatio, mandatoryCropping, image_preset, sources)
    }

    const updateBusiness = (fields) => {
      brandService.update(props.entityId, fields)
      .then(() => {
        Swal.fire(
          'Done!',
          'The item has been saved!',
          'success'
        )
        props.onHide()
        props.fetchData()
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message || error.response.data.errors[0].message,
        })
      })
    }

    const onSubmit = (fields) => {
      setActionsLoading(true)
      if(props.fieldPicture && !image){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Please upload a picture',
          })
          setActionsLoading(false)
      } else if(props.fieldPicture && image){
        fields[props.fieldPicture.name] = image
        updateBusiness(fields)
      } else if(!props.fieldPicture){
        updateBusiness(fields)
      }
    }
    
    return (
        <Modal
          size="lg"
          show={props.show}
          onHide={props.onHide}
          aria-labelledby="example-modal-sizes-title-lg"
        >
            <>
              {actionsLoading && <ModalProgressBar />}
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">{props.widgetTitle}</Modal.Title>
              </Modal.Header>
                <div
                    className='overlay overlay-block cursor-default modal-body'
                >
                    <Formik
                      enableReinitialize={true}
                      initialValues={props.initialValues}
                      validationSchema={props.EditSchema}
                      onSubmit={onSubmit}
                    >
                         {({ errors, touched, setFieldValue, handleSubmit, isSubmitting, setFieldTouched, values, handleChange, handleReset }) => (
                            <Form className="form form-label-right">
                                <div className="form-group row">
                                    {
                                      props.fieldTitle && (
                                        <div className='col-12'>
                                            {/* <label>{props.fieldTitle.label}</label> */}
                                            <Field
                                              type="text"
                                              name={props.fieldTitle.name}
                                              component={Input}
                                              placeholder={props.fieldTitle.label}
                                              label={props.fieldTitle.label}
                                              className={'form-control' + (errors[props.fieldTitle.name] && touched[props.fieldTitle.name] ? ' is-invalid' : '')}
                                            />
                                        </div>
                                      )
                                    }
                                    {
                                      props.fieldText && (
                                        <div className='col-12 mt-4'>
                                            <label>{props.fieldText.label}</label>
                                            <Field
                                              name={props.fieldText.name}
                                              as="textarea"
                                              className={'form-control' + (errors[props.fieldText.name] && touched[props.fieldText.name] ? ' is-invalid' : '')}
                                            />
                                        </div>
                                      )
                                    }
                                    {
                                      props.fieldPicture && (
                                        <div className='col-xl-3 mt-4'>
                                          <div className='d-flex flex-column'>
                                            <div class="d-flex pb-4 flex-column flex-grow-1 mr-2">
                                              <label>Image</label>
                                              <img 
                                                src={ image ? image : props.picture ? props.picture : toAbsoluteUrl("/media/default-logo.jpg") } 
                                                style={{height:"100%"}} 
                                                className='pb-4'
                                              />
                                              {
                                                props.picture || image
                                                ? <div className='d-flex justify-content-end'>
                                                    <button type='button' className='btn btn-success mr-1' onClick={()=>showWidget(1, true, brands_image_preset, 'logo')}>Update</button>
                                                    {/* <button type='button' className='btn btn-danger' onClick={()=>setImage('false')}>Remove</button> */}
                                                  </div> 
                                                : <div className='d-flex justify-content-end'>
                                                    <button type='button' className='btn btn-success' onClick={()=>showWidget(1, true, brands_image_preset, 'logo')}>Upload</button>
                                                  </div>
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <button
                                      type="button"
                                      className='btn btn-light mr-3'
                                      onClick={()=>handleReset()}
                                    >Reset</button>
                                    <button
                                      type="submit"
                                      className='btn btn-primary'
                                    >Save</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </>
        </Modal>
    )
    
}

export default LandingUpdateDialog;