import React, { useEffect, useRef, useState } from 'react'
import "mapbox-gl/dist/mapbox-gl.css";
import { mapboxService } from '../../../../app/services';
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import _, { debounce } from 'lodash'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export const PlacesList = ({ 
    actionsLoading,
    setActionsLoading,
    setSelectedPlace,
    address,
    address_line_2,
    addresszip,
    city,
    state
}) => {

    const [ features, setFeatures ] = useState([])
    const [ featuresError, setFeaturesError ] = useState('')
    const [ search, setSearch ] = useState('')

    const debouncedInput = useRef(_.debounce(value => searchLocation(value), 1000)).current;

    useEffect(() => {
        if(address.length>0){
            let text = `${address} ${address_line_2 ? address_line_2 : ''} ${city ? city : ''} ${state ? state : ''} ${addresszip ? addresszip : ''}`
            setSearch(text)
            searchLocation(text)
        }
    }, [address])

    const searchLocation = async (value) => {
        try{
            if(value.length>0){
                setActionsLoading(true)
                let response = await mapboxService.geocoding(value)
                setFeatures(response.data.features)
                setActionsLoading(false)
                if(!response.data.features.length > 0){
                    setFeaturesError({message: 'No places found'})
                }
            } else {
                setFeatures({features: []})
            }
        } catch(err){
            console.log(err.message)
        }
    }

    return (
        <>
            <div className='pb-4'>
                <div class="font-size-sm text-muted mt-2">Search location</div>
                <input 
                    type='search' 
                    value={search}
                    disabled={actionsLoading}
                    onChange={(e)=>{
                        setSearch(e.target.value)
                        debouncedInput(e.target.value)
                    }} 
                    className='form-control'
                    placeholder='Search a your exact location or a POI close to you'
                >
                </input>
            </div>
            {
                features.length > 0 ? (
                    <>
                        <p className='mt-2 font-weight-bolder'>Select the nearest place to you or edit the address bellow if you can't find a near place:</p>
                        <ul className='geocoding-results'>
                        {
                             features.map((x, i) =>
                                <li 
                                    key={i} 
                                    onClick={()=>setSelectedPlace(x)}
                                >
                                    <i className="flaticon-map-location"></i>
                                    {x.place_name}
                                </li>
                            )
                        }
                        </ul>
                    </>
                ) : (
                    <>
                    {
                        featuresError.length > 0 && <div className='pl-3 pr-3'>  
                            <h4 className='location-messages'>{featuresError}</h4>
                            <p><center>Adjust the search text and try again</center></p>
                        </div>
                    }
                    </>
                )
            }
        </>
    )
}