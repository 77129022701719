import axios from "axios";
import { getPublicApi, getPrivateApi } from '../../../helpers/config'


const LOGIN_URL = `${getPublicApi}/auth/login`;
const REGISTER_URL = `${getPublicApi}auth/signup`;
const VERIFY_MAIL_URL = `${getPublicApi}auth/verify/:token`;
const REQUEST_VERIFY_MAIL_URL = `${getPublicApi}auth/request-verify-mail`;
const REQUEST_PASSWORD_URL = `${getPublicApi}auth/forgot-password`;
const VALIDATE_TOKEN_URL = `${getPublicApi}auth/validate-password-reset-token`;
const RESET_PASSWORD_URL = `${getPublicApi}auth/reset-password`;

export const ME_URL = `${getPrivateApi}users/me`;

export function login(email, password) {
	return axios.post(LOGIN_URL, { email, password });
}

export function register(values) {
	return axios.post(REGISTER_URL, values);
}

export function requestPassword(email) {
	return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function checkVerifyMailToken(token) {
	return axios.post(VERIFY_MAIL_URL.replace(":token", token));
}

export function requestVerifyMail(body) {
	return axios.post(REQUEST_VERIFY_MAIL_URL, body);
}


export function validateToken(token){
	return axios.post(VALIDATE_TOKEN_URL+'?token='+token)
}

export function resetPassword(token,password){
	return axios.post(RESET_PASSWORD_URL, { token, password })
}

export function getUserByToken() {
	// Authorization head should be fulfilled in interceptor.
	return axios.get(ME_URL);
}
