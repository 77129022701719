export function DesktopNotification(body,icon,title){
    console.log({body,icon,title})
    if (("Notification" in window)) {
        if (Notification.permission === "granted") {
            var options = {
                body: body,
                icon: icon,
            }
            new Notification(title,options);
        }
    }
}
