/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler, DropdownMenu4 } from "../../dropdowns";
import { Link } from 'react-router-dom'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import _ from 'lodash'
import { dispensaryService } from "../../../../app/services";
import Swal from 'sweetalert2'
import { DispensaryInfoDialog } from "../dispensary-info-modal/DispensaryInfoDialog";

export function MyLocationWidget({ className, iconColor, dispensary, mapURL, disableTaxes, dispensaryId=undefined, user, fetchDispensary, business_id }) {
  console.log(business_id)
  const [ showFormModal, setShowFormModal ] = useState(false)
 
  const changeName = (field) => {
    Swal.fire({
      title: 'Submit the new name of your business',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Send',
      showLoaderOnConfirm: true,
      preConfirm: (name) => {

        let request = {
          ...dispensary.request,
          name,
          activeRequest: true
        }

        

        return dispensaryService.update(business_id, {request})
          .then(response => {
            return 'Sent'
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `Request sent`,
        })
      }
    })
  }



  // const changeAddress = () => {
  //   Swal.fire({
  //     title: 'Submit the new address of your business',
     
  //     html:
  //               '<label for="address">Address</label><input name="address" id="address" class="swal2-input">' +
  //               '<label for="address_line_2">Address line 2</label><input name="address_line_2" id="address_line_2" class="swal2-input">',
  //     showCancelButton: true,
  //     confirmButtonText: 'Send',
  //     showLoaderOnConfirm: true,
  //     preConfirm: (name) => {
  //       let address = document.getElementById('address').value
  //       let address_line_2 = document.getElementById('address_line_2').value
       
  //       let request = {
  //         ...dispensary.request,
  //         address,
  //         address_line_2,
  //         activeRequest: true
  //       }
  //       if(!address_line_2 || address_line_2.length === 0){
  //         delete request.address_line_2
  //       }

  //       return dispensaryService.update(undefined, {request})
  //         .then(response => {
  //           return 'Sent'
  //         })
  //         .catch(error => {
  //           Swal.showValidationMessage(
  //             `Request failed: ${error.response.data.message || error.response.data.errors[0].message}`
  //           )
  //         })
  //     },
  //     allowOutsideClick: () => !Swal.isLoading()
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       Swal.fire({
  //         title: `Request sent`,
  //       })
  //     }
  //   })
  // }


  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
                <div className="font-weight-bolder">{dispensary.name}</div>
              <div className="font-size-sm text-muted mt-2">{dispensary.type}</div>
            </div>
          </div>
          
          <div className="card-toolbar">
            { 
              showFormModal && (
                <DispensaryInfoDialog  
                  dispensary={dispensary}
                  user={user}
                  dispensaryId={business_id}
                  show={showFormModal}
                  onHide={()=>{
                    setShowFormModal(false)
                    fetchDispensary && fetchDispensary()
                  }}
                />
              )
            }
            <button onClick={()=>setShowFormModal(true)} className="btn btn-success font-weight-bolder font-size-sm mr-2">
                <><i className="flaticon-edit"></i>Update info</>
            </button>
            {
              user.type != 'ADMIN' &&  <button className='btn btn-primary mr-2' onClick={()=>changeName('name')}>
                Change name
              </button>
            }
            

            {/* <button className='btn btn-primary' onClick={()=>changeAddress('address')}>
              Change address
            </button> */}
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0">
          <div style={{paddingLeft:"2.25rem", paddingRight:"2.25rem"}}>
              <div className='row'>
                <div className='col-xl-3'>
                  <div className='d-flex flex-column'>
                    <img 
                      src={dispensary.logo ? dispensary.logo : dispensary.picture ? dispensary.picture : toAbsoluteUrl("/media/default-logo.jpg")} 
                      style={{width:"100%", height:"100%"}} 
                      className='pb-4'
                    />
                  </div>
                </div>
                <div className='col-xl-3'>
                  <div class="d-flex mb-9 p-5 flex-column">
                    {/* <div class="d-flex pb-4 flex-column flex-grow-1 mr-2">
                      <p class="font-weight-bold text-dark-75 font-size-lg mb-1 pt-4">{dispensary.city.name}, {dispensary.city.state.name} {dispensary.addresszip}</p>
                      <p class="text-muted font-weight-bold">{dispensary.address}</p>
                      {
                        dispensary.address_line_2 && <p class="text-muted font-weight-bold">{dispensary.address_line_2}</p>
                      }
                    </div> */}
                    <div class="d-flex pb-4 flex-column flex-grow-1 mr-2">
                      <span class="font-weight-bold text-dark-75 font-size-lg mb-1">Name</span>
                      <span class="text-muted font-weight-bold">{dispensary.name || 'Not found'}</span>
                    </div>
                    <div class="d-flex pb-4 flex-column flex-grow-1 mr-2">
                      <div class="d-flex flex-column flex-grow-1 mr-2">
                        <span class="font-weight-bold text-dark-75 font-size-lg mb-1">Business Email</span>
                        <span class="text-muted font-weight-bold">{dispensary.email || 'Not found'}</span>
                      </div>
                    </div>
                    <div class="d-flex pb-4 flex-column flex-grow-1 mr-2">
                      <div class="d-flex flex-column flex-grow-1 mr-2">
                        <span class="font-weight-bold text-dark-75 font-size-lg mb-1">Phone</span>
                        <span class="text-muted font-weight-bold">{dispensary.phone || 'Not found'}</span>
                      </div>
                    </div>
                    {/* {
                      !disableTaxes && <div class="d-flex pb-4 flex-column flex-grow-1 mr-2">
                        <div class="d-flex flex-column flex-grow-1 mr-2">
                          <span class="font-weight-bold text-dark-75 font-size-lg mb-1">Taxes</span>
                          <span class="text-muted font-weight-bold">{dispensary.taxes || 0 }</span>
                        </div>
                      </div>
                    } */}
                  </div>
                </div>
                <div className='col-xl-3'>
                  <div class="d-flex mb-9 p-5 flex-column">
                    <div class="d-flex pb-4 flex-column flex-grow-1 mr-2">
                      <div class="d-flex flex-column flex-grow-1 mr-2">
                        <span class="font-weight-bold text-dark-75 font-size-lg mb-1">Website</span>
                        <span class="text-muted font-weight-bold">{dispensary.website ? dispensary.website : 'Not found' }</span>
                      </div>
                    </div>
                    <div class="d-flex pb-4 flex-column flex-grow-1 mr-2">
                      <div class="d-flex flex-column flex-grow-1 mr-2">
                        <span class="font-weight-bold text-dark-75 font-size-lg mb-1">Twitter</span>
                        <span class="text-muted font-weight-bold">{dispensary.twitter ? dispensary.twitter.replace('https://twitter.com/', '@') : 'Not found'}</span>
                      </div>
                    </div>
                    <div class="d-flex pb-4 flex-column flex-grow-1 mr-2">
                      <div class="d-flex flex-column flex-grow-1 mr-2">
                        <span class="font-weight-bold text-dark-75 font-size-lg mb-1">Facebook</span>
                        <span class="text-muted font-weight-bold">{dispensary.facebook ? dispensary.facebook.replace('https://www.facebook.com', '') : 'Not found' }</span>
                      </div>
                    </div>
                    <div class="d-flex pb-4 flex-column flex-grow-1 mr-2">
                      <div class="d-flex flex-column flex-grow-1 mr-2">
                        <span class="font-weight-bold text-dark-75 font-size-lg mb-1">Instagram</span>
                        <span class="text-muted font-weight-bold">{dispensary.instagram ? dispensary.instagram.replace('https://www.instagram.com/', '@') : 'Not found' }</span>
                      </div>
                    </div>
                  </div>
                </div>

                {
                  !_.isEmpty(dispensary.amenities) && <div className='col-xl-3'>
                    <div class="d-flex mb-9 p-5 flex-column">
                      <h3 className='pb-4'>Amenities</h3>
                      
                      {
                        dispensary.amenities.isCreditCardAccepted && <div class="d-flex align-items-center pb-4 flex-grow-1 mr-2">
                          <i className='fa fa-credit-card pr-3' style={{fontSize: '1.8rem', color:'black'}}></i>
                          <span class="text-muted font-weight-bold">Credit Card</span>
                        </div>
                      }
                      {
                        dispensary.amenities.isAtmAvailable && <div class="d-flex align-items-center pb-4 flex-grow-1 mr-2">
                          <i className='fa fa-money-bill-wave-alt pr-3' style={{fontSize: '1.8rem', color:'black'}}></i>
                          <span class="text-muted font-weight-bold">ATM</span>
                        </div>
                      }
                      {
                        dispensary.amenities.isSecurityAvailable && <div class="d-flex align-items-center pb-4 flex-grow-1 mr-2">
                          <i className='fa fa-shield-alt pr-3' style={{fontSize: '1.8rem', color:'black'}}></i>
                          <span class="text-muted font-weight-bold">Security</span>
                        </div>
                      }
                      {
                        dispensary.amenities.isAccessible && <div class="d-flex align-items-center pb-4 flex-grow-1 mr-2">
                          <i className='fa fa-wheelchair pr-3' style={{fontSize: '1.8rem', color:'black'}}></i>
                          <span class="text-muted font-weight-bold">Accessible</span>
                        </div>
                      }
                      {/* {
                        dispensary.amenities.curbsidePickup && <div class="d-flex align-items-center pb-4 flex-grow-1 mr-2">
                          <i className='fa fa-car pr-3' style={{fontSize: '1.8rem', color:'black'}}></i>
                          <span class="text-muted font-weight-bold">Curbside Pickup</span>
                        </div>
                      } */}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          {/* end::Items */}
        </div>
        {/* end::Body */}
     
      {/* end::Tiles Widget 1 */}
    </>
  );
}

