/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef } from "react";

export const DropdownCustomToggler2 = forwardRef((props, ref) => {
  return (
    <a
      ref={ref}
      className="navi-link w-100"
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      {props.children}
    </a>
  );
});
