import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MapDialogHeader } from './MapDialogHeader'
import { dispensaryService } from '../../../../app/services'
import { AreaOfCoverageUpdateMap } from './AreaOfCoverageUpdateMap'
import Swal from 'sweetalert2'
import { AddPinpointToMap } from './AddPinpointToMap'

export function AddPinpointToMapModal({ 
    location,
    show, 
    onHide, 
    fetchDispensary, 
    coverage, 
    type, 
    address, 
    addresszip, 
    city, 
    state, 
    locations,
    address_line_2,
    businessType,
    editingPoint,
    deletePoint,
    dispensaryId,
}) {

    const actionsLoadingInitialState = false
    const [ actionsLoading, setActionsLoading ] = useState(actionsLoadingInitialState)

    const saveItem = (fields) => {
      
        setActionsLoading(true)
        dispensaryService.update(dispensaryId, fields).then(data => {
            // resetForm({});
            Swal.fire(
              'Done!',
              'The item has been saved!',
              'success'
            )
            fetchDispensary()
            setActionsLoading(false)
            onHide()
        })
        .catch(error => {
          
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
            })
            setActionsLoading(false)
        })
    }

    return (
        <Modal
          size="lg"
          show={show}
          onHide={onHide}
          aria-labelledby="example-modal-sizes-title-lg"
        >
    
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Manage delivery points</Modal.Title>
        </Modal.Header>
            <AddPinpointToMap
                dispensaryId={dispensaryId}
                actionsLoading={actionsLoading}
                locations={locations}
                saveItem={saveItem}
                onHide={onHide}
                setActionsLoading={setActionsLoading}
                coverage={coverage}
                type={type}
                address={address}
                address_line_2={address_line_2}
                addresszip={addresszip}
                city={city}
                state={state}
                location={location}
                businessType={businessType}
                editingPoint = {editingPoint}
                deletePoint={deletePoint}
            />   
        </Modal>
    )
    
}
