/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { orderService, reviewService, subscriptionService } from '../../../../app/services'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import Spinner from '../../spinner/Spinner'
import { Link } from 'react-router-dom'
import _ from "lodash";
import moment from "moment";
import Swal from "sweetalert2";

export function SubscriptionWidget({ business_id, dispensary, subscription, loadingSubscription, amountThisMonth, className }) {


const redirectToPortal = async () => {


		Swal.fire({
			title: 'Contact us',
			html:
			  '<h4>Email: Michael@weedzly.com</h4>' +
			  '<h4>Phone #: 323-990-0555</h4>',
		  })
	

}




  return (
    <>
    {console.log(loadingSubscription)}
          {
            loadingSubscription ? (
              <Spinner />
            ) : (
              (_.isEmpty(subscription) || !subscription || subscription.status==='CANCELED') ? (
                <div class={`d-flex h-100 align-items-start justify-content-start flex-grow-1 bg-light-success p-8 card-rounded flex-grow-1 position-relative ${className}`}>
							  <div class="card-body d-flex align-items-center">
							  	<div style={{marginRight:'10rem'}}>
							  		<h3 class="text-success font-weight-bolder mb-3">Join Weedzly today</h3>
							  		<p class="text-success font-size-lg">Sign up for a plan and take advantage of our premium listings and features to grow your business.</p>
                    
                        <Link className='btn btn-success' to={'/businesses/'+business_id+'/subscription'}>See plans</Link>
                     
                  </div>
                  <div class="position-absolute right-0 bottom-0 mr-5 overflow-hidden">
														<img
                              src={toAbsoluteUrl("/media/svg/humans/custom-1.svg")}
                              style={{maxHeight: '275px'}}
                              className='max-h-200px max-h-xl-275px '
                            ></img>
												</div>
							  </div>
                </div>
              ) : subscription.status==='ACTIVE' ? (
                  <div class="d-flex h-100 align-items-start justify-content-start flex-grow-1 bg-light-success p-8 card-rounded flex-grow-1 position-relative">
								    <div class="d-flex flex-column align-items-start flex-grow-1 h-100">
								  	  <div class="p-1 flex-grow-1">

								  			<h4 class="text-success font-weight-bolder">Subscription Status: {subscription.status.replace('_', ' ')}</h4>
                        <span class="text-success font-weight-bolder"><strong>Selected Plan: {subscription.plan.name}</strong></span>
								  			<p class="text-dark-50 font-weight-bold mt-3">Contact us to manage ur subscription.</p>
								  		</div>
                      {
                        dispensary.dispensaryCanSubscribe && <button onClick={()=>redirectToPortal()} to={'/businesses/:business_id/subscription'} class="btn btn-light">Manage subscription
								  			<span class="svg-icon svg-icon-lg svg-icon-danger"></span>
                        <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Navigation/Arrow-right.svg"
                                      )}

                                    ></SVG>
                        </button>
                      }
								  		
								  					</div>
								  					{/* <div class="position-absolute right-0 bottom-0 mr-5 overflow-hidden">
								  						<img
                                src={toAbsoluteUrl("/media/svg/humans/custom-13.svg")}
                                style={{maxHeight: '275px'}}
                                className='max-h-200px max-h-xl-275px mb-n20'
                              ></img>
								  				</div> */}
							    </div>
                ) : ''
              )
            
          }
          {/* end::Table */}
        
        {/* end::Body */}
      
      {/* end::Base Table Widget 6 */}
    </>
  );
}
