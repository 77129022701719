import { getPrivateApi } from '../helpers/config';
import { getPublicApi } from '../helpers/config';
import axios from "axios";

const baseUrl = getPrivateApi+"plans";


export const planService = {
    getAll,
    getById,
    create,
    update,
    getProductsByCategoryId,
    updateIsEnabled,
    delete: _delete,
    getAllAdmin
};

function getAll(business_id, query) {
    return axios.get(`${baseUrl}/business/${business_id}/${query}`);
}

function getAllAdmin(query) {
    return axios.get(`${baseUrl}${query}`);
}


function getById(id) {return axios.get(`${baseUrl}/${id}`);}

function getProductsByCategoryId(id) {return axios.get(`${baseUrl}/${id}/products/`);}

function create(params) {return axios.post(baseUrl, params);}

function update(id, params) {return axios.put(`${baseUrl}/${id}`, params)}

function _delete(id) {return axios.delete(`${baseUrl}/${id}`)}

function updateIsEnabled(id, params) {return axios.put(`${baseUrl}/status/${id}`, params)}